import React, { useState, useEffect, useMemo } from "react";
import Base from "../../Config/Base";
import Pagination from "../../Config/Pagination";
import { API_BASE_CRM_URL } from "../../Config/Config";
import { Link, useNavigate } from "react-router-dom";
import "../../Config/search.css";

import "../../Config/loader.css";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import "../../Config/loading.css";
import { Select } from "antd";
import { Option } from "antd/es/mentions";
import Swal from "sweetalert2";

export default function Inventory() {
  const navigate = useNavigate();

  const [inventoryData, setInventoryData] = useState({
    warehouseId: '',
    commodityId: '',
    expiry_date: '',
    inventory_number: '',
    lot_number: ''
  });
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [contactLeadOBJ, setContactLeadOBJ] = useState([]);
  const [remidnerLeadOBJ, setRemidnerLeadOBJ] = useState([]);
  const [followupLeadOBJ, setFollowupLeadOBJ] = useState([]);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [warehousesData, setWarehousesData] = useState([]);
  const [productsData, setProductsData] = useState([]);

  const [selectedWarehouseId, setSelectedWarehouseId] = useState('');



  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_BASE_CRM_URL}GetProducts`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        if (data.Status) {
          setProductsData(data.ResultData);
        } else {
          console.error("Fetched data is not an array:", data);
          setProductsData([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const getWarehouses = async () => {
      try {
        const response = await fetch(`${API_BASE_CRM_URL}GetWarehouse`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        if (data.Status) {
          setWarehousesData(data.ResultData);
        } else {
          console.error("Fetched data is not an array:", data);
          setWarehousesData([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    getWarehouses();
  }, []);

  const formatDateString = (dateString) => {
    const [day, month, year] = dateString.split('-');
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    const userDetails = sessionStorage.getItem("userDetails");
    if (!userDetails) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${API_BASE_CRM_URL}getinventorymanage`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        console.log(data)

        if (data.Status) {
          setInventoryData(data.ResultData);
        } else {
          console.error("Fetched data is not an array:", data);
          setInventoryData([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  console.log(inventoryData?.expiry_date);

  const handleWarehouseChange = (value) => {
    setSelectedWarehouseId(value);
    console.log("Selected Warehouse ID:", value);
  }
  const handleEdit = (id) => {
    const selectedItem = currentItems.find(item => item.id === id);
    console.log(selectedItem.warehousename)
    setInventoryData(selectedItem);
  };
  console.log(inventoryData.warehousename);

  const handleSelectChange = (value, field) => {
    setInventoryData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleInputChange = (e, field) => {
    const { value } = e.target;
    setInventoryData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);


    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    // Format as dd-mm-yyyy
    return `${day}-${month}-${year}`;
  };

  console.log(formatDate(inventoryData?.expiry_date));

  const showContactModal = (obj) => {
    setContactLeadOBJ(obj);
  };

  const showReminderModal = (obj) => {
    setRemidnerLeadOBJ(obj);
  };

  const showFollowupModal = (obj) => {
    setFollowupLeadOBJ(obj);
  };

  const itemsPerPage = 10;

  // const filteredItems = Array.isArray(inventoryData)
  //   ? inventoryData.filter(
  //     (item) =>
  //       item.warehousename.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //       item.commodityname.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //       item.lot_number.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //       item.expiry_date.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //       (item.inventory_number &&
  //         item.inventory_number.toLowerCase().includes(searchTerm.toLowerCase()))
  //   )
  //   : [];

  const filteredItems = useMemo(() => {
    const results = Array.isArray(inventoryData)
      ? inventoryData.filter(
        (item) =>
          item.warehousename.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.commodityname.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.lot_number.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.expiry_date.toLowerCase().includes(searchTerm.toLowerCase()) ||
          (item.inventory_number &&
            item.inventory_number.toLowerCase().includes(searchTerm.toLowerCase()))
      ) : []
    setCurrentPage(1);
    return results;
  }, [inventoryData, searchTerm]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

  const handleReload = () => {
    window.location.reload();
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleExcelDownload = () => {
    if (inventoryData.length === 0) {
      return;
    }


    const wb = XLSX.utils.book_new();


    const wsData = inventoryData.map((item) => ({
      Warehousename: item.warehouseId,
      Commodityname: item.commodity_id,
      "Lotnumber": item.lot_number,
      "expitydate": formatDate(item.expiry_date),
      "inventorynumber": item.inventory_number,
      Source: item.SourceName,
    }));

    const ws = XLSX.utils.json_to_sheet(wsData);


    XLSX.utils.book_append_sheet(wb, ws, "inventoryData");


    XLSX.writeFile(wb, "inventoryData.xlsx");
  };

  const handlePDFDownload = () => {
    const doc = new jsPDF();


    doc.setProperties({
      title: "Leads Data",
      author: "Your Name",
      creator: "Your Application",
    });


    const tableData = [];


    const headers = [
      "Warehousename",
      "Commodityname",
      "Lotnumber ",
      "expitydate ",
      "inventorynumber",

    ];
    tableData.push(headers);

    inventoryData.forEach((item) => {
      const row = [];
      row.push(
        item.warehouseId,
        item.commodity_id,
        item.lot_number,
        item.expiry_date,
        item.inventory_number,

      );
      tableData.push(row);
    });


    doc.autoTable({
      head: [headers],
      body: tableData.slice(1),
    });

    doc.save("inventoryData.pdf");
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    const postData = {
      id: inventoryData?.id,
      warehouse_id: inventoryData?.warehouse_id,
      commodity_id: inventoryData?.commodity_id,
      lot_number: inventoryData?.lot_number,
      expiry_date: inventoryData?.expiry_date,
      inventory_number: inventoryData?.inventory_number,
    };

    console.log(postData);

    try {
      const response = await fetch(`${API_BASE_CRM_URL}inventory/EditInventoryManage`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),
      });

      if (response.ok) {
        const responseData = await response.json();
        console.log('Success:', responseData);

        // Show success message using SweetAlert2
        Swal.fire({
          title: "Success!",
          text: "Inventory updated successfully!",
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => {
          window.location.reload();
        })
      } else {
        console.error('Error:', response.statusText);
        Swal.fire({
          title: "Error",
          text: "Failed to update inventory.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      console.error('Error:', error);
      Swal.fire({
        title: "Error",
        text: "An error occurred. Please try again later.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };



  return (
    <Base>
      <div className="page-header">
        <div className="d-flex justify-content-between flex-wrap">
          <div className="page-title">
            <h4>Inventory </h4>
            <h6>Manage your Inventory</h6>
          </div>
        </div>
        <div className="d-flex flex-wrap align-items-center mt-3">
          <div className="flex-fill d-md-flex justify-content-between align-items-center">
            <div className="input-group mb-2 mb-md-0">
              <input
                type="text"
                name="search"
                placeholder="Search..."
                className="form-control"
                // value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <button className="btn btn-primary" type="button">
                <i className="fas fa-search"></i>
              </button>
            </div>
          </div>
          <ul className="table-top-head d-flex list-unstyled mb-0 flex-wrap">
            <li className="ms-2 ms-md-3">
              <a
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Pdf"
                onClick={handlePDFDownload}
              >
                <img src="assets/img/icons/pdf.svg" alt="PDF" />
              </a>
            </li>
            <li className="ms-2 ms-md-3">
              <a
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Excel"
                onClick={handleExcelDownload}
              >
                <img src="assets/img/icons/excel.svg" alt="Excel" />
              </a>
            </li>
            {/* <li className="ms-2 ms-md-3">
                <a data-bs-toggle="tooltip" data-bs-placement="top" title="Print">
                    <i className="fa-solid fa-print fs-5"></i>
                </a>
            </li> */}
            <li className="ms-2 ms-md-3" onClick={handleReload}>
              <a
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Refresh"
              >
                <i className="fa-solid fa-arrow-rotate-left fs-5"></i>
              </a>
            </li>
            <li className="ms-2 ms-md-3">
              <a
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Collapse"
                id="collapse-header"
              >
                <i className="fa-solid fa-chevron-up fs-6"></i>
              </a>
            </li>
            <li className="ms-2 ms-md-3">
              <Link className="btn btn-added" to="/post-inventory">
                <i className="fa-solid fa-plus"></i>
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="card table-list-card">
        <div className="card-body">
          {/* For all screen sizes */}
          <div className="table-responsive" style={{ minHeight: "24rem" }}>
            <table className="table table-hover">
              <thead>
                <tr>
                  <th className="no-sort">#</th>
                  <th className="no-sort">Action</th>
                  <th> warehouse Name</th>

                  <th>Product Name</th>
                  <th>Lot Number</th>
                  <th>Expiry Date</th>
                  <th>Inventory</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <td colSpan={7} className="text-center">
                    <div className="containers m-auto">
                      <div className="dot"></div>
                      <div className="dot"></div>
                      <div className="dot"></div>
                      <div className="dot"></div>
                      <div className="dot"></div>
                      <div className="dot"></div>
                    </div>
                  </td>
                ) : (
                  currentItems &&
                  currentItems.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td className="text-start">
                        <div className="dropdown">
                          <a
                            className="action-set"
                            href="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i
                              className="fa fa-ellipsis-v"
                              aria-hidden="true"
                            ></i>
                          </a>
                          <div className="dropdown-menu">
                            <a
                              className="dropdown-item"
                              data-bs-toggle="offcanvas"
                              data-bs-target="#offcanvasView"
                              aria-controls="offcanvasView"
                              onClick={() => handleEdit(item.id)}

                            >
                              <i className="fa-regular fa-eye me-2"></i>edit
                            </a>
                            {/*}  <a
                              className="dropdown-item"
                              data-bs-toggle="offcanvas"
                              data-bs-target="#offcanvasContact"
                              aria-controls="offcanvasContact"
                              onClick={() => showContactModal(item)}
                            >
                              <i className="fa-regular fa-address-book me-2 text-success"></i>
                              Add Contact
                            </a>
                            <a
                              className="dropdown-item"
                              data-bs-toggle="offcanvas"
                              data-bs-target="#offcanvasReminder"
                              aria-controls="offcanvasReminder"
                              onClick={() => showReminderModal(item)}
                            >
                              <i className="fa-solid fa-business-time me-2 text-info"></i>
                              Set Reminder
                            </a>
                         <a
                              className="dropdown-item"
                              data-bs-toggle="offcanvas"
                              data-bs-target="#offcanvasFollowup"
                              aria-controls="offcanvasFollowup"
                              onClick={() => showFollowupModal(item)}
                            >
                              <i className="fa-solid fa-reply-all me-2"></i>
                              Followup
                            </a>*/}
                          </div>
                        </div>
                      </td>
                      <td>
                        {item.warehousename}
                      </td>
                      <td>{item.commodityname}</td>
                      <td>{item.lot_number}</td>
                      <td>{formatDate(item.expiry_date)}
                      </td>
                      <td>{item.inventory_number ? item.inventory_number : "N/A"}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={filteredItems.length}
              paginate={paginate}
              currentPage={currentPage}
            />
          </div>
        </div>
        <div
          className="offcanvas offcanvas-end custom-offcanvas"
          tabIndex="-1"
          id="offcanvasView"
          aria-labelledby="offcanvasRightLabel"
        >
          <style>
            {`
      .offcanvas.custom-offcanvas {
          width: 450px !important; /* Adjust this as needed */
      }

      @media (max-width: 576px) {
          .offcanvas.custom-offcanvas {
          width: 70% !important; /* Adjust the percentage as needed */
          }
      }
    `}
          </style>
          <div className="offcanvas-header">
            <div className="page-title d-flex justify-content-between align-items-center">
              <h4>edits</h4>
            </div>
            <button
              type="button"
              className="btn-close text-reset"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>

          <div className="offcanvas-body ms-2">
            <form onSubmit={handleSubmit}>
              <div className="row">

                <div className="mb-3 col-12">
                  <label className="form-label">Warehouse Name: </label>
                  <Select
                    name="warehouseId"

                    placeholder="Select Warehouse"
                    value={inventoryData.warehouse_id || ''}
                    onChange={(value) => handleSelectChange(value, 'warehouse_id')}
                    style={{ width: 200 }}

                  >
                    <Option value="" disabled>Select Warehouse</Option>
                    {warehousesData.length > 0 ? (
                      warehousesData.map((warehouse) => (
                        <Option key={warehouse.warehouse_id} value={warehouse.warehouse_id}>
                          {warehouse.warehouse_name}
                        </Option>
                      ))
                    ) : (
                      <Option disabled>Loading Warehouses...</Option>
                    )}
                  </Select>
                </div>


                <div className="mb-3 col-md-6 col-lg-6">
                  <label className="form-label">Commodity Name</label>
                  <Select
                    name="commodityId"
                    value={inventoryData.commodity_id || ''}
                    onChange={(value) => handleSelectChange(value, 'commodity_id')}
                    style={{ width: 200 }}
                    placeholder="Select Commodity"
                  >
                    <Option value="" disabled>Select Commodity</Option>
                    {productsData.length > 0 ? (
                      productsData.map((product) => (
                        <Option key={product.id} value={product.id}>
                          {product.description}
                        </Option>
                      ))
                    ) : (
                      <Option disabled>Loading Commodities...</Option>
                    )}
                  </Select>
                </div>


                <div className="mb-3 col-md-6 col-lg-6">
                  <label className="form-label">Lot Number</label>
                  <input
                    type="text"
                    className="form-control"
                    value={inventoryData?.lot_number || ''}
                    readOnly
                  />
                </div>


                <div className="mb-3 col-md-6 col-lg-6">
                  <label className="form-label">Expiry Date</label>
                  <input
                    type="date"
                    className="form-control"
                    value={inventoryData?.expiry_date ? new Date(inventoryData.expiry_date).toISOString().split('T')[0] : ''}
                    onChange={(e) => handleInputChange(e, 'expiry_date')}
                  />
                </div>

                <div className="mb-3 col-md-6 col-lg-6">
                  <label className="form-label">Inventory Number</label>
                  <input
                    type="text"
                    className="form-control"
                    value={inventoryData?.inventory_number || ''}
                    onChange={(e) => handleInputChange(e, 'inventory_number')}
                  />
                </div>
              </div>
              <button type="submit" className="btn btn-primary">Submit</button>
            </form>
          </div>

        </div>

      </div>
    </Base>
  );
}
