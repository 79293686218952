import React, { useState, useEffect, useMemo } from "react";
import Base from "../../Config/Base";
import { Link, useNavigate } from "react-router-dom";
import { API_BASE_CRM_URL } from "../../Config/Config";
import Pagination from "../../Config/Pagination";
import "../../../Assets/popup.css";
import PDFIMG from "../../Config/pdf.svg";
import EXCELIMG from "../../Config/excel.svg";
import Swal from "sweetalert2";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
// import ViewLogisticRate from "./ViewLogisticRate";
// import EditLogisticRate from "./EditLogisticRate";
// import AddLogisticRate from "./AddLogisticRate";
import "../../Config/loading.css";
import { useLabelContext } from "../../../Context";
import AddDesignmix from "./adddesignmix";

export default function Designmix() {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [designmixData, setDesignmixData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [logisticViewOBJ, setLogisticViewOBJ] = useState([]);
    const [logisticEditOBJ, setLogisticEditOBJ] = useState([]);
    const { setID } = useLabelContext();

    useEffect(() => {
        const userDetails = sessionStorage.getItem("userDetails");
        if (!userDetails) {
            navigate("/");
        }
    }, [navigate]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${API_BASE_CRM_URL}GetDesignMix`);
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();
                console.log(data.ResultData)

                if (data.Status) {
                    setDesignmixData(data.ResultData);
                } else {
                    console.error("Fetched data is not an array:", data);
                    setDesignmixData([]);
                }
            } catch (error) {
                console.error("Error fetching datasssss:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleReload = () => {
        window.location.reload();
    };

    const showLogisticViewModal = (obj) => {
        setLogisticViewOBJ(obj);
    };

    const showLogisticEditModal = (obj) => {
        setLogisticEditOBJ(obj);
    };

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const filteredItems = useMemo(() => {
        const results = designmixData.filter(
            (item) =>
                item.name.toLowerCase().includes(searchTerm.toLowerCase())

        );


        setCurrentPage(1);

        return results;
    }, [designmixData, searchTerm]);


    // Calculate indices for slicing data
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();

        return `${day}-${month}-${year}`;
    };



    const handleExcelDownload = () => {
        if (filteredItems.length === 0) {
            return; // Don't download if there is no data
        }

        // Create a new workbook
        const wb = XLSX.utils.book_new();

        // Convert your data to worksheet format
        const wsData = filteredItems.map((item) => ({
            Name: item.name,
            Customer: item.cus_name,
            Cost: item.project_cost,
            "Start Date": formatDate(item.start_date),
            Deadline: formatDate(item.deadline),
            "Completed Date": item.date_finished
                ? formatDate(item.date_finished)
                : "",
        }));

        const ws = XLSX.utils.json_to_sheet(wsData);

        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(wb, ws, "followupsData");

        // Save the workbook to a file
        XLSX.writeFile(wb, "designmixData.xlsx");
    };

    const handlePDFDownload = () => {
        const doc = new jsPDF();

        // Set properties for PDF
        doc.setProperties({
            title: "Projects Data",
            author: "Your Name",
            creator: "Your Application",
        });

        // Add data to PDF
        const tableData = []; // Your data array here

        // Add a header row
        const headers = [
            "Name",
            "Customer",
            "Cost",
            "Start Date",
            "Deadline",
            "Completed Date",
        ];
        tableData.push(headers);

        // Add rows
        filteredItems.forEach((item) => {
            const row = [];
            row.push(
                item.name,
                item.cus_name,
                item.project_cost,
                formatDate(item.start_date),
                formatDate(item.deadline),
                item.date_finished ? formatDate(item.date_finished) : ""
            );
            tableData.push(row);
        });

        // Generate table
        doc.autoTable({
            head: [headers],
            body: tableData.slice(1),
        });
        // Save the PDF
        doc.save("designmixData.pdf");
    };

    const handleViewPurchaseOrders = (item) => {
        console.log(item);
        setID(item.id);
    };

    return (
        <Base>
            <div className="page-header">
                <div className="add-item d-flex">
                    <div className="page-title">
                        <h4>Design Mix</h4>
                        <h6>Manage your Design Mix</h6>
                    </div>
                </div>
                <ul className="table-top-head">
                    <li>
                        <a data-bs-toggle="tooltip" data-bs-placement="top" title="Pdf">
                            <img src={PDFIMG} alt="img" onClick={handlePDFDownload} />
                        </a>
                    </li>
                    <li>
                        <a data-bs-toggle="tooltip" data-bs-placement="top" title="Excel">
                            <img src={EXCELIMG} alt="img" onClick={handleExcelDownload} />
                        </a>
                    </li>
                    <li>
                        <a title="Refresh" onClick={handleReload}>
                            <i className="fa-solid fa-arrow-rotate-left fs-5"></i>
                        </a>
                    </li>
                    <li>
                        <a title="Collapse" id="collapse-header">
                            <i className="fa-solid fa-chevron-up fs-6"></i>
                        </a>
                    </li>

                    <li>
                        <a
                            className="btn btn-added"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasRight"
                            aria-controls="offcanvasRight"
                        >
                            <i className="fa-solid fa-plus"></i>
                        </a>
                    </li>
                </ul>
            </div>

            <div className="card table-list-card">
                <div className="card-body">
                    <div className="table-top">
                        <div className="search-set">
                            <div className="search-input">
                                <input
                                    type="text"
                                    name="search"
                                    placeholder="Search..."
                                    className="form-control"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="table-responsive ">
                        <table className="table dataew">
                            <thead>
                                <tr>
                                    <th className="no-sort">
                                        <label className="checkboxs">
                                            <input type="checkbox" id="select-all" />
                                            <span className="checkmarks"></span>
                                        </label>
                                    </th>
                                    <th> Designmix Name</th>
                                    <th>cement</th>
                                    <th>flyash</th>
                                    <th>gypsum</th>
                                    <th>ggbs</th>
                                    <th> ad_mix</th>
                                    <th>Total Weight</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <td colSpan={12} className="etxt-center">
                                        <p className="text-center">
                                            <div className="containers m-auto">
                                                <div className="dot"></div>
                                                <div className="dot"></div>
                                                <div className="dot"></div>
                                                <div className="dot"></div>
                                                <div className="dot"></div>
                                                <div className="dot"></div>
                                            </div>
                                        </p>
                                    </td>
                                ) : (
                                    currentItems &&
                                    currentItems.map((item, index) => (
                                        <tr key={index}>
                                            <td>
                                                <label className="checkboxs">
                                                    <input type="checkbox" />
                                                    <span className="checkmarks"></span>
                                                </label>
                                            </td>
                                            <td>{item.name}</td>
                                            <td>{item.cement}</td>
                                            <td>{item.flyash}</td>
                                            <td>{item.gypsum}</td>
                                            <td>{item.ggbs}</td>
                                            <td>{item.ad_mix}</td>
                                            <td>{item.total_weight}</td>

                                            {/* <td className="text-center">
                                                <div className="dropdown">
                                                    <a
                                                        className="action-set"
                                                        href="#"
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="false"
                                                    >
                                                        <i
                                                            className="fa fa-ellipsis-v"
                                                            aria-hidden="true"
                                                        ></i>
                                                    </a>
                                                    <div className="dropdown-menu">
                                                       
                                                        <Link
                                                            className="btn btn-added"
                                                            data-bs-toggle="offcanvas"
                                                            data-bs-target="#offcanvasRight"
                                                            aria-controls="offcanvasRight"
                                                            onClick={() => handleViewPurchaseOrders(item)} // Pass data and handle logic
                                                        >
                                                            <i className="fa-regular fa-pen-to-square text-info me-2"></i>
                                                            Edit
                                                        </Link>
                                                       
                                                    </div>
                                                </div>
                                            </td> */}
                                            <td className="action-table-data">
                                                <div className="edit-delete-action">

                                                    <Link
                                                        className="hello"
                                                        to={`/Edit-designmix`}
                                                        onClick={() => handleViewPurchaseOrders(item)}
                                                    >
                                                        <i className="fa-regular fa-pen-to-square text-info me-2"></i>
                                                        Edit
                                                    </Link>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                        <Pagination
                            itemsPerPage={itemsPerPage}
                            totalItems={filteredItems.length}
                            paginate={paginate}
                            currentPage={currentPage}
                        />
                    </div>
                </div>
            </div>
            <AddDesignmix></AddDesignmix>
        </Base>
    );
}
