import React, { useState, useEffect, useMemo } from "react";
import Base from "../../Config/Base";
import { Link, useNavigate } from "react-router-dom";
import { API_BASE_CRM_URL } from "../../Config/Config";
import Pagination from "../../Config/Pagination";
import "../../../Assets/popup.css";
import PDFIMG from "../../Config/pdf.svg";
import EXCELIMG from "../../Config/excel.svg";
import Swal from "sweetalert2";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import AddProject from "./AddProject";
// import ViewLogisticRate from "./ViewLogisticRate";
// import EditLogisticRate from "./EditLogisticRate";
// import AddLogisticRate from "./AddLogisticRate";
import "../../Config/loading.css";
import { useLabelContext } from "../../../Context";

export default function Projects() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [projectsData, setProjectsData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [logisticViewOBJ, setLogisticViewOBJ] = useState([]);
  const [logisticEditOBJ, setLogisticEditOBJ] = useState([]);
  const { setID } = useLabelContext();

  useEffect(() => {
    const userDetails = sessionStorage.getItem("userDetails");
    if (!userDetails) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${API_BASE_CRM_URL}GetProjects`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        console.log(data.ResultData)

        if (data.Status) {
          setProjectsData(data.ResultData);
        } else {
          console.error("Fetched data is not an array:", data);
          setProjectsData([]);
        }
      } catch (error) {
        console.error("Error fetching datasssss:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleReload = () => {
    window.location.reload();
  };

  const showLogisticViewModal = (obj) => {
    setLogisticViewOBJ(obj);
  };

  const showLogisticEditModal = (obj) => {
    setLogisticEditOBJ(obj);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // const filteredItems = projectsData.filter(
  //   (item) =>
  //     (item.name &&
  //       item.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
  //     (item.units &&
  //       item.units.toLowerCase().includes(searchTerm.toLowerCase()))
  // );

  const filteredItems = useMemo(() => {
    const results = projectsData.filter(
      (item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (item.units &&
          item.units.toLowerCase().includes(searchTerm.toLowerCase()))

    );


    setCurrentPage(1);

    return results;
  }, [projectsData, searchTerm]);


  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };

  const activeLogisticRate = (item) => {
    const url = `${API_BASE_CRM_URL}ActiveProject`;
    const payload = { Id: item.id };
    console.log(payload);

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        if (data.Status) {
          Swal.fire({
            title: `Logistic Rate Status Changed Successfully.`,
            icon: "success",
          }).then((result) => {
            if (result.isConfirmed || result.isDismissed) {
              window.location.reload();
            }
          });
        } else {
          Swal.fire({
            title: "Error",
            text: "Failed to submit request.",
            icon: "error",
          });
        }
      })
      .catch((error) => {
        console.error("Error status change:", error);
        Swal.fire("Error!", "There was an error changing the status.", "error");
      });
  };

  const handleExcelDownload = () => {
    if (filteredItems.length === 0) {
      return; // Don't download if there is no data
    }

    // Create a new workbook
    const wb = XLSX.utils.book_new();

    // Convert your data to worksheet format
    const wsData = filteredItems.map((item) => ({
      Name: item.name,
      Customer: item.cus_name,
      Cost: item.project_cost,
      "Start Date": formatDate(item.start_date),
      Deadline: formatDate(item.deadline),
      "Completed Date": item.date_finished
        ? formatDate(item.date_finished)
        : "",
    }));

    const ws = XLSX.utils.json_to_sheet(wsData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "followupsData");

    // Save the workbook to a file
    XLSX.writeFile(wb, "projectsData.xlsx");
  };

  const handlePDFDownload = () => {
    const doc = new jsPDF();

    // Set properties for PDF
    doc.setProperties({
      title: "Projects Data",
      author: "Your Name",
      creator: "Your Application",
    });

    // Add data to PDF
    const tableData = []; // Your data array here

    // Add a header row
    const headers = [
      "Name",
      "Customer",
      "Cost",
      "Start Date",
      "Deadline",
      "Completed Date",
    ];
    tableData.push(headers);

    // Add rows
    filteredItems.forEach((item) => {
      const row = [];
      row.push(
        item.name,
        item.cus_name,
        item.project_cost,
        formatDate(item.start_date),
        formatDate(item.deadline),
        item.date_finished ? formatDate(item.date_finished) : ""
      );
      tableData.push(row);
    });

    // Generate table
    doc.autoTable({
      head: [headers],
      body: tableData.slice(1),
    });
    // Save the PDF
    doc.save("projectsData.pdf");
  };

  const handleViewPurchaseOrders = (item) => {
    console.log(item);
    setID(item.id);
  };
  
  return (
    <Base>
      <div className="page-header">
        <div className="add-item d-flex">
          <div className="page-title">
            <h4>Projects</h4>
            <h6>Manage your Projects</h6>
          </div>
        </div>
        <ul className="table-top-head">
          <li>
            <a data-bs-toggle="tooltip" data-bs-placement="top" title="Pdf">
              <img src={PDFIMG} alt="img" onClick={handlePDFDownload} />
            </a>
          </li>
          <li>
            <a data-bs-toggle="tooltip" data-bs-placement="top" title="Excel">
              <img src={EXCELIMG} alt="img" onClick={handleExcelDownload} />
            </a>
          </li>
          <li>
            <a title="Print">
              <i className="fa-solid fa-print fs-5"></i>
            </a>
          </li>
          <li>
            <a title="Refresh" onClick={handleReload}>
              <i className="fa-solid fa-arrow-rotate-left fs-5"></i>
            </a>
          </li>
          <li>
            <a title="Collapse" id="collapse-header">
              <i className="fa-solid fa-chevron-up fs-6"></i>
            </a>
          </li>
          {/* <li>
                        <a className="btn btn-added" data-bs-toggle="modal" data-bs-target="#add-logistic-rate"><i className="fa-solid fa-plus"></i></a>
                    </li> */}
          <li>
            <a
              className="btn btn-added"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasRight"
              aria-controls="offcanvasRight"
            >
              <i className="fa-solid fa-plus"></i>
            </a>
          </li>
        </ul>
      </div>

      <div className="card table-list-card">
        <div className="card-body">
          <div className="table-top">
            <div className="search-set">
              <div className="search-input">
                <input
                  type="text"
                  name="search"
                  placeholder="Search..."
                  className="form-control"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="table-responsive ">
            <table className="table dataew">
              <thead>
                <tr>

                  <th>Name</th>
                  <th>Cus Name</th>
                  <th>Cost</th>
                  <th>Km</th>
                  <th>Location</th>
                  <th>Status</th>
                  <th className="no-sort">Action</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <td colSpan={12} className="etxt-center">
                    <p className="text-center">
                      <div className="containers m-auto">
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                      </div>
                    </p>
                  </td>
                ) : (
                  currentItems &&
                  currentItems.map((item, index) => (
                    <tr key={index}>

                      <td>{item.name}</td>
                      <td>{item.cus_name}</td>
                      <td>{item.project_cost}</td>

                      <td>{item.KM}</td>
                      <td>
                        <a href={item.notes || '#'} target="_blank" rel="noopener noreferrer">
                          Maps
                        </a>
                      </td>
                      <td>
                        {item.status === 1 ? (
                          <span className="badge bg-outline-success">
                            Active
                          </span>
                        ) : (
                          <span className="badge bg-outline-danger">
                            Inactive
                          </span>
                        )}
                      </td>
                      <td className="text-center">
                        <div className="dropdown">
                          <a
                            className="action-set"
                            href="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i
                              className="fa fa-ellipsis-v"
                              aria-hidden="true"
                            ></i>
                          </a>
                          <div className="dropdown-menu">
                            <Link
                              to={`/view-project`}
                              className="dropdown-item"
                              onClick={() => handleViewPurchaseOrders(item)}
                            >
                              <i className="fa-regular fa-eye me-2"></i>View
                            </Link>
                            <Link
                              className={`dropdown-item ${item.status === 1 ? "" : "disabled"
                                }`}
                              to={`/edit-project`}
                              onClick={() => handleViewPurchaseOrders(item)}
                            >
                              <i className="fa-regular fa-pen-to-square text-info me-2"></i>
                              Edit
                            </Link>
                            <a
                              className={`dropdown-item ${item.status === 1 ? "" : "disabled"
                                }`}
                              href="javascript:void(0);"
                              onClick={() => activeLogisticRate(item)}
                            >
                              <i className="fa-regular fa-trash-can text-danger me-2"></i>
                              Delete
                            </a>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={filteredItems.length}
              paginate={paginate}
              currentPage={currentPage}
            />
          </div>


        </div>
      </div>
      <AddProject />
      {/* <ViewLogisticRate logisticRateObj={logisticViewOBJ} />
            <EditLogisticRate logisticRateObj={logisticEditOBJ} />
            <AddLogisticRate /> */}
    </Base>
  );
}
