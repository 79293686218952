import React, { useState, useEffect } from "react";
import { API_BASE_CRM_URL } from "../../Config/Config";
import Swal from "sweetalert2";
import { Select } from 'antd';
import '../../Config/swal.css';


export default function AddDesignmix() {

    const [userDetails, SetUserDetails] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedCusId, setSelectedCusId] = useState('');
    const [customersData, setCustomersData] = useState([]);


    useEffect(() => {
        const userDetailsSession = JSON.parse(sessionStorage.getItem('userDetails'));
        SetUserDetails(userDetailsSession);
    }, []);

    const [formData, setFormData] = useState({
        name: '',
        cement: '',
        msand: '',
        six_mm: '',
        flyash: '',
        gypsum: '',
        ggbs: '',
        ad_mix: ''
    });

    // Handle form input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {

            console.log(formData, 'data sending to api');

            const response = await fetch(`${API_BASE_CRM_URL}addDesignMix`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
            });

            const data = await response.json();
            if (data.Status) {
                setLoading(false);
                Swal.fire({
                    title: `Project added Successfully.`,
                    icon: "success",
                    customClass: {
                        title: 'swal-title',
                        content: 'swal-text'
                    }
                }).then((result) => {
                    if (result.isConfirmed || result.isDismissed) {
                        window.location.reload();
                    }
                });
            } else {
                setLoading(false);
                Swal.fire({
                    title: "Error",
                    text: "Failed to submit request.",
                    icon: "error",
                    customClass: {
                        title: 'swal-title',
                        content: 'swal-text'
                    }
                });
            }
        } catch (error) {
            console.error("Error submitting form:", error);
            alert("Error submitting form");
        }
    };

    return (
        <div>
            <style>
                {`
                .offcanvas.custom-offcanvas {
                    width: 400px !important; /* Adjust this as needed */
                }

                @media (max-width: 576px) {
                    .offcanvas.custom-offcanvas {
                    width: 70% !important; /* Adjust the percentage as needed */
                    }
                }
                `}
            </style>

            <div
                className="offcanvas offcanvas-end custom-offcanvas"
                tabIndex="-1"
                id="offcanvasRight"
                aria-labelledby="offcanvasRightLabel"
            >
                <div className="offcanvas-header">
                    <h5 id="offcanvasRightLabel">Add Project</h5>
                    <button
                        type="button"
                        className="btn-close text-reset"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                    ></button>
                </div>
                <div className="offcanvas-body">
                    <form onSubmit={handleSubmit}>
                        <div className="row mt-2">
                            <div className=" mt-2">
                                <label className="form-label">Name <span className='text-danger'>*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter project name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className=" mt-2">
                                <label className="form-label">Cement <span className='text-danger'>*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter project name"
                                    name="cement"
                                    value={formData.cement}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-md-6">
                                <label className="form-label">Msand<span className="text-danger">*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="msand"
                                    value={formData.msand}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="col-md-6">
                                <label className="form-label">six mm</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="six_mm"
                                    value={formData.six_mm}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-md-6">
                                <label className="form-label">flyash</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Enter cost of project"
                                    name="flyash"
                                    value={formData.flyash}
                                    onChange={handleChange}
                                    onWheel={(e) => e.target.blur()}
                                />
                            </div>
                            <div className="col-md-6">
                                <label className="form-label">gypsum</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Enter longitude"
                                    name="gypsum"
                                    value={formData.gypsum}
                                    onChange={handleChange}
                                    onWheel={(e) => e.target.blur()}
                                />
                            </div>
                        </div>
                        <div className="row mt-2">


                            <div className="col-md-6">
                                <label className="form-label">ggbs</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter project ggbs"
                                    name="ggbs"
                                    value={formData.ggbs}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className=" col-md-6">
                                <label className="form-label">ad mix</label>
                                <textarea
                                    className="form-control"
                                    placeholder="Enter project ad_mix"
                                    name="ad_mix"
                                    value={formData.ad_mix}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <button type="submit" className="btn btn-primary d-flex m-auto mt-4">Submit</button>
                    </form>
                </div>
            </div>
        </div>
    );
}
