import React, { useState, useEffect } from 'react';
import Base from '../../Config/Base';
import { API_BASE_CRM_URL } from "../../Config/Config";
import { Link } from 'react-router-dom';
import Swal from "sweetalert2";
import { useNavigate } from 'react-router-dom';
import { Select } from 'antd';
import '../../Config/swal.css';
import { Option } from 'antd/es/mentions';


export default function AddProdcut() {

    const [userDetails, SetUserDetails] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const userDetailsSession = JSON.parse(sessionStorage.getItem('userDetails'));
        SetUserDetails(userDetailsSession);
    }, []);

    useEffect(() => {
        const userDetails = sessionStorage.getItem('userDetails');
        if (!userDetails) {
            navigate('/');
        }
    }, [navigate]);

    const [loading, setLoading] = useState(false);
    const [taxesData, setTaxesdata] = useState([]);
    const [selectedwareId, setSelectedWareId] = useState(null);
    const [selectedTax2Id, setSelectedTax2Id] = useState(null);
    const [warehousesData, setWarehousesData] = useState([]);
    const [selecteddesignixid, setselecteddesignmixid] = useState("")
    const [designmixData, setDesignmixData] = useState([]);

    const [formValues, setFormValues] = useState({
        name: '',
        rate: '',
        unit: '',
        description: '',
        weight: '',
        warehouse_id: '',
        commodity_code: '',
        commodity_name: '',
        quantity: '',
        tax: 9,
        design_mix: '',
    });

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${API_BASE_CRM_URL}GetTaxes`);
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();

                if (data.Status) {
                    setTaxesdata(data.ResultData);
                } else {
                    console.error("Fetched data is not an array:", data);
                    setTaxesdata([]);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const getWarehouses = async () => {
            try {
                const response = await fetch(`${API_BASE_CRM_URL}GetWarehouse`);
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();
                if (data.Status) {
                    setWarehousesData(data.ResultData);
                    console.log(data.ResultData);
                } else {
                    console.error("Fetched data is not an array:", data);
                    setWarehousesData([]);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        getWarehouses();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${API_BASE_CRM_URL}GetDesignMix`);
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();
                console.log(data.ResultData)

                if (data.Status) {
                    setDesignmixData(data.ResultData);
                } else {
                    console.error("Fetched data is not an array:", data);
                    setDesignmixData([]);
                }
            } catch (error) {
                console.error("Error fetching datasssss:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const DesignmixOptions =
        designmixData &&
        designmixData.map((item) => ({
            value: item.id,
            label: item.name,
        }));

    const warwhouseOptions =
        warehousesData &&
        warehousesData.map((item) => ({
            value: item.warehouse_id,
            label: item.warehouse_name,
        }));

    const taxOptions = taxesData && taxesData.map((item) => ({
        value: item.id,
        label: item.name,
    }));

    const handleWarehouseSelectChange = (selectedOption) => {
        setSelectedWareId(selectedOption);
        setFormValues(prevValues => ({
            ...prevValues,
            warehouse_id: selectedOption
        }));
    };

    const handleDesignmixSelectChange = (selectedOption) => {
        setselecteddesignmixid(selectedOption);
        setFormValues(prevValues => ({
            ...prevValues,
            design_mix: selectedOption
        }));
    };

    console.log(selectedwareId);


    const handleTax2SelectChange = (selectedOption) => {
        setSelectedTax2Id(selectedOption);
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues(prevValues => ({
            ...prevValues,


            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        // formValues.tax1 = selectedTax1Id;
        // formValues.tax2 = selectedTax2Id;
        console.log(formValues)

        try {
            const response = await fetch(`${API_BASE_CRM_URL}AddProduct`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formValues),
            });

            const result = await response.json();
            console.log(result, 'resultttt')

            if (result.status) {
                setLoading(false);
                Swal.fire({
                    title: `Product Added Successfully.`,
                    icon: "success",
                    customClass: {
                        title: 'swal-title',
                        content: 'swal-text'
                    }
                }).then((result) => {
                    if (result.isConfirmed || result.isDismissed) {
                        window.location.reload();
                    }
                });
            } else {
                setLoading(false);
                Swal.fire({
                    title: "Error",
                    text: "Failed to submit request.",
                    icon: "error",
                    customClass: {
                        title: 'swal-title',
                        content: 'swal-text'
                    }
                });
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Base>
            <div
                className="offcanvas offcanvas-end custom-offcanvas"
                tabIndex="-1"
                id="offcanvasAdd"
                aria-labelledby="offcanvasRightLabel"
            >
                <style>
                    {`
                        .offcanvas.custom-offcanvas {
                            width: 450px !important; /* Adjust this as needed */
                        }

                        @media (max-width: 576px) {
                            .offcanvas.custom-offcanvas {
                            width: 70% !important; /* Adjust the percentage as needed */
                            }
                        }
                        `}
                </style>
                <div className="offcanvas-header">
                    <h4>Add Product</h4>
                    <button
                        type="button"
                        className="btn-close text-reset"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                    ></button>
                    {/* <p className='fw-semibold'>Fill out the form to add a new product</p> */}
                </div>
                <div className="offcanvas-body ms-2">
                    <form onSubmit={handleSubmit}>
                        <div className='row mb-2'>
                            <div className='col-md-12'>
                                <label className='form-label'>Name or Size<span className='text-danger'>*</span></label>
                                <input
                                    className='form-control'
                                    type='text'
                                    name='description'
                                    value={formValues.description}
                                    onChange={handleChange}
                                    placeholder='Enter name'
                                    required
                                />
                            </div>
                            {/* <div className='col-md-6'>
                                <label className='form-label'>Quantity</label>
                                <input
                                    className='form-control'
                                    type='text'
                                    name='quantity'
                                    value={formValues.quantity}
                                    onChange={handleChange}
                                    placeholder='Enter quantity'

                                />
                            </div> */}
                        </div>
                        <div className='row mb-2'>
                            <div className='col-md-6'>
                                <label className='form-label'>Price<span className='text-danger'>*</span></label>
                                <input
                                    className='form-control'
                                    type='text'
                                    name='rate'
                                    value={formValues.rate}
                                    onChange={handleChange}
                                    placeholder='Enter price'
                                    required
                                />
                            </div>

                            <div className='col-md-6'>
                                <label className='form-label'>Units</label>
                                <input
                                    className='form-control'
                                    type='text'
                                    name='unit'
                                    value={formValues.unit}
                                    onChange={handleChange}
                                    placeholder='Enter unit'
                                    required
                                />
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <div className='col-md-6'>
                                <label className='form-label'>Inches<span className='text-danger'>*</span></label>
                                <input
                                    className='form-control'
                                    type='text'
                                    name='size_id'
                                    value={formValues.size_id}
                                    onChange={handleChange}
                                    placeholder='Enter price'
                                    required
                                />
                            </div>
                            <div className='col-md-6'>
                                <label className='form-label'>Alios (related name)</label>
                                <input
                                    className='form-control'
                                    type='text'
                                    name='sku_name'
                                    value={formValues.sku_name}
                                    onChange={handleChange}
                                    placeholder='Enter unit'
                                    required
                                />
                            </div>
                        </div>
                        <div className='mb-2'>
                            <label className='form-label'>Designmix</label>
                            <div className='d-flex' style={{ width: '100%' }}>
                                <Select
                                    name="design_mix"
                                    placeholder="Select Designmix"
                                    value={selecteddesignixid || null}
                                    onChange={handleDesignmixSelectChange}
                                    style={{ width: '100%' }}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={DesignmixOptions}
                                />
                            </div>
                        </div>


                        <div className='row mb-2'>
                            <div className='col-md-6'>
                                <label className='form-label'>Weight</label>
                                <input
                                    className='form-control'
                                    type='text'
                                    name='weight'
                                    value={formValues.weight}
                                    onChange={handleChange}
                                    placeholder='Enter weight'

                                />
                            </div>

                            <div className='col-md-6'>
                                <label className='form-label'>Tax<span className='text-danger'>*</span></label>
                                <input
                                    className='form-control'
                                    type='text'
                                    name='tax'
                                    value={formValues.tax}
                                    onChange={handleChange}

                                />
                            </div>
                        </div>

                        {/* <div className='mb-2'>
                            <label className='form-label'>Design Mix</label>
                            <input
                                className='form-control'
                                type='text'
                                name='design_mix'
                                value={formValues.design_mix}
                                onChange={handleChange}


                            />
                        </div> */}
                        {/* <div className='mb-2 d-flex flex-column'>
                            <label className='form-label'>
                                State Tax<span className='text-danger'>*</span>
                            </label>
                            <div className='d-flex'>
                                <Select
                                    showSearch
                                    placeholder="Choose tax"
                                    value={selectedTax1Id || null}
                                    onChange={handleTax1SelectChange}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={taxOptions}
                                    style={{ flexGrow: 1, height: '2.4rem' }}
                                />
                            </div>
                        </div>
                        <div className='mb-2 d-flex flex-column'>
                            <label className='form-label'>
                                Central Tax
                            </label>
                            <div className='d-flex'>
                                <Select
                                    showSearch
                                    placeholder="Choose tax"
                                    value={selectedTax2Id || null}
                                    onChange={handleTax2SelectChange}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={taxOptions}
                                    style={{ flexGrow: 1, height: '2.4rem' }}
                                />
                            </div>
                        </div> */}
                        {/* <div className='mb-2'>
                            <label className='form-label'>Description</label>
                            <textarea
                                className='form-control'
                                type='text'
                                name='description'
                                value={formValues.description}
                                onChange={handleChange}
                                placeholder='Enter notes...'
                            />
                        </div> */}
                        <div className='text-center m-auto'>
                            <button type="submit" className="btn btn-primary col-7" disabled={loading}>
                                {loading ? 'Adding...' : 'Add Product'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Base>
    );
}
