import React, { useState, useEffect, useMemo } from "react";
import Base from "../../Config/Base";
import { Link, useNavigate } from "react-router-dom";
import { API_BASE_CRM_URL } from "../../Config/Config";
import Pagination from "../../Config/Pagination";
import "../../../Assets/popup.css";
import PDFIMG from "../../Config/pdf.svg";
import EXCELIMG from "../../Config/excel.svg";
import Swal from "sweetalert2";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import "../../Config/loading.css";
import { useLabelContext } from "../../../Context";

export default function Proposal() {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [purchasesData, setPurchasesData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [logisticViewOBJ, setLogisticViewOBJ] = useState([]);
    const [logisticEditOBJ, setLogisticEditOBJ] = useState([]);
    const { setID } = useLabelContext();

    useEffect(() => {
        const userDetails = sessionStorage.getItem("userDetails");
        if (!userDetails) {
            navigate("/");
        }
    }, [navigate]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${API_BASE_CRM_URL}GetProposal`);
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();

                if (data.Status) {
                    setPurchasesData(data.ResultData);
                } else {
                    console.error("Fetched data is not an array:", data);
                    setPurchasesData([]);
                }
            } catch (error) {
                console.error("Error fetching datasssss:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleReload = () => {
        window.location.reload();
    };

    const showLogisticViewModal = (obj) => {
        setLogisticViewOBJ(obj);
    };

    const showLogisticEditModal = (obj) => {
        setLogisticEditOBJ(obj);
    };

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    // const filteredItems = purchasesData.filter(
    //   (item) =>
    //     (item.number && item.number.toString().includes(searchTerm.toString())) ||
    //     (item.clientnote &&
    //       item.clientnote.toLowerCase().includes(searchTerm.toLowerCase())) ||
    //     (item.cus_name &&
    //       item.cus_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
    //     (item.proj_name &&
    //       item.proj_name.toLowerCase().includes(searchTerm.toLowerCase()))
    // );

    const filteredItems = useMemo(() => {
        const results = purchasesData.filter(
            (item) =>
                (item.subject && item.subject.toString().includes(searchTerm.toString())) ||
                (item.company &&
                    item.company.toLowerCase().includes(searchTerm.toLowerCase()))

        );

        // Reset current page if the search term changes
        setCurrentPage(1);

        return results;
    }, [purchasesData, searchTerm]);

    // Calculate indices for slicing data
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();

        return `${day}-${month}-${year}`;
    };

    const activeLogisticRate = (id) => {
        const url = `${API_BASE_CRM_URL}ActiveLogisticRate`;
        const payload = { Id: id };

        fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.json();
            })
            .then((data) => {
                if (data.Status) {
                    Swal.fire({
                        title: `Logistic Rate Status Changed Successfully.`,
                        icon: "success",
                    }).then((result) => {
                        if (result.isConfirmed || result.isDismissed) {
                            window.location.reload();
                        }
                    });
                } else {
                    Swal.fire({
                        title: "Error",
                        text: "Failed to submit request.",
                        icon: "error",
                    });
                }
            })
            .catch((error) => {
                console.error("Error status change:", error);
                Swal.fire("Error!", "There was an error changing the status.", "error");
            });
    };

    const handleExcelDownload = () => {
        if (purchasesData.length === 0) {
            return; // Don't download if there is no data
        }

        // Create a new workbook
        const wb = XLSX.utils.book_new();

        // Convert your data to worksheet format
        const wsData = purchasesData.map((item) => ({

            Subject: item.subject,
            to: item.company,
            Assigne: item.staff_fullname,
            "Date ": item.date ? formatDate(item.date) : "",
            "Expiry Date": item.open_till ? formatDate(item.open_till) : "",
        }));

        const ws = XLSX.utils.json_to_sheet(wsData);

        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(wb, ws, "purchasesData");

        // Save the workbook to a file
        XLSX.writeFile(wb, "proposal.xlsx");
    };

    const handlePDFDownload = () => {
        const doc = new jsPDF();

        // Set properties for PDF
        doc.setProperties({
            title: "Purchase Orders",
            author: "Your Name",
            creator: "Your Application",
        });

        // Add data to PDF
        const tableData = []; // Your data array here

        // Add a header row
        const headers = [
            "Subject",
            "To",
            "Assigne",
            "date",
            "open_till",

        ];
        tableData.push(headers);

        // Add rows
        purchasesData.forEach((item) => {
            const row = [];
            row.push(
                item.subject,
                item.company,
                item.staff_fullname,
                item.date,
                item.open_till
            );
            tableData.push(row);
        });

        // Generate table
        doc.autoTable({
            head: [headers],
            body: tableData.slice(1),
        });

        // Save the PDF
        doc.save("proposal.pdf");
    };

    const handleViewPurchaseOrders = (item) => {
        console.log(item);
        setID(item.proposal_id);
    };

    return (
        <Base>
            <div className="page-header">
                <div className="d-flex justify-content-between flex-wrap">
                    <div className="page-title">
                        <h4>Proposal</h4>
                        <h6>Manage your proposal</h6>
                    </div>
                </div>
                <div className="d-flex flex-wrap align-items-center mt-3">
                    <div className="flex-fill d-md-flex justify-content-between align-items-center">
                        <div className="input-group mb-2 mb-md-0">
                            <input
                                type="text"
                                name="search"
                                placeholder="Search..."
                                className="form-control"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                            <button className="btn btn-primary" type="button">
                                <i className="fas fa-search"></i>
                            </button>
                        </div>
                    </div>
                    <ul className="table-top-head d-flex list-unstyled mb-0 flex-wrap">
                        <li className="ms-2 ms-md-3">
                            <a
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Pdf"
                                onClick={handlePDFDownload}
                            >
                                <img src="assets/img/icons/pdf.svg" alt="PDF" />
                            </a>
                        </li>
                        <li className="ms-2 ms-md-3">
                            <a
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Excel"
                                onClick={handleExcelDownload}
                            >
                                <img src="assets/img/icons/excel.svg" alt="Excel" />
                            </a>
                        </li>
                        {/* <li className="ms-2 ms-md-3">
                <a data-bs-toggle="tooltip" data-bs-placement="top" title="Print">
                    <i className="fa-solid fa-print fs-5"></i>
                </a>
            </li> */}
                        <li className="ms-2 ms-md-3">
                            <a
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Refresh"
                                onClick={handleReload}
                            >
                                <i className="fa-solid fa-arrow-rotate-left fs-5"></i>
                            </a>
                        </li>
                        <li className="ms-2 ms-md-3">
                            <a
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Collapse"
                                id="collapse-header"
                            >
                                <i className="fa-solid fa-chevron-up fs-6"></i>
                            </a>
                        </li>
                        <li className="ms-2 ms-md-3">
                            <Link className="btn btn-added" to="/add-proposal">
                                <i className="fa-solid fa-plus"></i>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="card table-list-card">
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table dataew">
                            <thead>
                                <tr>
                                    <th className="no-sort">#</th>
                                    <th className="no-sort text-start">Action</th>
                                    <th>Subject</th>
                                    <th>To</th>
                                    <th>Assigne</th>
                                    <th>Date</th>
                                    <th>Expiry Date</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr colSpan={7} className="text-center">
                                        <td colSpan={7}>
                                            <div className="containers m-auto">
                                                <div className="dot"></div>
                                                <div className="dot"></div>
                                                <div className="dot"></div>
                                                <div className="dot"></div>
                                                <div className="dot"></div>
                                                <div className="dot"></div>
                                            </div>
                                        </td>
                                    </tr>
                                ) : (
                                    currentItems?.map((item, index) => (
                                        <tr key={item.id || index}>
                                            <td>{indexOfFirstItem + index + 1}</td>
                                            <td className="text-center">
                                                <div className="dropdown">
                                                    <a
                                                        className="action-set"
                                                        href="#"
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="false"
                                                    >
                                                        <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                                    </a>
                                                    <div className="dropdown-menu">
                                                        <Link
                                                            to={`/view-proposal`}
                                                            className="dropdown-item"
                                                            onClick={() => handleViewPurchaseOrders(item)}
                                                        >
                                                            <i className="fa-regular fa-eye me-2"></i>View
                                                        </Link>
                                                        <Link
                                                            to={`/edit-proposal`}
                                                            className="dropdown-item"
                                                            onClick={() => handleViewPurchaseOrders(item)}
                                                        >
                                                            <i className="fa-regular fa-pen-to-square text-info me-2"></i> Edit
                                                        </Link>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <Link
                                                    to={`/view-proposal`}
                                                    onClick={() => handleViewPurchaseOrders(item)}
                                                >
                                                    {item.subject}
                                                </Link>
                                            </td>
                                            <td>{item.company}</td>
                                            <td>{item.staff_fullname}</td>
                                            <td>{formatDate(item.date)}</td>
                                            <td>{formatDate(item.open_till)}</td>
                                            <td>
                                                {(() => {
                                                    switch (item.status) {
                                                        case 1:
                                                            return (
                                                                <span className="badge bg-outline-warning">
                                                                    Draft
                                                                </span>
                                                            );
                                                        case 2:
                                                            return (
                                                                <span className="badge bg-outline-info">
                                                                    Sent
                                                                </span>
                                                            );
                                                        case 3:
                                                            return (
                                                                <span className="badge bg-outline-danger">
                                                                    Declined
                                                                </span>
                                                            );
                                                        case 4:
                                                            return (
                                                                <span className="badge bg-outline-success">
                                                                    Accepted
                                                                </span>
                                                            );
                                                        case 5:
                                                            return (
                                                                <span className="badge bg-outline-secondary">
                                                                    Expired
                                                                </span>
                                                            );
                                                        default:
                                                            return (
                                                                <span className="badge bg-outline-dark">
                                                                    Unknown
                                                                </span>
                                                            );
                                                    }
                                                })()}
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                        <Pagination
                            itemsPerPage={itemsPerPage}
                            totalItems={filteredItems.length}
                            paginate={paginate}
                            currentPage={currentPage}
                        />
                    </div>
                </div>
            </div>

            {/* <ViewLogisticRate logisticRateObj={logisticViewOBJ} />
            <EditLogisticRate logisticRateObj={logisticEditOBJ} />
            <AddLogisticRate /> */}
        </Base>
    );
}
