import React, { useState, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { API_BASE_CRM_URL } from "../../Config/Config";
import Pagination from "../../Config/Pagination";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Base from "../../Config/Base";
import Swal from "sweetalert2";
import { useLabelContext } from "../../../Context";
const Transporters = () => {
    const [loading, setLoading] = useState(false);
    const [tasksData, setTasksData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const { setID } = useLabelContext();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${API_BASE_CRM_URL}GetTransporter`);
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();

                if (data.Status) {
                    setTasksData(data.ResultData);
                    console.log(data);
                } else {
                    console.error("Fetched data is not an array:", data);
                    setTasksData([]);
                }
            } catch (error) {
                console.error("Error fetching datasssss:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const filteredItems = useMemo(() => {

        if (!Array.isArray(tasksData)) {
            console.error("tasksData is not an array:", tasksData);
            return [];
        }

        const results = tasksData.filter((item) =>
            item.name && item.name.toString().includes(searchTerm.toString())
        );

        setCurrentPage(1);
        return results;
    }, [tasksData, searchTerm]);



    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

    console.log(currentItems)

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();

        return `${day}-${month}-${year}`;
    };

    const handleReload = () => {
        window.location.reload();
    };

    const handleExcelDownload = () => {
        if (tasksData.length === 0) {
            return;
        }

        const wb = XLSX.utils.book_new();

        const wsData = tasksData.map((item) => ({
            Name: item.name,

            "Start Date": formatDate(item.startdate),
            "Due Date": formatDate(item.duedate),
            "Assigned To": item.staff_names,
            Tags: item.tag_name,
        }));

        const ws = XLSX.utils.json_to_sheet(wsData);

        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(wb, ws, "tasksData");

        // Save the workbook to a file
        XLSX.writeFile(wb, "tasksData.xlsx");
    };

    const handlePDFDownload = () => {
        const doc = new jsPDF();

        // Set properties for PDF
        doc.setProperties({
            title: "Tasks",
            author: "Your Name",
            creator: "Your Application",
        });

        // Add data to PDF
        const tableData = []; // Your data array here

        // Add a header row
        const headers = ["Name", "Start Date", "Due Date", "Assigned to", "Tags"];
        tableData.push(headers);

        // Add rows
        tasksData.forEach((item) => {
            const row = [];
            row.push(
                item.name,
                formatDate(item.startdate),
                formatDate(item.duedate),
                item.staff_names,
                item.tag_name
            );
            tableData.push(row);
        });

        // Generate table
        doc.autoTable({
            head: [headers],
            body: tableData.slice(1),
        });

        // Save the PDF
        doc.save("tasksData.pdf");
    };

    const handleViewPurchaseOrders = (item) => {
        console.log(item);
        setID(item.id);
    };

    const handledelete = async (item) => {
        console.log(item);
        try {
            const response = await fetch(`${API_BASE_CRM_URL}DeleteTask`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ Id: item.id }),
            });
            console.log(response);

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const result = await response.json();
            console.log(result);
            if (result.Status) {
                setLoading(false);
                Swal.fire({
                    title: `Task Deleted Successfully`,
                    icon: "success",
                    customClass: {
                        title: "swal-title",
                        content: "swal-text",
                    },
                }).then((result) => {
                    if (result.isConfirmed || result.isDismissed) {
                        // window.location.reload();
                        // navigate("/delivery-challan");
                    }
                });
            } else {
                setLoading(false);
                Swal.fire({
                    title: "Error",
                    text: "Failed to submit request.",
                    icon: "error",
                    customClass: {
                        title: "swal-title",
                        content: "swal-text",
                    },
                });
            }
        } catch (error) {
            setLoading(false);
            console.error("Error:", error);
        }
    };

    return (
        <Base>
            <div className="page-header">
                <div className="d-flex justify-content-between flex-wrap">
                    <div className="page-title">
                        <h4>transporter</h4>
                        <h6>Manage your transporters</h6>
                    </div>
                </div>
                <div className="d-flex flex-wrap align-items-center mt-3">
                    <div className="flex-fill d-md-flex justify-content-between align-items-center">
                        <div className="input-group mb-2 mb-md-0">
                            <input
                                type="text"
                                name="search"
                                placeholder="Search..."
                                className="form-control"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                            <button className="btn btn-primary" type="button">
                                <i className="fas fa-search"></i>
                            </button>
                        </div>
                    </div>
                    <ul className="table-top-head d-flex list-unstyled mb-0 flex-wrap">
                        <li className="ms-2 ms-md-3">
                            <a
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Pdf"
                                onClick={handlePDFDownload}
                            >
                                <img src="assets/img/icons/pdf.svg" alt="PDF" />
                            </a>
                        </li>
                        <li className="ms-2 ms-md-3">
                            <a
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Excel"
                                onClick={handleExcelDownload}
                            >
                                <img src="assets/img/icons/excel.svg" alt="Excel" />
                            </a>
                        </li>

                        <li className="ms-2 ms-md-3">
                            <a
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Refresh"
                                onClick={handleReload}
                            >
                                <i className="fa-solid fa-arrow-rotate-left fs-5"></i>
                            </a>
                        </li>
                        <li className="ms-2 ms-md-3">
                            <a
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Collapse"
                                id="collapse-header"
                            >
                                <i className="fa-solid fa-chevron-up fs-6"></i>
                            </a>
                        </li>
                        <li className="ms-2 ms-md-3">
                            <Link className="btn btn-added" to="/addtransporter">
                                <i className="fa-solid fa-plus"></i>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="card table-list-card">
                <div className="card-body" style={{ minHeight: "100px" }}>
                    {/* Responsive Wrapper */}
                    <div
                        style={{
                            overflowX: "auto",
                            WebkitOverflowScrolling: "touch",
                            display: "block",
                        }}
                    >
                        <table className="table dataew mb-2" style={{ minWidth: "100%", tableLayout: "auto" }}>
                            <thead>
                                <tr>
                                    <th className="no-sort">#</th>
                                    <th className="no-sort text-start">Action</th>
                                    <th className="no-sort text-start">Name</th>
                                    <th>Phone Number</th>
                                    <th>Email</th>
                                    <th>Aadhaar Number</th>
                                    <th>PAN Number</th>
                                    <th>GST No</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr>
                                        <td colSpan={8} style={{ textAlign: "center" }}>
                                            <div className="containers m-auto">
                                                <div className="dot"></div>
                                                <div className="dot"></div>
                                                <div className="dot"></div>
                                                <div className="dot"></div>
                                                <div className="dot"></div>
                                                <div className="dot"></div>
                                            </div>
                                        </td>
                                    </tr>
                                ) : (
                                    currentItems &&
                                    currentItems.map((item, index) => (
                                        <tr key={index}>
                                            <td>{indexOfFirstItem + index + 1}</td>
                                            <td className="text-center">
                                                <div className="dropdown">
                                                    <a
                                                        className="action-set"
                                                        href="#"
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="false"
                                                    >
                                                        <i
                                                            className="fa fa-ellipsis-v"
                                                            aria-hidden="true"
                                                        ></i>
                                                    </a>
                                                    <div className="dropdown-menu">
                                                        <Link
                                                            className="dropdown-item"
                                                            to={`/edit-transporter`}
                                                            onClick={() => handleViewPurchaseOrders(item)}
                                                        >
                                                            <i className="fa-regular fa-pen-to-square text-info me-2"></i>
                                                            Edit
                                                        </Link>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>{item.name}</td>
                                            <td>{item.contact_no}</td>
                                            <td>{item.email}</td>
                                            <td>{item.aadhaar_no}</td>
                                            <td>{item.pan_card}</td>
                                            <td>{item.gst_no}</td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                    </div>
                    <Pagination
                        itemsPerPage={itemsPerPage}
                        totalItems={filteredItems.length}
                        paginate={paginate}
                        currentPage={currentPage}
                    />
                </div>
            </div>

        </Base>
    );
};

export default Transporters;
