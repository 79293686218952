import React, { useState, useEffect, useMemo } from "react";
import Base from "../../Config/Base";
import { Link, useNavigate } from "react-router-dom";
import { API_BASE_CRM_URL } from "../../Config/Config";
import Pagination from "../../Config/Pagination";
import "../../../Assets/popup.css";
import PDFIMG from "../../Config/pdf.svg";
import EXCELIMG from "../../Config/excel.svg";
import Swal from "sweetalert2";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
// import AddProject from "./AddProject";
// import ViewLogisticRate from "./ViewLogisticRate";
// import EditLogisticRate from "./EditLogisticRate";
// import AddLogisticRate from "./AddLogisticRate";
import "../../Config/loading.css";
import { useLabelContext } from "../../../Context";

export default function CreditNotes() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [creditNotesData, setCreditNotesData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [logisticViewOBJ, setLogisticViewOBJ] = useState([]);
  const [logisticEditOBJ, setLogisticEditOBJ] = useState([]);
  const { setID } = useLabelContext();

  useEffect(() => {
    const userDetails = sessionStorage.getItem("userDetails");
    if (!userDetails) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${API_BASE_CRM_URL}GetCreditNotes`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        console.log(data)

        if (data.Status) {
          setCreditNotesData(data.ResultData);
          console.log(data.ResultData)
        } else {
          console.error("Fetched data is not an array:", data);
          setCreditNotesData([]);
        }
      } catch (error) {
        console.error("Error fetching datasssss:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleReload = () => {
    window.location.reload();
  };

  const showLogisticViewModal = (obj) => {
    setLogisticViewOBJ(obj);
  };

  const showLogisticEditModal = (obj) => {
    setLogisticEditOBJ(obj);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // const filteredItems = creditNotesData.filter(
  //   (item) =>
  //     (item.number && item.number.toString().includes(searchTerm.toString())) ||
  //     (item.clientnote &&
  //       item.clientnote.toLowerCase().includes(searchTerm.toLowerCase())) ||
  //     (item.cus_name &&
  //       item.cus_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
  //     (item.proj_name &&
  //       item.proj_name.toLowerCase().includes(searchTerm.toLowerCase()))
  // );

  const filteredItems = useMemo(() => {
    const results = creditNotesData.filter(
      (item) =>
          (item.number && item.number.toString().includes(searchTerm.toString())) ||
          (item.clientnote &&
             item.clientnote.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (item.cus_name &&
               item.cus_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
             (item.proj_name &&
              item.proj_name.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    
    setCurrentPage(1);

    return results;
  }, [creditNotesData, searchTerm]);

  // Calculate indices for slicing data
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };

  const activeLogisticRate = (id) => {
    const url = `${API_BASE_CRM_URL}ActiveLogisticRate`;
    const payload = { Id: id };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        if (data.Status) {
          Swal.fire({
            title: `Logistic Rate Status Changed Successfully.`,
            icon: "success",
          }).then((result) => {
            if (result.isConfirmed || result.isDismissed) {
              window.location.reload();
            }
          });
        } else {
          Swal.fire({
            title: "Error",
            text: "Failed to submit request.",
            icon: "error",
          });
        }
      })
      .catch((error) => {
        console.error("Error status change:", error);
        Swal.fire("Error!", "There was an error changing the status.", "error");
      });
  };

  const handleDelete = async (item) => {
    console.log(item);
    
    try {
      const response = await fetch(`${API_BASE_CRM_URL}deletecreditnotes`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ Id: item.id}),
      });

      const result = await response.json();

      if (result.Status) {
        // Update the local state to reflect the change
        const newRows = creditNotesData.filter((each) => each.id !== item.id);
       console.log(newRows)  
       setCreditNotesData(newRows)
        Swal.fire("Success", "Deleted successfully", "success");
      } else {
        Swal.fire("Error", "Failed to Delete", "error");
      }
    } catch (error) {
      console.error("Error deleting credit notes:", error);
      Swal.fire(
        "Error",
        "An error occurred while Deleting credit notes",
        "error"
      );
    }
};

  const handleExcelDownload = () => {
    if (creditNotesData.length === 0) {
      return; // Don't download if there is no data
    }

    // Create a new workbook
    const wb = XLSX.utils.book_new();

    // Convert your data to worksheet format
    const wsData = creditNotesData.map((item) => ({
      Id: `CN-${item.id}`,
      Amount: item.total,
      Customer: item.cus_name,
      Project: item.proj_name,
      "Total Tax": item.total_tax,
      Date: item.date ? formatDate(item.date) : "",
      "Expiry Date": item.expirydate ? formatDate(item.expirydate) : "",
    }));

    const ws = XLSX.utils.json_to_sheet(wsData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "creditNotesData");

    // Save the workbook to a file
    XLSX.writeFile(wb, "creditNotes.xlsx");
  };

  const handlePDFDownload = () => {
    const doc = new jsPDF();

    // Set properties for PDF
    doc.setProperties({
      title: "Credit Note",
      author: "Your Name",
      creator: "Your Application",
    });

    // Add data to PDF
    const tableData = []; // Your data array here

    // Add a header row
    const headers = [
      "Id",
      "Amount",
      "Customer",
      "Project",
      "Total Tax",
      "Date",
      "Expiry Date",
    ];
    tableData.push(headers);

    // Add rows
    creditNotesData.forEach((item) => {
      const row = [];
      row.push(
        `CN-${item.id}`,
        item.total,
        item.cus_name,
        item.proj_name,
        item.total_tax,
        item.date ? formatDate(item.date) : "",
        item.expirydate ? formatDate(item.expirydate) : ""
      );
      tableData.push(row);
    });

    // Generate table
    doc.autoTable({
      head: [headers],
      body: tableData.slice(1),
    });

    // Save the PDF
    doc.save("creditNotes.pdf");
  };

  const handleViewPurchaseOrders = (item) => {
    console.log(item);
    setID(item.id);
  };

  return (
    <Base>
      <div className="page-header">
        <div className="d-flex justify-content-between flex-wrap">
          <div className="page-title">
            <h4>Credit Note</h4>
            <h6>Manage your Credit Notes</h6>
          </div>
        </div>
        <div className="d-flex flex-wrap align-items-center mt-3">
          <div className="flex-fill d-md-flex justify-content-between align-items-center">
            <div className="input-group mb-2 mb-md-0">
              <input
                type="text"
                name="search"
                placeholder="Search..."
                className="form-control"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <button className="btn btn-primary" type="button">
                <i className="fas fa-search"></i>
              </button>
            </div>
          </div>
          <ul className="table-top-head d-flex list-unstyled mb-0 flex-wrap">
            <li className="ms-2 ms-md-3">
              <a
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Pdf"
                onClick={handlePDFDownload}
              >
                <img src="assets/img/icons/pdf.svg" alt="PDF" />
              </a>
            </li>
            <li className="ms-2 ms-md-3">
              <a
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Excel"
                onClick={handleExcelDownload}
              >
                <img src="assets/img/icons/excel.svg" alt="Excel" />
              </a>
            </li>
            {/* <li className="ms-2 ms-md-3">
                <a data-bs-toggle="tooltip" data-bs-placement="top" title="Print">
                    <i className="fa-solid fa-print fs-5"></i>
                </a>
            </li> */}
            <li className="ms-2 ms-md-3">
              <a
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Refresh"
                onClick={handleReload}
              >
                <i className="fa-solid fa-arrow-rotate-left fs-5"></i>
              </a>
            </li>
            <li className="ms-2 ms-md-3">
              <a
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Collapse"
                id="collapse-header"
              >
                <i className="fa-solid fa-chevron-up fs-6"></i>
              </a>
            </li>
            <li className="ms-2 ms-md-3">
              <Link className="btn btn-added" to="/add-credit-notes">
                <i className="fa-solid fa-plus"></i>
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="card table-list-card">
        <div className="card-body">
          <div className="table-responsive ">
            <table className="table dataew">
              <thead>
                <tr>
                  <th className="no-sort">#</th>
                  <th className="no-sort text-start">Action</th>
                  <th>Id</th>
                  <th>Amount</th>
                  <th>Customer</th>
                  <th>Project</th>
                  <th>Total Tax</th>
                  <th>Date</th>
                  <th>Expiry Date</th>
                  {/* <th>Status</th> */}
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <td colSpan={12} className="etxt-center">
                    <p className="text-center">
                      <div className="containers m-auto">
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                      </div>
                    </p>
                  </td>
                ) : (
                  currentItems &&
                  currentItems.map((item, index) => (
                    <tr key={index}>
                      <td>{indexOfFirstItem + index + 1}</td>
                      <td className="text-center">
                        <div className="dropdown">
                          <a
                            className="action-set"
                            href="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i
                              className="fa fa-ellipsis-v"
                              aria-hidden="true"
                            ></i>
                          </a>
                          <div className="dropdown-menu">
                            <Link
                              to={`/view-credit-notes`}
                              className="dropdown-item"
                              onClick={() => handleViewPurchaseOrders(item)}
                            >
                              <i className="fa-regular fa-eye me-2"></i>View
                            </Link>
                            <Link
                              className={`dropdown-item`}
                              to={`/edit-credit-notes`}
                              onClick={() => handleViewPurchaseOrders(item)}
                            >
                              <i className="fa-regular fa-pen-to-square text-info me-2"></i>
                              Edit
                            </Link>
                            <button
                              className={`dropdown-item`}
                              // href="javascript:void(0);"
                              onClick={() => handleDelete(item)}
                            >
                              <i className="fa-regular fa-trash-can text-danger me-2"  
                             ></i>
                              Delete
                            </button>
                          </div>
                        </div>
                      </td>
                      <td>
                        <Link to={`/view-purchase-order/${item.id}`}>
                          CN-{item.id}
                        </Link>
                      </td>
                      <td>{item.total}</td>
                      <td>{item.cus_name ? item.cus_name : "N/A"}</td>
                      <td>{item.proj_name ? item.proj_name : "N/A"}</td>
                      <td>{item.total_tax}</td>
                      <td>{formatDate(item.date)}</td>
                      <td>
                        {item.expirydate ? formatDate(item.expirydate) : "N/A"}
                      </td>
                      {/* <td>
                                            {(() => {
                                                switch (item.status) {
                                                    case 1:
                                                        return <span className="badge bg-outline-warning">Draft</span>;
                                                    case 2:
                                                        return <span className="badge bg-outline-info">Sent</span>;
                                                    case 3:
                                                        return <span className="badge bg-outline-danger">Declined</span>;
                                                    case 4:
                                                        return <span className="badge bg-outline-success">Accepted</span>;
                                                    case 5:
                                                        return <span className="badge bg-outline-secondary">Expired</span>;
                                                    default:
                                                        return <span className="badge bg-outline-dark">Unknown</span>;
                                                }
                                            })()}
                                        </td> */}
                    </tr>
                  ))
                )}
              </tbody>
            </table>
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={filteredItems.length}
              paginate={paginate}
              currentPage={currentPage}
            />
          </div>

          {/* Mobile View */}
          {/* <div className="row d-block d-md-none d-lg-none" style={{ maxHeight: '100vh', overflowY: 'auto' }}>
                        {loading ? 
                            <td colSpan={12} className="text-center">
                                <p className="text-center">
                                    <div className="containers m-auto">
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                    </div>
                                </p>
                            </td> : 
                            currentItems && currentItems.map((item, index) => ( 
                            <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6" key={index}>
                                <div className="employee-grid-profile">
                                    <div className="profile-head">
                                        <p>{formatDate(item.createdon)}</p>
                                        <div className="profile-head-action">
                                            {item.IsActive === 1 ? (
                                                <span className="badge bg-outline-success text-center w-auto me-1">Active</span>
                                            ) : (
                                                <span className="badge bg-outline-danger text-center w-auto me-1">Inactive</span>
                                            )}
                                            <div className="dropdown profile-action">
                                                <a href="#" className="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><svg
                                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                        stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                                        className="feather feather-more-vertical feather-user">
                                                        <circle cx="12" cy="12" r="1"></circle>
                                                        <circle cx="12" cy="5" r="1"></circle>
                                                        <circle cx="12" cy="19" r="1"></circle>
                                                    </svg></a>
                                                <ul className="dropdown-menu">
                                                    <li className=" mt-2">
                                                        <a 
                                                            className="dropdown-item"  
                                                            data-bs-toggle="modal" 
                                                            data-bs-target="#view-logistic-rate" 
                                                            onClick={() => showLogisticViewModal(item)}
                                                        >
                                                            <i className="fa-regular fa-eye me-2"></i> View
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a 
                                                            className="dropdown-item"  
                                                            data-bs-toggle="modal" 
                                                            data-bs-target="#edit-logistic-rate"
                                                            onClick={() => showLogisticEditModal(item)}
                                                        >
                                                            <i className="fa-regular fa-pen-to-square me-2 text-info"></i>Edit
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a className="dropdown-item confirm-text mb-0">
                                                            <i className="fa-regular fa-trash-can me-2 text-danger"></i>Delete
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <table className="table">
                                        <tbody>
                                            <tr className="text-start">
                                                <th>{item.name}</th>
                                                <th>{item.weight} {item.units}</th>
                                                <th>Rs.{item.price}/-</th>
                                            </tr>
                                            <tr className="text-start">
                                                <th>{item.min_distance}</th>
                                                <th>-</th>
                                                <th>{item.max_distance}</th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                         ))}
                    </div> */}
        </div>
      </div>
      {/* <ViewLogisticRate logisticRateObj={logisticViewOBJ} />
            <EditLogisticRate logisticRateObj={logisticEditOBJ} />
            <AddLogisticRate /> */}
    </Base>
  );
}
