import React, { useState, useEffect, useMemo } from "react";
import Base from "../../Config/Base";
import { API_BASE_CRM_URL } from "../../Config/Config";
import Pagination from "../../Config/Pagination";
import "../../../Assets/popup.css";
import PDFIMG from "../../Config/pdf.svg";
import EXCELIMG from "../../Config/excel.svg";
import Swal from "sweetalert2";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import ViewLogisticRate from "./ViewLogisticRate";
import EditLogisticRate from "./EditLogisticRate";
import AddLogisticRate from "./AddLogisticRate";
import "../../Config/loading.css";
import { Link, useNavigate } from "react-router-dom";

export default function LogisticRate() {
  const navigate = useNavigate();

  useEffect(() => {
    const userDetails = sessionStorage.getItem("userDetails");
    if (!userDetails) {
      navigate("/");
    }
  }, [navigate]);

  const [loading, setLoading] = useState(false);
  const [logisticRateData, setLogisticRateData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [logisticViewOBJ, setLogisticViewOBJ] = useState([]);
  const [logisticEditOBJ, setLogisticEditOBJ] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${API_BASE_CRM_URL}GetLogisticRates`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();

        if (data.Status) {
          setLogisticRateData(data.ResultData);
          console.log(data.ResultData)
        } else {
          console.error("Fetched data is not an array:", data);
          setLogisticRateData([]);
        }
      } catch (error) {
        console.error("Error fetching datasssss:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleReload = () => {
    window.location.reload();
  };

  const showLogisticViewModal = (obj) => {
    setLogisticViewOBJ(obj);
  };

  const showLogisticEditModal = (obj) => {
    setLogisticEditOBJ(obj);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // const filteredItems = logisticRateData.filter(
  //   (item) =>
  //     (item.name &&
  //       item.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
  //     (item.units &&
  //       item.units.toLowerCase().includes(searchTerm.toLowerCase()))
  // );

  const filteredItems = useMemo(() => {
    const results = logisticRateData.filter(
      (item) =>
        (item.name &&
          item.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (item.units &&
          item.units.toLowerCase().includes(searchTerm.toLowerCase()))
    );
 
    setCurrentPage(1);

    return results;
  }, [logisticRateData, searchTerm]);

  // Calculate indices for slicing data
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };

  const activeLogisticRate = (id) => {
    const url = `${API_BASE_CRM_URL}ActiveLogisticRate`;
    const payload = { Id: id };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        if (data.Status) {
          Swal.fire({
            title: `Logistic Rate Status Changed Successfully.`,
            icon: "success",
          }).then((result) => {
            if (result.isConfirmed || result.isDismissed) {
              window.location.reload();
            }
          });
        } else {
          Swal.fire({
            title: "Error",
            text: "Failed to submit request.",
            icon: "error",
          });
        }
      })
      .catch((error) => {
        console.error("Error status change:", error);
        Swal.fire("Error!", "There was an error changing the status.", "error");
      });
  };

  const handleExcelDownload = () => {
    if (logisticRateData.length === 0) {
      return; // Don't download if there is no data
    }

    // Create a new workbook
    const wb = XLSX.utils.book_new();

    // Convert your data to worksheet format
    const wsData = logisticRateData.map((item) => ({
      Name: item.name,
      Weight: item.weight,
      "Min Distance": item.min_distance,
      "Max Distance": item.max_distance,
      Price: item.price,
    }));

    const ws = XLSX.utils.json_to_sheet(wsData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "logisticRateData");

    // Save the workbook to a file
    XLSX.writeFile(wb, "logisticRateData.xlsx");
  };

  const handlePDFDownload = () => {
    const doc = new jsPDF();

    // Set properties for PDF
    doc.setProperties({
      title: "Logistic Rate ",
      author: "Your Name",
      creator: "Your Application",
    });

    // Add data to PDF
    const tableData = []; // Your data array here

    // Add a header row
    const headers = ["Name", "Weight", "Min Distance", "Max Distance", "Price"];
    tableData.push(headers);

    // Add rows
    logisticRateData.forEach((item) => {
      const row = [];
      row.push(
        item.name,
        item.weight,
        item.min_distance,
        item.max_distance,
        item.price
      );
      tableData.push(row);
    });

    // Generate table
    doc.autoTable({
      head: [headers],
      body: tableData.slice(1),
    });

    // Save the PDF
    doc.save("logisticRateData.pdf");
  };

  return (
    <Base>
      <div className="page-header">
        <div className="d-flex justify-content-between flex-wrap">
          <div className="page-title">
            <h4>Logistic Rate </h4>
            <h6>Manage your Logistic Rate</h6>
          </div>
        </div>
        <div className="d-flex flex-wrap align-items-center mt-3">
          <div className="flex-fill d-md-flex justify-content-between align-items-center">
            <div className="input-group mb-2 mb-md-0">
              <input
                type="text"
                name="search"
                placeholder="Search..."
                className="form-control"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <button className="btn btn-primary" type="button">
                <i className="fas fa-search"></i>
              </button>
            </div>
          </div>
          <ul className="table-top-head d-flex list-unstyled mb-0 flex-wrap">
            <li className="ms-2 ms-md-3">
              <a
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Pdf"
                onClick={handlePDFDownload}
              >
                <img src="assets/img/icons/pdf.svg" alt="PDF" />
              </a>
            </li>
            <li className="ms-2 ms-md-3">
              <a
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Excel"
                onClick={handleExcelDownload}
              >
                <img src="assets/img/icons/excel.svg" alt="Excel" />
              </a>
            </li>
            {/* <li className="ms-2 ms-md-3">
                <a data-bs-toggle="tooltip" data-bs-placement="top" title="Print">
                    <i className="fa-solid fa-print fs-5"></i>
                </a>
            </li> */}
            <li className="ms-2 ms-md-3">
              <a
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Refresh"
                onClick={handleReload}
              >
                <i className="fa-solid fa-arrow-rotate-left fs-5"></i>
              </a>
            </li>
            <li className="ms-2 ms-md-3">
              <a
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Collapse"
                id="collapse-header"
              >
                <i className="fa-solid fa-chevron-up fs-6"></i>
              </a>
            </li>
            <li className="ms-2 ms-md-3">
              <a
                className="btn btn-added"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasAdd"
                aria-controls="offcanvasAdd"
              >
                <i className="fa-solid fa-plus"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="card table-list-card">
        <div className="card-body">
          <div className="table-top">
            <div className="search-set">
              <div className="search-input">
                <input
                  type="text"
                  name="search"
                  placeholder="Search..."
                  className="form-control"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="table-responsive ">
            <table className="table dataew">
              <thead>
                <tr>
                  <th className="no-sort">
                    <label className="checkboxs">
                      <input type="checkbox" id="select-all" />
                      <span className="checkmarks"></span>
                    </label>
                  </th>
                  <th>Name</th>
                  <th>Weight</th>
                  {/* <th>Units</th> */}
                  <th>Min Distance</th>
                  <th>Max Distance</th>
                  <th>Price</th>
                  <th>Status</th>
                  <th className="no-sort">Action</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <td colSpan={12} className="etxt-center">
                    <p className="text-center">
                      <div class="containers m-auto">
                        <div class="dot"></div>
                        <div class="dot"></div>
                        <div class="dot"></div>
                        <div class="dot"></div>
                        <div class="dot"></div>
                        <div class="dot"></div>
                      </div>
                    </p>
                  </td>
                ) : (
                  currentItems &&
                  currentItems.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <label className="checkboxs">
                          <input type="checkbox" />
                          <span className="checkmarks"></span>
                        </label>
                      </td>
                      <td>{item.name}</td>
                      <td>{item.weight}</td>
                      {/* <td>{item.units}</td> */}
                      <td>{item.min_distance}</td>
                      <td>{item.max_distance}</td>
                      <td>{item.price}</td>
                      <td>
                        {item.IsActive === 1 ? (
                          <span className="badge bg-outline-success">
                            Active
                          </span>
                        ) : (
                          <span className="badge bg-outline-danger">
                            Inactive
                          </span>
                        )}
                      </td>
                      <td className="action-table-data">
                        <div className="edit-delete-action">
                          <a
                            className="dropdown-item"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasView"
                            aria-controls="offcanvasView"
                            onClick={() => showLogisticViewModal(item)}
                          >
                            <i className="fa-regular fa-eye"></i>
                          </a>
                          <a
                            className="me-2 p-2 ms-2"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasEdit"
                            aria-controls="offcanvasEdit"
                            onClick={() => showLogisticEditModal(item)}
                          >
                            <i className="fa-regular fa-pen-to-square text-info"></i>
                          </a>
                          <a
                            className="confirm-text p-2"
                            title={item.IsActive === 1 ? "Active" : "Inactive"}
                            onClick={() => activeLogisticRate(item.id)}
                          >
                            <i
                              className={`fa-solid fa-toggle-${
                                item.IsActive
                                  ? "off text-danger"
                                  : "on text-success"
                              } me-2`}
                            ></i>
                          </a>
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={filteredItems.length}
              paginate={paginate}
              currentPage={currentPage}
            />
          </div>

          {/* Mobile View */}
          {/* <div className="row d-block d-md-none d-lg-none" style={{ maxHeight: '100vh', overflowY: 'auto' }}>
                        {loading ? 
                            <td colSpan={12} className="text-center">
                                <p className="loader m-auto my-5"></p>
                            </td> : 
                            currentItems && currentItems.map((item, index) => ( 
                            <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6" key={index}>
                                <div className="employee-grid-profile">
                                    <div className="profile-head">
                                        <p>{formatDate(item.createdon)}</p>
                                        <div className="profile-head-action">
                                            {item.IsActive === 1 ? (
                                                <span className="badge bg-outline-success text-center w-auto me-1">Active</span>
                                            ) : (
                                                <span className="badge bg-outline-danger text-center w-auto me-1">Inactive</span>
                                            )}
                                            <div className="dropdown profile-action">
                                                <a href="#" className="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><svg
                                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                        className="feather feather-more-vertical feather-user">
                                                        <circle cx="12" cy="12" r="1"></circle>
                                                        <circle cx="12" cy="5" r="1"></circle>
                                                        <circle cx="12" cy="19" r="1"></circle>
                                                    </svg></a>
                                                <ul className="dropdown-menu">
                                                    <li className=" mt-2">
                                                        <a 
                                                            className="dropdown-item"  
                                                            data-bs-toggle="modal" 
                                                            data-bs-target="#view-logistic-rate" 
                                                            onClick={() => showLogisticViewModal(item)}
                                                        >
                                                            <i className="fa-regular fa-eye me-2"></i> View
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a 
                                                            className="dropdown-item"  
                                                            data-bs-toggle="modal" 
                                                            data-bs-target="#edit-logistic-rate"
                                                            onClick={() => showLogisticEditModal(item)}
                                                        >
                                                            <i className="fa-regular fa-pen-to-square me-2 text-info"></i>Edit
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a className="dropdown-item confirm-text mb-0">
                                                            <i className="fa-regular fa-trash-can me-2 text-danger"></i>Delete
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <table className="table">
                                        <tbody>
                                            <tr className="text-start">
                                                <th>{item.name}</th>
                                                <th>{item.weight} {item.units}</th>
                                                <th>Rs.{item.price}/-</th>
                                            </tr>
                                            <tr className="text-start">
                                                <th>{item.min_distance}</th>
                                                <th>-</th>
                                                <th>{item.max_distance}</th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                         ))}
                    </div> */}
        </div>
      </div>
      <ViewLogisticRate logisticRateObj={logisticViewOBJ} />
      <EditLogisticRate logisticRateObj={logisticEditOBJ} />
      <AddLogisticRate />
    </Base>
  );
}
