import React, { useState, useEffect } from 'react';
import Base from '../../Config/Base';
import { Link } from 'react-router-dom';
import { API_BASE_CRM_URL } from "../../Config/Config";
import Swal from "sweetalert2";
import { useNavigate } from 'react-router-dom';
import '../../Config/swal.css';
import { Select } from 'antd';



export default function AddExpense() {

    const [userDetails, SetUserDetails] = useState([]);
    const [customersData, setCustomersData] = useState([]);
    const [selectedCustomerid, setSelectedCustomerid] = useState("");

    const [categoriesData, setCategoriesData] = useState([]);
    const [selectedCategoryid, setSelectedCategoryid] = useState("");

    const [paymentModeData, setPaymentModeData] = useState([]);
    const [selectedPaymentid, setSelectedPaymentid] = useState("");

    const [taxesData, setTaxesData] = useState([]);
    const [selectedTaxid, setSelectedTaxid] = useState("");

    const [proejctsData, setProejctsData] = useState([]);
    const [selectedProjectid, setSelectedProjectid] = useState("");

    const [selectedDate, setSelectedDate] = useState("");

    useEffect(() => {
        const userDetailsSession = JSON.parse(sessionStorage.getItem('userDetails'));
        SetUserDetails(userDetailsSession);
    }, []);

    useEffect(() => {
        const fetchAllData = async () => {
            try {
                const [customersResponse, categoriesResponse, paymentModesResponse, taxesResponse, projectsResponse] = await Promise.all([
                    fetch(`${API_BASE_CRM_URL}GetCustomers`),
                    fetch(`${API_BASE_CRM_URL}GetExpenseCategories`),
                    fetch(`${API_BASE_CRM_URL}GetPaymentModes`),
                    fetch(`${API_BASE_CRM_URL}GetTaxes`),
                    fetch(`${API_BASE_CRM_URL}GetProjects`)
                ]);

                if (!customersResponse.ok || !categoriesResponse.ok || !paymentModesResponse.ok || !taxesResponse.ok || !projectsResponse.ok) {
                    throw new Error("Failed to fetch data");
                }

                const customersData = await customersResponse.json();
                const categoriesData = await categoriesResponse.json();
                const paymentModesData = await paymentModesResponse.json();
                const taxesData = await taxesResponse.json();
                const projectsData = await projectsResponse.json();

                if (customersData.Status) setCustomersData(customersData.ResultData);
                if (categoriesData.Status) setCategoriesData(categoriesData.ResultData);
                if (paymentModesData.Status) setPaymentModeData(paymentModesData.ResultData);
                if (taxesData.Status) setTaxesData(taxesData.ResultData);
                if (projectsData.Status) setProejctsData(projectsData.ResultData);

            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchAllData();
    }, []);


    const customerOptions = customersData && customersData.map((item) => ({
        value: item.userid,
        label: item.company,
    }));

    const categoryOptions = categoriesData && categoriesData.map((item) => ({
        value: item.id,
        label: item.name,
    }));

    const taxOptions = taxesData && taxesData.map((item) => ({
        key: item.id,
        value: item.taxrate,
        label: item.name + " " + item.taxrate,
    }));

    const paymentModeOptions = paymentModeData && paymentModeData.map((item) => ({
        value: item.id,
        label: item.name,
    }));

    const projectOptions = proejctsData && proejctsData.map((item) => ({
        value: item.id,
        label: item.name,
    }));


    const handleCustomerChange = (selectedone) => {
        setSelectedCustomerid(selectedone);
    };

    const handleCategoryChange = (selectedone) => {
        setSelectedCategoryid(selectedone);
    };

    const handleTaxChange = (selectedone) => {
        setSelectedTaxid(selectedone);
    };

    const handlePaymentChange = (selectedone) => {
        setSelectedPaymentid(selectedone);
    };

    const handleProjectChange = (selectedone) => {
        setSelectedProjectid(selectedone);
    };

    const handleSelectDate = (e) => {
        setSelectedDate(e.target.value);
    }

    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        category: "",
        currency: 3,
        amount: "",
        tax: "",
        tax2: "",
        reference_no: "",
        note: "",
        expense_name: "",
        clientid: "",
        project_id: "",
        billable: "",
        invoiceid: "",
        paymentmode: "",
        date: "",
        recurring_type: "null",
        repeat_every: 0,
        recurring: 0,
        cycles: 0,
        total_cycles: 0,
        custom_recurring: 0,
        last_recurring_date: null,
        create_invoice_billable: 1,
        send_invoice_to_customer: 1,
        recurring_from: null,
        addedfrom: userDetails.staffid
    });
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        // Create the data object to send as JSON
        const formDataToSend = {
            category: selectedCategoryid,
            currency: 3,
            amount: formData.amount,
            tax: selectedTaxid,
            tax2: selectedTaxid,
            reference_no: formData.reference_no,
            note: formData.note,
            expense_name: formData.expense_name,
            clientid: selectedCustomerid,
            project_id: selectedProjectid,
            billable: 1,
            invoiceid: 0,
            paymentmode: selectedPaymentid,
            date: selectedDate,
            recurring_type: "monthly",
            repeat_every: 0,
            recurring: 0,
            cycles: 0,
            total_cycles: 0,
            custom_recurring: 0,
            last_recurring_date: null,
            create_invoice_billable: 1,
            send_invoice_to_customer: 1,
            recurring_from: null,
            addedfrom: userDetails.staffid
        };

        console.log(formDataToSend, 'data to api')

        try {
            const response = await fetch(`${API_BASE_CRM_URL}AddExpense`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formDataToSend)
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();
            console.log(result, 'rrrrrr');
            if (result.Status) {
                setLoading(false);
                Swal.fire({
                    title: `Expense Added Successfully.`,
                    icon: "success",
                    customClass: {
                        title: 'swal-title',
                        content: 'swal-text'
                    }
                }).then((result) => {
                    if (result.isConfirmed || result.isDismissed) {
                        // window.location.reload();
                        navigate('/expenses');
                    }
                });
            } else {
                setLoading(false);
                Swal.fire({
                    title: "Error",
                    text: "Failed to submit request.",
                    icon: "error",
                    customClass: {
                        title: 'swal-title',
                        content: 'swal-text'
                    }
                });
            }
        } catch (error) {
            console.error('Error:', error);
            setLoading(false);
            Swal.fire({
                title: "Error",
                text: "An error occurred while submitting the request.",
                icon: "error",
            });
        }
    };


    return (
        <Base>
            <div className="page-heade mb-3">
                {/* <div className="container"> */}
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <div className="page-title">
                            <h4>Add Expense</h4>
                            <p className='fw-semibold'>Fill out the form to add a new expense</p>
                        </div>
                    </div>
                    <div className="col-md-6 d-flex justify-content-md-end justify-content-start">
                        <Link to='/expenses'>
                            <button className="btn btn-primary" style={{ borderRadius: '10px' }}>
                                <i className="fa-solid fa-arrow-left me-2"></i> Go to List
                            </button>
                        </Link>
                    </div>
                    {/* </div> */}
                </div>
            </div>

            <div className="col-xxl-12 col-xl-12" >
                <div className="card">
                    <div className="card-body" style={{ maxHeight: '100vh', overflowY: 'auto' }}>
                        <form onSubmit={handleSubmit}>
                            <ul className="nav nav-tabs tab-style-2 nav-justified mb-3 d-sm-flex d-block" id="myTab1"
                                role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="order-tab" data-bs-toggle="tab"
                                        data-bs-target="#order-tab-pane" type="button" role="tab" aria-controls="order-tab-pane"
                                        aria-selected="true">
                                        <i className="fa-regular fa-address-book"></i>Info
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="confirmed-tab" data-bs-toggle="tab"
                                        data-bs-target="#confirm-tab-pane" type="button" role="tab" aria-controls="confirm-tab-pane"
                                        aria-selected="false">
                                        <i className="fa-solid fa-map-location-dot"></i>Advanced
                                    </button>
                                </li>
                            </ul>
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active text-muted" id="order-tab-pane" role="tabpanel"
                                    aria-labelledby="order-tab" tabIndex="0">
                                    <div className='row m-3'>
                                        <div className='col-md-4 col-lg-4 mb-2'>
                                            <label className='form-label'>Name</label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                name='expense_name'
                                                value={formData.expense_name}
                                                placeholder='Enter expense name'
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div className='col-md-4 col-lg-4'>
                                            <label className='form-label'>Date<span className='text-danger'>*</span></label>
                                            <input
                                                className='form-control'
                                                type='date'
                                                onChange={handleSelectDate}
                                                required
                                            />
                                        </div>
                                        <div className='col-md-4 col-lg-4'>
                                            <label className='form-label'>Amount<span className='text-danger'>*</span></label>
                                            <input
                                                className='form-control'
                                                type='number'
                                                name='amount'
                                                value={formData.amount}
                                                placeholder='Enter amount'
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div className='col-md-4 col-lg-4 d-flex flex-column'>
                                            <label className='form-label'>Category<span className='text-danger'>*</span></label>
                                            <Select
                                                showSearch
                                                placeholder="Choose Category"
                                                value={selectedCategoryid || null}
                                                onChange={handleCategoryChange}
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                options={categoryOptions}
                                                style={{ height: '2.4rem' }}
                                            />
                                        </div>
                                        <div className='col-md-4 col-lg-4 d-flex flex-column'>
                                            <label className='form-label'>Customer</label>
                                            <Select
                                                showSearch
                                                placeholder="Choose Customer"
                                                value={selectedCustomerid || null}
                                                onChange={handleCustomerChange}
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                options={customerOptions}
                                                style={{ height: '2.4rem' }}
                                            />
                                        </div>
                                        <div className='col-md-4 col-lg-4 d-flex flex-column'>
                                            <label className='form-label'>Project</label>
                                            <Select
                                                showSearch
                                                placeholder="Choose Project"
                                                value={selectedProjectid || null}
                                                onChange={handleProjectChange}
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                options={projectOptions}
                                                style={{ height: '2.4rem' }}
                                            />
                                        </div>
                                        <div className='col-12 mt-2'>
                                            <label className='form-label'>Description</label>
                                            <textarea
                                                className='form-control'
                                                name='note'
                                                value={formData.note}
                                                placeholder='Enter notes...'
                                                onChange={handleChange}
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>

                                <div className="tab-pane fade text-muted" id="confirm-tab-pane" role="tabpanel"
                                    aria-labelledby="confirmed-tab" tabIndex="0">
                                    <div className='row m-3'>
                                        <div className='col-md-4 col-lg-4'>
                                            <label className='form-label'>Currency</label>
                                            <input
                                                className='form-control'
                                                value="INR Rs"
                                                readOnly
                                            />
                                        </div>
                                        <div className='col-md-4 col-lg-4 d-flex flex-column'>
                                            <label className='form-label'>Tax</label>
                                            <Select
                                                showSearch
                                                placeholder="Choose Tax"
                                                value={selectedTaxid || null}
                                                onChange={handleTaxChange}
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                options={taxOptions.map(option => ({ ...option, key: option.key }))}
                                                style={{ height: '2.4rem' }}
                                            />
                                        </div>
                                        <div className='col-md-4 col-lg-4 d-flex flex-column'>
                                            <label className='form-label'>Payment Mode</label>
                                            <Select
                                                showSearch
                                                placeholder="Choose Tax"
                                                value={selectedPaymentid || null}
                                                onChange={handlePaymentChange}
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                options={paymentModeOptions}
                                                style={{ height: '2.4rem' }}
                                            />
                                        </div>
                                        <div className='col-md-4 col-lg-4'>
                                            <label className='form-label'>Reference no</label>
                                            <input
                                                className='form-control'
                                                type='text'
                                                placeholder='Enter reference number'
                                                name='reference_no'
                                                value={formData.reference_no}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className='col-md-4 col-lg-4'>
                                            <label className='form-label'>Supplier</label>
                                            <input
                                                className='form-control'
                                                type='text'
                                                name='supplier'
                                                value={formData.supplier}
                                                placeholder='Enter supplier name'
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary mt-3 d-flex m-auto">{loading ? 'Submitting...' : 'Submit'}</button>
                        </form>
                    </div>
                </div>
            </div>
        </Base>
    );
}
