import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { API_BASE_CRM_URL } from "../../Config/Config";
import { Select } from "antd";

export default function EditRate({ locationObj }) {
    const [productsData, setProductsData] = useState([]);
    const [selectedproductid, setselectedproductid] = useState([]);
    const [rateData, setRateData] = useState({
        id: "",
        Product: "",
        BricksPerPallet: "",
        Machine: "",
        Rate: ""
    });

    const [loading, setLoading] = useState(false);

    // Populate the form when `locationObj` is passed
    useEffect(() => {
        if (locationObj) {
            setRateData({
                id: locationObj.id || "",
                Product: locationObj.description || "",
                BricksPerPallet: locationObj.BricksPerPallet || "",
                Machine: locationObj.Machine || "",
                Rate: locationObj.Rate || "",
            });
        }
    }, [locationObj]);
    useEffect(() => {
        // Fetch products data
        const fetchData = async () => {
            try {
                const response = await fetch(`${API_BASE_CRM_URL}GetProducts`);
                const data = await response.json();
                if (data.Status) {
                    setProductsData(data.ResultData);
                } else {
                    console.error("Failed to fetch products");
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);
    const productOptions = productsData.map((item) => ({
        value: item.id, // Use the product ID here
        label: item.description, // Display description
    }));

    const handleProductChange = (value) => {
        console.log("Selected Product ID:", value); // Log the product ID
        setselectedproductid(value);
    };

    // Handle input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setRateData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        const { id, Product, BricksPerPallet, Machine, Rate } = rateData;

        if (!Product || !BricksPerPallet || !Machine || !Rate) {
            Swal.fire("Error", "All fields are required!", "error");
            return;
        }

        try {
            setLoading(true);

            const response = await fetch(`${API_BASE_CRM_URL}UpdateRate`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ id, Product, BricksPerPallet, Machine, Rate }),
            });

            const result = await response.json();

            if (result.Status) {
                Swal.fire("Success", "Rate updated successfully!", "success");
                window.location.reload();
            } else {
                Swal.fire("Error", result.Message || "Failed to update rate.", "error");
            }
        } catch (error) {
            console.error("Error updating rate:", error);
            Swal.fire("Error", "Something went wrong!", "error");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <style>
                {`
                    .offcanvas.custom-offcanvas {
                        width: 400px !important;
                    }

                    @media (max-width: 576px) {
                        .offcanvas.custom-offcanvas {
                            width: 70% !important;
                        }
                    }
                `}
            </style>

            <div
                className="offcanvas offcanvas-end custom-offcanvas"
                tabIndex="-1"
                id="offcanvasRightEdit"
                aria-labelledby="offcanvasRightLabel"
            >
                <div className="offcanvas-header">
                    <h5 id="offcanvasRightLabel">Edit Rate</h5>
                    <button
                        type="button"
                        className="btn-close text-reset"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                    ></button>
                </div>
                <div className="offcanvas-body">
                    <form onSubmit={handleSubmit}>
                        <div className="mt-2 d-flex flex-column mb-2">
                            <label className="form-label">Product</label>
                            <Select
                                showSearch
                                placeholder="Select Product"
                                value={selectedproductid}
                                onChange={handleProductChange} // Log and set product ID
                                filterOption={(input, option) =>
                                    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                                }
                                options={productOptions}
                            />
                        </div>

                        <div className="mb-2">
                            <label className="form-label">Bricks Per Pallet</label>
                            <input
                                type="number"
                                className="form-control"
                                name="BricksPerPallet"
                                value={rateData.BricksPerPallet}
                                onChange={handleInputChange}
                                required
                            />
                        </div>

                        <div className="mb-2">
                            <label className="form-label">Machine</label>
                            <input
                                type="text"
                                className="form-control"
                                name="Machine"
                                value={rateData.Machine}
                                onChange={handleInputChange}
                                required
                            />
                        </div>

                        <div className="mb-2">
                            <label className="form-label">Rate</label>
                            <input
                                type="number"
                                className="form-control"
                                name="Rate"
                                value={rateData.Rate}
                                onChange={handleInputChange}
                                required
                            />
                        </div>

                        <button
                            type="submit"
                            className="btn btn-primary d-flex m-auto mt-4"
                            disabled={loading}
                        >
                            {loading ? "Updating..." : "Update"}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}
