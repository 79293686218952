import React, { useState, useEffect } from "react";
import { API_BASE_CRM_URL } from "../../Config/Config";
import Swal from "sweetalert2";
import "../../Config/swal.css";
import { Select } from "antd";

export default function AddDrivers() {
    const [userDetails, setUserDetails] = useState([]);
    const [name, setName] = useState("");
    const [transporter, setTransporter] = useState(null);
    const [contactNumber, setContactNumber] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [status, setStatus] = useState(1);
    const [successMessage, setSuccessMessage] = useState("");
    const [transporterData, setTransporterData] = useState([]);

    // Fetch transporter data on component mount
    useEffect(() => {
        const fetchTransporterData = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${API_BASE_CRM_URL}GetTransporter`);
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();
                if (data.Status) {
                    setTransporterData(data.ResultData);
                } else {
                    console.error("Fetched data is invalid:", data);
                    setTransporterData([]);
                }
            } catch (error) {
                console.error("Error fetching transporter data:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchTransporterData();
    }, []);

    const transporterOptions = transporterData.map((transporter) => ({
        label: transporter.name,
        value: transporter.id,
    }));

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError("");
        setSuccessMessage("");

        if (!name || !transporter) {
            setError("Please fill in all required fields.");
            setLoading(false);
            return;
        }

        const requestData = {
            Name: name,
            Transporter_id: transporter, 
            ContactNo: contactNumber,
            Status: status,
        };

        try {
            const response = await fetch(`${API_BASE_CRM_URL}AddDriver`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(requestData),
            });

            const data = await response.json();

            if (data.Status) {
                setSuccessMessage("Driver added successfully.");
                // Clear form fields
                setName("");
                setTransporter(null);
                setContactNumber("");
                window.location.reload();
            } else {
                setError("Failed to add driver.");
            }
        } catch (error) {
            console.error("Error:", error);
            setError("An error occurred while adding the driver.");
        } finally {
            setLoading(false);
        }
    };

    const handleTransporterChange = (value) => {
        setTransporter(value);
    };

    useEffect(() => {
        const userDetailsSession = JSON.parse(sessionStorage.getItem("userDetails"));
        setUserDetails(userDetailsSession);
    }, []);

    return (
        <div>
            <style>
                {`
                .offcanvas.custom-offcanvas {
                    width: 400px !important; /* Adjust this as needed */
                }

                @media (max-width: 576px) {
                    .offcanvas.custom-offcanvas {
                    width: 70% !important; /* Adjust the percentage as needed */
                    }
                }
                `}
            </style>
            <div
                className="offcanvas offcanvas-end custom-offcanvas"
                tabIndex="-1"
                id="offcanvasRight"
                aria-labelledby="offcanvasRightLabel"
            >
                <div className="offcanvas-header">
                    <h5 id="offcanvasRightLabel">Add Driver</h5>
                    <button
                        type="button"
                        className="btn-close text-reset"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                    ></button>
                </div>
                <div className="offcanvas-body">
                    <form onSubmit={handleSubmit}>
                        <div className="mt-2">
                            <label className="form-label">
                                Name <span className="text-danger">*</span>
                            </label>
                            <input
                                className="form-control"
                                placeholder="Enter Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                        </div>

                        <div className="mt-2 d-flex flex-column mb-2">
                            <label className="form-label">
                                Transporter <span className="text-danger">*</span>
                            </label>
                            <Select
                                showSearch
                                placeholder="Choose Transporter"
                                value={transporter || null}
                                onChange={handleTransporterChange}
                                filterOption={(input, option) =>
                                    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                                }
                                options={transporterOptions}
                                style={{ width: "100%" }}
                                required
                            />
                        </div>

                        <div className="mt-2">
                            <label className="form-label">Contact Number</label>
                            <input
                                className="form-control"
                                placeholder="Enter Contact Number"
                                value={contactNumber}
                                onChange={(e) => setContactNumber(e.target.value)}
                                type="tel"
                            />
                        </div>

                        {error && (
                            <div className="text-danger mt-2">
                                <small>{error}</small>
                            </div>
                        )}

                        {successMessage && (
                            <div className="text-success mt-2">
                                <small>{successMessage}</small>
                            </div>
                        )}

                        <button
                            type="submit"
                            className="btn btn-primary d-flex m-auto mt-4"
                            disabled={loading}
                        >
                            {loading ? "Submitting..." : "Submit"}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}
