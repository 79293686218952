import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import DeliveryChalan from "./Components/Pages/Delivery Chalan/DeliveryChalan";
import Customers from "./Components/Pages/Customers/Customers";
import Leads from "./Components/Pages/Leads/Leads";
import Reminders from "./Components/Pages/Reminders/Reminders";
import FollowUps from "./Components/Pages/FollowUps/FollowUps";
import Calendar from "./Components/Pages/Calendar/Calendar";
import ViewCustomer from "./Components/Pages/Customers/ViewCustomer";
import EditCustomer from "./Components/Pages/Customers/EditCustomer";
import AddCustomer from "./Components/Pages/Customers/AddCustomer";
import ViewLead from "./Components/Pages/Leads/ViewLead";
import EditLead from "./Components/Pages/Leads/EditLead";
import AddLead from "./Components/Pages/Leads/AddLead";
import Dashboard from "./Components/Pages/Dashboard/Dashboard";
import LeadsReport from "./Components/Pages/Reports/LeadsReports";
import CustomersReport from "./Components/Pages/Reports/CustomersReport";
import RemindersReport from "./Components/Pages/Reports/RemindersReport";
import FollowupsReport from "./Components/Pages/Reports/FollowupsReport";
import Login from "./Components/Pages/Login/Login";
import LogisticRate from "./Components/Pages/LogisticRate.js/LogisticRate";
import ProjectLocations from "./Components/Pages/Locations/Locations";
import Projects from "./Components/Pages/Projects/Projects";
import EditProject from "./Components/Pages/Projects/EditProject";
import ViewProject from "./Components/Pages/Projects/ViewProject";
import AddChalan from "./Components/Pages/Delivery Chalan/AddDeliveryChalan";
import Products from "./Components/Pages/Products/Products";
import AddProdcut from "./Components/Pages/Products/AddProduct";
import ViewChallan from "./Components/Pages/Delivery Chalan/ViewChallan";
import EditChallan from "./Components/Pages/Delivery Chalan/EditChallan";
import PurchaseOrder from "./Components/Pages/Purchase Order/PurchaseOrders";
import AddPurchaseOrder from "./Components/Pages/Purchase Order/AddPurchaseOrder";
import ViewPurchaseOrder from "./Components/Pages/Purchase Order/ViewPurchaseOrder";
import Invoice from "./Components/Pages/Invoice/Invoices";
import AddInvoice from "./Components/Pages/Invoice/AddInvoice";
import EditPurchaseOrder from "./Components/Pages/Purchase Order/EditPurchaseOrder";
import Expenses from "./Components/Pages/Expenses/Expenses";
import AddExpense from "./Components/Pages/Expenses/AddExpense";
import EditExpense from "./Components/Pages/Expenses/EditExpense";
import ViewExpense from "./Components/Pages/Expenses/ViewExpenses";
import CreditNotes from "./Components/Pages/Credit Notes/CreditNotes";
import AddCreditNotes from "./Components/Pages/Credit Notes/AddCreditNotes";
import Tasks from "./Components/Pages/Tasks/Tasks";
import EditTask from "./Components/Pages/Tasks/EditTask";
import ViewTask from "./Components/Pages/Tasks/ViewTask";
import AddTask from "./Components/Pages/Tasks/AddTask";
import Payments from "./Components/Pages/Payments/Payments";
import EditCreditNotes from "./Components/Pages/Credit Notes/EditCreditNotes";
import ViewCreditNotes from "./Components/Pages/Credit Notes/ViewCreditNotes";
import EditPayment from "./Components/Pages/Payments/EditPayment";
import ViewPayment from "./Components/Pages/Payments/ViewPaymnet";

import Inventory from "./Components/Pages/Inventory/inventory";
import AddInventory from "./Components/Pages/Inventory/addinventory";
import InternalDeliveryNote from "./Components/Pages/Inventory/addinternaldeliverynote";
import PostInventory from "./Components/Pages/Inventory/postinventory";
import ViewInternalDeliveryNote from "./Components/Pages/Inventory/viewinternaldeliverynote";
import EditInternaldeliverynote from "./Components/Pages/Inventory/editinternaldeliverynote";
import ShowInternalDeliveryNote from "./Components/Pages/Inventory/internaldeliverynote";
import EditInvoice from "./Components/Pages/Invoice/EditInvoice";
import ViewInvoice from "./Components/Pages/Invoice/ViewInvoice";
import AddDesignmix from "./Components/Pages/designmix/adddesignmix";
import Designmix from "./Components/Pages/designmix/designmix";
import EditDesignmix from "./Components/Pages/designmix/editdesignmix";
import Transporters from "./Components/Pages/transporter/transporters";
import Drivers from "./Components/Pages/drivers/drivers";
import AddTransporters from "./Components/Pages/transporter/Addtransports";
import Rate from "./Components/Pages/rate/rate";
import Vehicle from "./Components/Pages/Vehicle/vehicle";
import EditTransporters from "./Components/Pages/transporter/Editransporters";
import Slabrate from "./Components/Pages/slab rate/slabrate";
import AddSlabrate from "./Components/Pages/slab rate/addslabrate";
import EditSlabrate from "./Components/Pages/slab rate/editslabrate";
import Proposal from "./Components/Pages/proposals/proposal";
import AddProposal from "./Components/Pages/proposals/addproposal";
import EditProposal from "./Components/Pages/proposals/editproposal";
import ViewProposal from "./Components/Pages/proposals/viewproposal";

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="delivery-challan" element={<DeliveryChalan />} />
          <Route path="add-challan" element={<AddChalan />} />
          <Route path="view-challan" element={<ViewChallan />} />
          {/* <Route path="view-challan/:ChallanID" element={<ViewChallan />} /> */}
          <Route path="edit-challan" element={<EditChallan />} />
          {/* <Route path="edit-challan/:ChallanID" element={<EditChallan />} /> */}
          <Route path="products" element={<Products />} />
          <Route path="add-product" element={<AddProdcut />} />

          <Route path="purchase-order" element={<PurchaseOrder />} />
          <Route path="add-purchase-order" element={<AddPurchaseOrder />} />
          {/* <Route
            path="view-purchase-order/:PurchaseID"
            element={<ViewPurchaseOrder />}
          /> */}
          <Route path="view-purchase-order" element={<ViewPurchaseOrder />} />
          <Route path="edit-purchase-order" element={<EditPurchaseOrder />} />
          {/* <Route
            path="edit-purchase-order/:PurchaseID"
            element={<EditPurchaseOrder />}
          /> */}

          <Route path="expenses" element={<Expenses />} />
          <Route path="add-expense" element={<AddExpense />} />
          {/* <Route path="edit-expense/:ExpenseID" element={<EditExpense />} /> */}
          <Route path="edit-expense" element={<EditExpense />} />
          {/* <Route path="view-expense/:ExpenseID" element={<ViewExpense />} /> */}
          <Route path="view-expense" element={<ViewExpense />} />

          <Route path="credit-notes" element={<CreditNotes />} />
          <Route path="add-credit-notes" element={<AddCreditNotes />} />
          <Route path="edit-credit-notes" element={<EditCreditNotes />} />
          {/* <Route
            path="edit-credit-notes/:creditNotesId"
            element={<EditCreditNotes />}
          /> */}
          <Route path="view-credit-notes" element={<ViewCreditNotes />} />
          {/* <Route
            path="view-credit-notes/:creditNotesId"
            element={<ViewCreditNotes />}
          /> */}

          <Route path="customers" element={<Customers />} />
          <Route path="add-customer" element={<AddCustomer />} />
          <Route path="view-customer" element={<ViewCustomer />} />
          {/* <Route path="view-customer/:cusid" element={<ViewCustomer />} /> */}
          <Route path="edit-customer" element={<EditCustomer />} />
          {/* <Route path="edit-customer/:cusid" element={<EditCustomer />} /> */}

          <Route path="invoice" element={<Invoice />} />
          <Route path="add-invoice" element={<AddInvoice />} />
          <Route path="edit-invoice" element={<EditInvoice />} />
          <Route path="view-invoice" element={<ViewInvoice></ViewInvoice>} />

          <Route path="proposal" element={<Proposal></Proposal>} />
          <Route path="add-proposal" element={<AddProposal></AddProposal>} />
          <Route path="edit-proposal" element={<EditProposal></EditProposal>} />
          <Route path="view-proposal" element={<ViewProposal></ViewProposal>} />

          <Route path="leads" element={<Leads />} />
          <Route path="add-lead" element={<AddLead />} />
          <Route path="view-lead" element={<ViewLead />} />
          {/* <Route path="view-lead/:id" element={<ViewLead />} /> */}
          <Route path="edit-lead" element={<EditLead />} />
          {/* <Route path="edit-lead/:id" element={<EditLead />} /> */}

          <Route path="logistic-rate" element={<LogisticRate />} />

          <Route path="reminders" element={<Reminders />} />
          <Route path="followups" element={<FollowUps />} />
          <Route path="calendar" element={<Calendar />} />

          <Route path="projects" element={<Projects />} />
          <Route path="edit-project" element={<EditProject />} />
          {/* <Route path="edit-project/:id" element={<EditProject />} /> */}
          <Route path="view-project" element={<ViewProject />} />
          {/* <Route path="view-project/:id" element={<ViewProject />} /> */}

          <Route path="project-locations" element={<ProjectLocations />} />
          <Route path="drivers" element={<Drivers></Drivers>}></Route>
          <Route path="vehicle" element={<Vehicle></Vehicle>}></Route>
          <Route path="rate" element={<Rate></Rate>}></Route>
          <Route path="leads-report" element={<LeadsReport />} />
          <Route path="customers-report" element={<CustomersReport />} />
          <Route path="reminders-report" element={<RemindersReport />} />
          <Route path="followups-report" element={<FollowupsReport />} />
          <Route path="payments" element={<Payments />} />
          <Route path="edit-payment/:PaymentId" element={<EditPayment />} />
          <Route path="view-payment/:PaymentId" element={<ViewPayment />} />
          <Route path="add-designmix" element={<AddDesignmix></AddDesignmix>}></Route>
          <Route path="designmix" element={<Designmix></Designmix>}></Route>
          <Route path="Edit-designmix" element={<EditDesignmix></EditDesignmix>}></Route>
          <Route path="tasks" element={<Tasks />} />
          <Route path="add-task" element={<AddTask />} />
          {/* <Route path="edit-task/:TaskId" element={<EditTask />} /> */}
          <Route path="edit-task" element={<EditTask />} />
          {/* <Route path="view-task/:TaskId" element={<ViewTask />} /> */}
          <Route path="view-task" element={<ViewTask />} />
          <Route path="transporter" element={<Transporters></Transporters>} />
          <Route path="edit-transporter" element={<EditTransporters></EditTransporters>}></Route>
          <Route path="addtransporter" element={<AddTransporters></AddTransporters>} />
          <Route path="/inventory" element={<Inventory></Inventory>}></Route>
          <Route path="/slabrate" element={<Slabrate></Slabrate>}></Route>
          <Route path="/add-slabrate" element={<AddSlabrate></AddSlabrate>}></Route>
          <Route path="/edit-slabrate" element={<EditSlabrate></EditSlabrate>}></Route>
          <Route
            path="/add-inventory"
            element={<AddInventory></AddInventory>}
          ></Route>

          <Route
            path="/post-inventory"
            element={<PostInventory></PostInventory>}
          ></Route>
          <Route
            path="/internal-delivery-note"
            element={<ShowInternalDeliveryNote></ShowInternalDeliveryNote>}
          ></Route>
          <Route
            path="/view-internaldelivery"
            element={<ViewInternalDeliveryNote></ViewInternalDeliveryNote>}
          ></Route>
          <Route
            path="/add-internaldelivery"
            element={<InternalDeliveryNote></InternalDeliveryNote>}
          ></Route>
          <Route
            path="/edit-internaldelivery-note"
            element={<EditInternaldeliverynote></EditInternaldeliverynote>}
          ></Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
