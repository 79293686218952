
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";


export default function ViewProduct({ productObj }) {

    const [productData, setProductData] = useState([]);

    useEffect(() => {
        setProductData(productObj);
    }, [productObj]);
    
    // const formatDate = (dateString) => {
    //     const date = new Date(dateString);
    //     const day = String(date.getDate()).padStart(2, '0');
    //     const month = String(date.getMonth() + 1).padStart(2, '0');
    //     const year = date.getFullYear();
    //     return `${day}-${month}-${year}`;
    // };

    return (
        
        <div 
            className="offcanvas offcanvas-end custom-offcanvas"
            tabIndex="-1"
            id="offcanvasView"
            aria-labelledby="offcanvasRightLabel"
        >

            <style>
                {`
                .offcanvas.custom-offcanvas {
                    width: 450px !important; /* Adjust this as needed */
                }

                @media (max-width: 576px) {
                    .offcanvas.custom-offcanvas {
                    width: 70% !important; /* Adjust the percentage as needed */
                    }
                }
                `}
            </style>
            <div className="offcanvas-header">
                <div className="page-title d-flex justify-content-between align-items-center">
                    <h4>Product Details</h4>
                    {productData?.active === 1 ? (
                        <span className="badge bg-outline-success ms-2">Active</span>
                    ) : (
                        <span className="badge bg-outline-danger ms-2">Inactive</span>
                    )}
                </div>
                <button
                    type="button"
                    className="btn-close text-reset"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                ></button>
            </div>
            {/* <span className="ms-3">{formatDate(productData?.createdon)}</span> */}
            <div className="offcanvas-body ms-2">
                <form action="brand-list.html">
                    <div className="row">
                        <div className="mb-3 col-12">
                            <label className="form-label">Name</label>
                            <input
                                type="text"
                                name="Title"
                                className="form-control"
                                placeholder="Name of Product"
                                value={productData?.description}
                                readOnly
                            />
                        </div>
                        <div className="mb-3 col-md-6 col-lg-6">
                            <label className="form-label">Units</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Min Distance"
                                value={productData?.unit}
                                readOnly
                            />
                        </div>
                        <div className="mb-3 col-md-6 col-lg-6">
                            <label className="form-label">Price</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Min Distance"
                                value={productData?.rate}
                                readOnly
                            />
                        </div>
                        <div className="mb-3 col-md-6 col-lg-6">
                            <label className="form-label">Weight</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="weight"
                                value={productData?.weight}
                                readOnly
                            />
                        </div>
                        <div className="mb-3 col-md-6 col-lg-6">
                            <label className="form-label"> Inches</label>
                            <input
                                type="text"
                                className="form-control"
                              
                                value={productData?.size_id}
                                readOnly
                            />
                        </div>
                        <div className="mb-3 col-md-6 col-lg-6">
                            <label className="form-label"> Alios</label>
                            <input
                                type="text"
                                className="form-control"
                              
                                value={productData?.sku_name}
                                readOnly
                            />
                        </div>
                        
                        <div className="mb-3 col-12">
                            <label className="form-label">design_mix</label>
                            <textarea 
                                className="form-control"
                                placeholder="Notes..." 
                                rows={4}
                                value={productData?.design_mix || ''}
                                readOnly
                            ></textarea>
                        </div>
                    </div>
                    
                </form>
            </div>
        </div>
                   
    );
}

ViewProduct.propTypes = {
    productObj: PropTypes.array.isRequired,
};
  