import React, { useState, useEffect } from "react";
import { API_BASE_CRM_URL } from "../../Config/Config";
import Swal from "sweetalert2";
import { Select } from 'antd';
import '../../Config/swal.css';
import { useLabelContext } from "../../../Context";
import { Link, useNavigate } from "react-router-dom";
import Base from "../../Config/Base";


export default function EditDesignmix() {

    const [userDetails, SetUserDetails] = useState([]);
    const [loading, setLoading] = useState(false);

    const [desinData, setDesignData] = useState([])

    const { ID } = useLabelContext();
    const id = ID;


    const navigate = useNavigate();

    useEffect(() => {
        const userDetailsSession = JSON.parse(sessionStorage.getItem('userDetails'));
        SetUserDetails(userDetailsSession);
    }, []);

    const [formData, setFormData] = useState({
        id: '',
        name: '',
        cement: '',
        msand: '',
        six_mm: '',
        flyash: '',
        gypsum: '',
        ggbs: '',
        ad_mix: ''
    });
    console.log(formData)

    useEffect(() => {
        if (id) {
            setFormData((prev) => ({ ...prev, id }));
        }
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    useEffect(() => {
        if (id) {
            sessionStorage.setItem("designid", JSON.stringify(id)); // Store ID in sessionStorage
        }

        const designmixid = sessionStorage.getItem("designid"); // Retrieve the ID from sessionStorage

        const fetchData = async () => {
            setLoading(true);
            try {
                // Fetch data with ID passed as part of the URL path
                const response = await fetch(
                    `${API_BASE_CRM_URL}GetDesignMix/${designmixid}` // Correct URL path with id as URL param
                );
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();

                if (data.Status) {
                    setDesignData(data.ResultData); // Set design data
                    setFormData({
                        id: data.ResultData.id || "",
                        name: data.ResultData.name || "",
                        cement: data.ResultData.cement || "",
                        msand: data.ResultData.msand || "",
                        six_mm: data.ResultData.six_mm || "",
                        flyash: data.ResultData.flyash || "",
                        gypsum: data.ResultData.gypsum || "",
                        ggbs: data.ResultData.ggbs || "",
                        ad_mix: data.ResultData.ad_mix || "",
                    });
                } else {
                    console.error("Fetched data is not valid:", data);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [id]);


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            console.log(formData, 'data sending to API');

            const response = await fetch(`${API_BASE_CRM_URL}UpdateDesignMix`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
            });

            console.log("API Response:", response);

            // Check if the response is okay
            if (!response.ok) {
                console.error(`HTTP Error: ${response.status} - ${response.statusText}`);
                throw new Error("API request failed");
            }

            const data = await response.json();
            console.log("API Response Data:", data);

            if (data.Status) {
                setLoading(false);
                Swal.fire({
                    title: `Project added successfully.`,
                    icon: "success",
                    customClass: {
                        title: 'swal-title',
                        content: 'swal-text'
                    }
                }).then((result) => {
                    if (result.isConfirmed || result.isDismissed) {
                        navigate("/designmix")
                    }
                });
            } else {
                setLoading(false);
                Swal.fire({
                    title: "Error",
                    text: data.Message || "Failed to submit request.",
                    icon: "error",
                    customClass: {
                        title: 'swal-title',
                        content: 'swal-text'
                    }
                });
            }
        } catch (error) {
            console.error("Error submitting form:", error.message || error);
            Swal.fire({
                title: "Error",
                text: "An unexpected error occurred. Please try again later.",
                icon: "error",
                customClass: {
                    title: 'swal-title',
                    content: 'swal-text'
                }
            });
        }
    };


    return (
        <Base>
            <div className="page-heade mb-3">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <div className="page-title">
                            <h4>Design Mix Details</h4>
                            <p className="fw-semibold">edit Design Mix</p>
                        </div>
                    </div>
                    <div className="col-md-6 d-flex justify-content-md-end justify-content-start">
                        <Link to="/designmix">
                            <button
                                className="btn btn-primary"
                                style={{ borderRadius: "10px" }}
                            >
                                <i className="fa-solid fa-arrow-left me-2"></i> Go to List
                            </button>
                        </Link>
                    </div>
                </div>
            </div>

            <div className="col-xxl-12 col-xl-12">
                <div className="card">
                    <div
                        className="card-body"
                        style={{ maxHeight: "100vh", overflowY: "auto" }}
                    >
                        <form onSubmit={handleSubmit}>
                            <div className="row m-3">
                                <div className="col-md-4 col-lg-4 mb-2">
                                    <label className="form-label">Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter project name"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="col-md-4 col-lg-4 mb-2">
                                    <label className="form-label">Cement</label>
                                    <div className="d-flex">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter project name"
                                            name="cement"
                                            value={formData.cement}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="col-md-4 col-lg-4 mb-2">
                                    <label className="form-label">Msand</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="msand"
                                        value={formData.msand}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="col-md-4 col-lg-4 mb-2">
                                    <label className="form-label">Six mm</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="six_mm"
                                        value={formData.six_mm}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-md-4 col-lg-4 mb-2">
                                    <label className="form-label">Flyash</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        placeholder="Enter cost of project"
                                        name="flyash"
                                        value={formData.flyash}
                                        onChange={handleChange}
                                        onWheel={(e) => e.target.blur()}
                                    />
                                </div>
                                <div className="col-md-4 col-lg-4 mb-2">
                                    <label className="form-label">Gypsum</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        placeholder="Enter longitude"
                                        name="gypsum"
                                        value={formData.gypsum}
                                        onChange={handleChange}
                                        onWheel={(e) => e.target.blur()}
                                    />
                                </div>

                                <div className="col-md-4 col-lg-4 mb-2">
                                    <label className="form-label">Ad mix</label>
                                    <textarea
                                        className="form-control"
                                        placeholder="Enter project ad_mix"
                                        name="ad_mix"
                                        value={formData.ad_mix}
                                        onChange={handleChange}
                                    />
                                </div>



                            </div>
                            <div className="row m-3 col-2 m-auto">
                                <button type="submit" className="btn btn-primary">
                                    {loading ? "Submitting.." : "Update Project"}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Base>
        // <div>
        //     <style>
        //         {`
        //             .offcanvas.custom-offcanvas {
        //                 width: 400px !important; /* Adjust this as needed */
        //             }

        //             @media (max-width: 576px) {
        //                 .offcanvas.custom-offcanvas {
        //                 width: 70% !important; /* Adjust the percentage as needed */
        //                 }import Customers from './../Customers/Customers';

        //             }
        //         `}
        //     </style>

        //     <div
        //         className="offcanvas offcanvas-end custom-offcanvas"
        //         tabIndex="-1"
        //         id="offcanvasRightEdit"
        //         aria-labelledby="offcanvasRightLabel"
        //     >
        //         <div className="offcanvas-header">
        //             <h5 id="offcanvasRightLabel">Edit DesignMIx</h5>
        //             <button
        //                 type="button"
        //                 className="btn-close text-reset"
        //                 data-bs-dismiss="offcanvas"
        //                 aria-label="Close"
        //             ></button>
        //         </div>
        //         <div className="offcanvas-body">
        //             <form onSubmit={handleSubmit}>
        //                 <div className="row mt-2">
        //                     <div className=" mt-2">
        //                         <label className="form-label">Name <span className='text-danger'>*</span></label>
        //                         <input
        //                             type="text"
        //                             className="form-control"
        //                             placeholder="Enter project name"
        //                             name="name"
        //                             value={formData.name}
        //                             onChange={handleChange}
        //                             required
        //                         />
        //                     </div>
        //                     <div className=" mt-2">
        //                         <label className="form-label">Cement <span className='text-danger'>*</span></label>
        //                         <input
        //                             type="text"
        //                             className="form-control"
        //                             placeholder="Enter project name"
        //                             name="cement"
        //                             value={formData.cement}
        //                             onChange={handleChange}
        //                             required
        //                         />
        //                     </div>
        //                 </div>

        //                 <div className="row mt-2">
        //                     <div className="col-md-6">
        //                         <label className="form-label">Msand<span className="text-danger">*</span></label>
        //                         <input
        //                             type="text"
        //                             className="form-control"
        //                             name="msand"
        //                             value={formData.msand}
        //                             onChange={handleChange}
        //                             required
        //                         />
        //                     </div>
        //                     <div className="col-md-6">
        //                         <label className="form-label">six mm</label>
        //                         <input
        //                             type="text"
        //                             className="form-control"
        //                             name="six_mm"
        //                             value={formData.six_mm}
        //                             onChange={handleChange}
        //                         />
        //                     </div>
        //                 </div>


        //                 <div className="row mt-2">
        //                     <div className="col-md-6">
        //                         <label className="form-label">flyash</label>
        //                         <input
        //                             type="number"
        //                             className="form-control"
        //                             placeholder="Enter cost of project"
        //                             name="flyash"
        //                             value={formData.flyash}
        //                             onChange={handleChange}
        //                             onWheel={(e) => e.target.blur()}
        //                         />
        //                     </div>
        //                     <div className="col-md-6">
        //                         <label className="form-label">gypsum</label>
        //                         <input
        //                             type="number"
        //                             className="form-control"
        //                             placeholder="Enter longitude"
        //                             name="gypsum"
        //                             value={formData.gypsum}
        //                             onChange={handleChange}
        //                             onWheel={(e) => e.target.blur()}
        //                         />
        //                     </div>
        //                 </div>
        //                 <div className="row mt-2">


        //                     <div className="col-md-6">
        //                         <label className="form-label">ggbs</label>
        //                         <input
        //                             type="text"
        //                             className="form-control"
        //                             placeholder="Enter project ggbs"
        //                             name="ggbs"
        //                             value={formData.ggbs}
        //                             onChange={handleChange}
        //                         />
        //                     </div>
        //                     <div className=" col-md-6">
        //                         <label className="form-label">ad mix</label>
        //                         <textarea
        //                             className="form-control"
        //                             placeholder="Enter project ad_mix"
        //                             name="ad_mix"
        //                             value={formData.ad_mix}
        //                             onChange={handleChange}
        //                         />
        //                     </div>
        //                 </div>

        //                 <button type="submit" className="btn btn-primary d-flex m-auto mt-4">Submit</button>
        //             </form>
        //         </div>
        //     </div>
        // </div>
    );
}
