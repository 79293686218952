
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { API_BASE_CRM_URL } from "../../Config/Config";
import { Select } from "antd";
import Swal from "sweetalert2";
import '../../Config/swal.css';


export default function EditProduct({ productObj }) {

    const [taxOptionsData, setTaxOptionsData] = useState([]);
    const [selectedTax1Id, setSelectedTax1Id] = useState('');
    const [selectedTax2Id, setSelectedTax2Id] = useState('');
    const [loading, setLoading] = useState(false);
    const [selecteddesignixid, setselecteddesignmixid] = useState("")
    const [designmixData, setDesignmixData] = useState([]);
    const [formValues, setFormValues] = useState({
        id: '',
        description: '',
        rate: '',
        weight: '',
        // quantity: '',
        tax: '',
        unit: '',
        design_mix: '',
    });

    useEffect(() => {
        if (productObj) {
            setFormValues({
                id: productObj?.id,
                description: productObj?.description || '',
                rate: productObj?.rate || '',
                weight: productObj?.weight || '',
                // quantity: productObj?.quantity || '',
                tax: productObj?.tax || '',
                unit: productObj?.unit || '',
                design_mix: productObj?.design_mix_name || ''
            });

            setSelectedTax1Id(productObj?.tax || '');
            setSelectedTax2Id(productObj?.tax2 || '');
        } else {
            console.error("Data is not get:");
        }
    }, [productObj]);

    console.log(productObj?.weight)

    const handleTax1SelectChange = (selectedOption) => {
        setSelectedTax1Id(selectedOption);
        setFormValues(prevData => ({
            ...prevData,
            tax1: selectedOption
        }));
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${API_BASE_CRM_URL}GetDesignMix`);
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();
                console.log(data.ResultData)

                if (data.Status) {
                    setDesignmixData(data.ResultData);
                } else {
                    console.error("Fetched data is not an array:", data);
                    setDesignmixData([]);
                }
            } catch (error) {
                console.error("Error fetching datasssss:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const DesignmixOptions =
        designmixData &&
        designmixData.map((item) => ({
            value: item.id,
            label: item.name,
        }));

    const handleTax2SelectChange = (selectedOption) => {
        setSelectedTax2Id(selectedOption);
        setFormValues(prevData => ({
            ...prevData,
            tax2: selectedOption
        }));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues(prevValues => ({
            ...prevValues,
            [name]: value
        }));
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${API_BASE_CRM_URL}GetTaxes`);
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();

                if (data.Status) {
                    setTaxOptionsData(data.ResultData);
                } else {
                    console.error("Fetched data is not an array:", data);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {

            }
        };
        fetchData();
    }, []);

    const taxOptions = taxOptionsData && taxOptionsData.map((item) => ({
        value: item.id,
        label: item.name,
    }));

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        console.log(formValues, 'data sending to API');
        try {
            const response = await fetch(`${API_BASE_CRM_URL}UpdateProduct`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formValues),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            console.log(result); // Log the result for debugging

            if (result.Status) {
                setLoading(false);
                Swal.fire({
                    title: `Product Updated Successfully.`,
                    icon: "success",
                    customClass: {
                        title: 'swal-title',
                        content: 'swal-text'
                    }
                }).then((result) => {
                    if (result.isConfirmed || result.isDismissed) {
                        window.location.reload();
                    }
                });
            } else {
                setLoading(false);
                Swal.fire({
                    title: "Error",
                    text: result.Message || "Failed to Request Submit.",
                    icon: "error",
                    customClass: {
                        title: 'swal-title',
                        content: 'swal-text'
                    }

                });
                window.location.reload();
            }
        } catch (error) {
            console.error('Error:', error);
            setLoading(false);
            Swal.fire({
                title: "Error",
                text: error.message || 'Error updating product.',
                icon: "error",
                customClass: {
                    title: 'swal-title',
                    content: 'swal-text'
                }
            });
        } finally {
            setLoading(false);
        }
    };


    const handleDesignmixSelectChange = (selectedOption) => {
        setselecteddesignmixid(selectedOption);
        setFormValues(prevValues => ({
            ...prevValues,
            design_mix: selectedOption
        }));
    };

    return (

        <div
            className="offcanvas offcanvas-end custom-offcanvas"
            tabIndex="-1"
            id="offcanvasEdit"
            aria-labelledby="offcanvasRightLabel"
        >
            <style>
                {`
                .offcanvas.custom-offcanvas {
                    width: 450px !important; /* Adjust this as needed */
                }

                @media (max-width: 576px) {
                    .offcanvas.custom-offcanvas {
                    width: 70% !important; /* Adjust the percentage as needed */
                    }
                }
                `}
            </style>
            <div className="offcanvas-header">
                <div className="page-title d-flex justify-content-between align-items-center">
                    <h4>Edit Product Details</h4>
                    {productObj?.active === 1 ? (
                        <span className="badge bg-outline-success ms-2">Active</span>
                    ) : (
                        <span className="badge bg-outline-danger ms-2">Inactive</span>
                    )}
                </div>
                <button
                    type="button"
                    className="btn-close text-reset"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                ></button>
            </div>
            <div className="offcanvas-body ms-2">
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="mb-3 col-12">
                            <label className="form-label">Name</label>
                            <input
                                type="text"
                                name="description"
                                className="form-control"
                                placeholder="Name of Product"
                                value={formValues?.description}
                                onChange={handleChange}
                            />
                        </div>
                        {/* <div className="mb-3 col-12">
                            <label className="form-label">Quantity</label>
                            <input
                                type="text"
                                name="quantity"
                                className="form-control"
                                placeholder="Name of quantity"
                                value={formValues?.quantity}
                                onChange={handleChange}
                            />
                        </div> */}
                        <div className="mb-3 col-md-6 col-lg-6">
                            <label className="form-label">Units</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Enter units"
                                name="unit"
                                value={formValues?.unit}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mb-3 col-md-6 col-lg-6">
                            <label className="form-label">Price</label>
                            <input
                                type="number"
                                className="form-control"
                                name="rate"
                                placeholder="Enter price"
                                value={formValues?.rate}
                                onChange={handleChange}
                                onWheel={(e) => e.target.blur()}
                            />
                        </div>
                        <div className="mb-3 col-md-6 col-lg-6 d-flex flex-column">
                            <label className="form-label"> weight</label>
                            <input
                                type="text"
                                className="form-control"
                                name="weight"
                                placeholder="Enter weight"
                                value={formValues?.weight}
                                onChange={handleChange}
                                onWheel={(e) => e.target.blur()}
                            />

                        </div>
                        <div className="mb-3 col-md-6 col-lg-6 d-flex flex-column">
                            <label className="form-label"> Tax</label>
                            <input
                                type="number"
                                className="form-control"
                                name="tax"
                                placeholder="Enter tax"
                                value={formValues?.tax}
                                onChange={handleChange}
                                onWheel={(e) => e.target.blur()}
                            />
                            {/* <Select
                                showSearch
                                placeholder="Choose tax"
                                value={selectedTax2Id || null}
                                onChange={handleTax2SelectChange}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={taxOptions}
                                style={{ flexGrow: 1, height: '2.4rem' }}
                            /> */}
                        </div>

                        <div className='mb-2'>
                            <label className='form-label'>Designmix</label>
                            <div className='d-flex' style={{ width: '100%' }}>
                                <Select
                                    name="design_mix"
                                    placeholder="Select Designmix"
                                    value={selecteddesignixid || null}
                                    onChange={handleDesignmixSelectChange}
                                    style={{ width: '100%' }}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={DesignmixOptions}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row m-3 m-auto text-center'>
                        <button type="submit" className="btn btn-primary col-7 m-auto text-center">
                            {loading ? 'Submitting...' : 'Save Changes'}
                        </button>

                    </div>
                </form>
            </div>
        </div>

    );
}

EditProduct.propTypes = {
    productObj: PropTypes.array.isRequired,
};
