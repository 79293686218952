import React, { useState, useEffect } from "react";
import Base from '../../Config/Base'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { Select, Tooltip } from 'antd';
import { API_BASE_CRM_URL } from "../../Config/Config";
import Swal from "sweetalert2";

const AddTransporters = () => {

    const navigate = useNavigate();

    useEffect(() => {
        const userDetails = sessionStorage.getItem('userDetails');
        if (userDetails) {
            const parsedUserDetails = JSON.parse(userDetails);
            console.log(parsedUserDetails);
            console.log(parsedUserDetails.staffid);

        }
    }, []);

    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        name: "",
        contact_no: "",
        email: "",
        pan_card: "",
        pan_photo: "",
        aadhaar_no: "",
        aadhaar_photo: "",
        gst_pdf: "",
        gst_no: "",
        vehicle_id: "",
        driver_id: ""
    });

    const [message, setMessage] = useState("");

    // Handle form changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        const file = files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setFormData({ ...formData, [name]: reader.result });
            };
            reader.readAsDataURL(file);
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const response = await fetch(`${API_BASE_CRM_URL}AddTransporter`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
            });

            const data = await response.json();

            if (data.Status) {
                alert("Transporter added successfully!");

                navigate("/transporter")

            } else {
                alert("Failed to add transporter. Please try again.");
            }
        } catch (error) {
            console.error("Error:", error);
            alert("An error occurred while adding the transporter.");
        } finally {
            setLoading(false);
        }
    };



    return (
        <Base>
            <div className="page-header">
                <div className="add-item d-flex">
                    <div className="page-title">
                        <h4>Add New Transporter</h4>
                        <h6>Fill in the details to add a new transporter</h6>
                    </div>
                </div>
                <ul className="table-top-head">
                    <li>
                        <Link to='/transporter' className="btn btn-added">
                            <i className="fa-solid fa-arrow-left me-1"></i>Go to List
                        </Link>
                    </li>
                </ul>
            </div>

            <div className="card table-list-card">
                <div className="card-body">
                    <form onSubmit={handleSubmit}>
                        <div className="row m-2">
                            <div className="col-lg-4 mb-2">
                                <label className="form-label">Name</label>
                                <input
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    className="form-control"
                                    placeholder="Enter Transporter Name"
                                    required
                                />
                            </div>
                            <div className="col-lg-4 mb-2">
                                <label className="form-label">Contact Number</label>
                                <input
                                    type="text"
                                    name="contact_no"
                                    value={formData.contact_no}
                                    onChange={handleInputChange}
                                    className="form-control"
                                    placeholder="Enter Contact Number"
                                    required
                                />
                            </div>
                            <div className="col-lg-4 mb-2">
                                <label className="form-label">Email</label>
                                <input
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    className="form-control"
                                    placeholder="Enter Email"
                                />
                            </div>
                            <div className="col-lg-4 mb-2">
                                <label className="form-label">PAN Card</label>
                                <input
                                    type="text"
                                    name="pan_card"
                                    value={formData.pan_card}
                                    onChange={handleInputChange}
                                    className="form-control"
                                    placeholder="Enter PAN Card Number"
                                />
                            </div>
                            <div className="col-lg-4 mb-2">
                                <label className="form-label">Aadhaar Photo</label>
                                <input
                                    className="form-control"
                                    type="file"
                                    name="aadhaar_photo"
                                    onChange={handleFileChange}
                                />
                            </div>

                            <div className="col-lg-4 mb-2">
                                <label className="form-label">PAN Photo</label>
                                <input
                                    className="form-control"
                                    type="file"
                                    name="pan_photo"
                                    onChange={handleFileChange}
                                />
                            </div>

                            <div className="col-lg-4 mb-2">
                                <label className="form-label">Aadhaar Number</label>
                                <input
                                    type="text"
                                    name="aadhaar_no"
                                    value={formData.aadhaar_no}
                                    onChange={handleInputChange}
                                    className="form-control"
                                    placeholder="Enter Aadhaar Number"
                                />
                            </div>
                            <div className="col-lg-4 mb-2">
                                <label className="form-label">GST Number</label>
                                <input
                                    type="text"
                                    name="gst_no"
                                    value={formData.gst_no}
                                    onChange={handleInputChange}
                                    className="form-control"
                                    placeholder="Enter GST Number"
                                />
                            </div>

                            <div className="col-12 text-center mt-3">
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    disabled={loading}
                                >
                                    {loading ? "Submitting..." : "Submit"}
                                </button>
                            </div>
                        </div>
                    </form>
                    {message && <p className="text-center mt-3">{message}</p>}
                </div>
            </div>
        </Base>
    )
}

export default AddTransporters;