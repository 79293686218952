import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Base from "../../Config/Base";
import { API_BASE_CRM_URL } from "../../Config/Config";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import "../../Config/swal.css";
import { useLabelContext } from "../../../Context";

export default function EditCustomer() {
  //   const { cusid } = useParams();
  const { ID } = useLabelContext();
  const cusid = ID;

  const navigate = useNavigate();

  const [customerData, setCustomerData] = useState({
    UserId: "",
    LeadId: "",
    Company: "",
    PhoneNo: "",
    Website: "",
    googlelocation: "",
    Vat: "",
    datecreated: "",
    default_language: "",
    Country: "",
    State: "",
    City: "",
    Zip: "",
    Address: "",
    BillingStreet: "",
    BillingCity: "",
    BillingState: "",
    BillingZip: "",
    BillingCountry: "",
    ShippingStreet: "",
    ShippingCity: "",
    ShippingState: "",
    ShippingZip: "",
    ShippingCountry: "",
    Longitude: "",
    Latitude: "",
    DefaultLanguage: "",
    DefaultCurrency: "",
    email: "",
    product_id: "",
    site_name: "",
    km_slab: "",
    rate: "",
    bill: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCustomerData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (cusid) {
      sessionStorage.setItem("purchaseId", JSON.stringify(cusid));
    }

    const storedPurchaseId = sessionStorage.getItem("purchaseId");
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${API_BASE_CRM_URL}GetCustomerDetails?CusId=${storedPurchaseId}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        if (data.Status) {
          const customerDetails = data.ResultData[0];
          setCustomerData({
            UserId: customerDetails.userid,
            Company: customerDetails.company,
            Vat: customerDetails.vat,
            PhoneNo: customerDetails.phonenumber,
            Country: customerDetails.country,
            City: customerDetails.city,
            Zip: customerDetails.zip,
            State: customerDetails.state,
            Address: customerDetails.address,
            Website: customerDetails.website,
            googlelocation: customerDetails.googlelocation,
            head_office_flag: customerDetails.head_office_flag,
            LeadId: customerDetails.leadid,
            BillingStreet: customerDetails.billing_street,
            BillingCity: customerDetails.billing_city,
            BillingState: customerDetails.billing_state,
            BillingZip: customerDetails.billing_zip,
            BillingCountry: customerDetails.billing_country,
            ShippingStreet: customerDetails.shipping_street,
            ShippingCity: customerDetails.shipping_city,
            ShippingState: customerDetails.shipping_state,
            ShippingZip: customerDetails.shipping_zip,
            ShippingCountry: customerDetails.shipping_country,
            Longitude: customerDetails.longitude,
            Latitude: customerDetails.latitude,
            DefaultLanguage: customerDetails.default_language,
            DefaultCurrency: customerDetails.default_currency,
            AddedFrom: customerDetails.addedfrom,
            email: customerDetails.email,
            product_id: customerDetails.product_id,
            site_name: customerDetails.site_name,
            km_slab: customerDetails.km_slab,
            rate: customerDetails.rate,
            bill: customerDetails.bill,
          });
        } else {
          setCustomerData({});
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [cusid]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await fetch(`${API_BASE_CRM_URL}UpdateCustomer`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(customerData),
      });

      if (!response.ok) {
        throw new Error("Failed to update customer data");
      }

      const result = await response.json();
      setLoading(false);

      if (result.Status) {
        Swal.fire({
          title: `Customer Updated Successfully.`,
          icon: "success",
          customClass: {
            title: "swal-title",
            content: "swal-text",
          },
        }).then((result) => {
          if (result.isConfirmed || result.isDismissed) {
            navigate("/customers");
          }
        });
      } else {
        Swal.fire({
          title: "Error",
          text: "Failed to submit request.",
          icon: "error",
          customClass: {
            title: "swal-title",
            content: "swal-text",
          },
        });
      }
    } catch (error) {
      console.error("Error updating data:", error);
      setLoading(false);
    }
  };

  return (
    <Base>
      <div className="page-heade mb-3">
        <div className="row align-items-center">
          <div className="col-md-6">
            <div className="page-title">
              <h4>company Details</h4>
              <p className="fw-semibold">Edit details</p>
            </div>
          </div>
          <div className="col-md-6 d-flex justify-content-md-end justify-content-start">
            <Link to="/customers">
              <button
                className="btn btn-primary"
                style={{ borderRadius: "10px" }}
              >
                <i className="fa-solid fa-arrow-left me-2"></i> Go to List
              </button>
            </Link>
          </div>
        </div>
      </div>

      <div className="col-xxl-12 col-xl-12">
        <div className="card">
          <div
            className="card-body"
            style={{ maxHeight: "100vh", overflowY: "auto" }}
          >
            <form onSubmit={handleSubmit}>
              <ul
                className="nav nav-tabs tab-style-2 nav-justified mb-3 d-sm-flex d-block"
                id="myTab1"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="order-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#order-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="order-tab-pane"
                    aria-selected="true"
                  >
                    <i className="fa-regular fa-address-book"></i> Info
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="confirmed-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#confirm-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="confirm-tab-pane"
                    aria-selected="false"
                  >
                    <i className="fa-solid fa-map-location-dot"></i>Billing
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="shipped-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#shipped-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="shipped-tab-pane"
                    aria-selected="false"
                  >
                    <i className="fa-solid fa-truck-fast"></i>Shipping
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active text-muted"
                  id="order-tab-pane"
                  role="tabpanel"
                  aria-labelledby="order-tab"
                  tabIndex="0"
                >
                  <div className="row m-3">
                    <div className="col-md-4 col-lg-4 mb-2">
                      <label className="form-label">Organization Name</label>
                      <input
                        className="form-control"
                        name="Company"
                        value={customerData.Company}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-4 col-lg-4">
                      <label className="form-label">Phone Number</label>
                      <input
                        className="form-control"
                        name="PhoneNo"
                        value={customerData.PhoneNo}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-4 col-lg-4">
                      <label className="form-label">Google Location</label>
                      <input
                        className="form-control"
                        name="googlelocation"
                        value={customerData.googlelocation}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-4 col-lg-4">
                      <label className="form-label">Website</label>
                      <input
                        className="form-control"
                        name="Website"
                        value={customerData.Website}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-4 col-lg-4">
                      <label className="form-label">Language</label>
                      <input
                        className="form-control"
                        name="DefaultLanguage"
                        value={customerData.DefaultLanguage}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-4 col-lg-4">
                      <label className="form-label">City</label>
                      <input
                        className="form-control"
                        name="City"
                        value={customerData.City}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-4 col-lg-4 mb-2">
                      <label className="form-label">State</label>
                      <input
                        className="form-control"
                        name="State"
                        value={customerData.State}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-4 col-lg-4">
                      <label className="form-label">Zipcode</label>
                      <input
                        className="form-control"
                        name="Zip"
                        value={customerData.Zip}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade text-muted"
                  id="confirm-tab-pane"
                  role="tabpanel"
                  aria-labelledby="confirmed-tab"
                  tabIndex="0"
                >
                  <div className="row m-3">
                    <div className="col-md-4 col-lg-4">
                      <label className="form-label">Billing Street</label>
                      <input
                        className="form-control"
                        name="BillingStreet"
                        value={customerData.BillingStreet}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-4 col-lg-4">
                      <label className="form-label">Billing City</label>
                      <input
                        className="form-control"
                        name="BillingCity"
                        value={customerData.BillingCity}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-4 col-lg-4">
                      <label className="form-label">Billing State</label>
                      <input
                        className="form-control"
                        name="BillingState"
                        value={customerData.BillingState}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-4 col-lg-4">
                      <label className="form-label">Billing Zipcode</label>
                      <input
                        className="form-control"
                        name="BillingZip"
                        value={customerData.BillingZip}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade text-muted"
                  id="shipped-tab-pane"
                  role="tabpanel"
                  aria-labelledby="shipped-tab"
                  tabIndex="0"
                >
                  <div className="row m-3">
                    <div className="col-md-4 col-lg-4">
                      <label className="form-label">Shipping Street</label>
                      <input
                        className="form-control"
                        name="ShippingStreet"
                        value={customerData.ShippingStreet}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-4 col-lg-4">
                      <label className="form-label">Shipping City</label>
                      <input
                        className="form-control"
                        name="ShippingCity"
                        value={customerData.ShippingCity}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-4 col-lg-4">
                      <label className="form-label">Shipping State</label>
                      <input
                        className="form-control"
                        name="ShippingState"
                        value={customerData.ShippingState}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-4 col-lg-4">
                      <label className="form-label">Shipping Zipcode</label>
                      <input
                        className="form-control"
                        name="ShippingZip"
                        value={customerData.ShippingZip}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center mt-4">
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? "Updating..." : "Update Customer"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Base>
  );
}
