import React, { useState, useEffect } from "react";
import { API_BASE_CRM_URL } from "../../Config/Config";
import Swal from "sweetalert2";
import "../../Config/swal.css";
import { Link, useNavigate } from "react-router-dom";
import Base from "../../Config/Base";

export default function AddSlabrate() {
    const [loading, setLoading] = useState(false);

    const [kmSlab, setKmSlab] = useState("");
    const [fourInch, setFourInch] = useState("");
    const [sixInch, setSixInch] = useState("");
    const [eightInch, setEightInch] = useState("");
    const [nineSixInch, setNineSixInch] = useState("");

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Check if all fields are filled
        if (!kmSlab) {
            Swal.fire("Error", "All fields are required!", "error");
            return;
        }

        const requestBody = {
            kmSlab,
            fourInch,
            sixInch,
            eightInch,
            nineSixInch,
        };

        try {
            setLoading(true);
            const response = await fetch(`${API_BASE_CRM_URL}/kmslab`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(requestBody),
            });
            const result = await response.json();
            if (result.Status) {
                Swal.fire("Success", "Rate updated successfully!", "success");

                setKmSlab("");
                setFourInch("");
                setSixInch("");
                setEightInch("");
                setNineSixInch("");
                navigate("/slabrate")
            } else {
                Swal.fire("Error", result.Message || "Failed to update rate.", "error");
            }
        } catch (error) {
            Swal.fire("Error", "Something went wrong!", "error");
        } finally {
            setLoading(false);
        }
    };

    return (

        <Base>
            <div className="page-heade mb-3">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="page-title">
                        <h4>Add Lead</h4>
                        <p className='fw-semibold'>Fill out the form to add a new Slab rate</p>
                    </div>

                    <div className=''>
                        <Link to='/slabrate'>
                            <button className="btn btn-primary" style={{ borderRadius: '10px' }}>
                                <i className="fa-solid fa-arrow-left me-2"></i> Go to List
                            </button>
                        </Link>
                    </div>
                </div>
            </div>


            <div className='row'>

                <div className="col-xxl-12 col-xl-12">
                    <div className="card">
                        <div className="card-body" style={{ maxHeight: '100vh', overflowY: 'auto' }}>
                            <form onSubmit={handleSubmit}>
                                <div className='row m-3'>
                                    <h5 className='mb-3'>Slab rate</h5>
                                    <div className='col-md-4 col-lg-4 mb-2'>
                                        <label className='form-label'>Slab rate<span className='text-danger'>*</span></label>
                                        <input
                                            className='form-control'
                                            type='text'
                                            name='Company'
                                            value={kmSlab}
                                            onChange={(e) => setKmSlab(e.target.value)}
                                            placeholder='Enter Kmslab'
                                            required
                                        />
                                    </div>

                                    <div className='col-md-4 col-lg-4 mb-2'>
                                        <label className='form-label'>four inch</label>
                                        <input
                                            className='form-control'
                                            type='text'
                                            name='fourInch'
                                            value={fourInch}
                                            onChange={(e) => setFourInch(e.target.value)}
                                            placeholder='Enter fourInch'
                                        />
                                    </div>
                                    <div className='col-md-4 col-lg-4 mb-2'>
                                        <label className='form-label'>Six inch</label>
                                        <input
                                            className='form-control'
                                            type='text'
                                            name='sixInch'
                                            value={sixInch}
                                            onChange={(e) => setSixInch(e.target.value)}
                                            placeholder='Enter sixInch'
                                        />
                                    </div>
                                    <div className='col-md-4 col-lg-4 mb-2'>
                                        <label className='form-label'>Eight inch</label>
                                        <input
                                            className='form-control'
                                            type='text'
                                            name='eightInch'
                                            value={eightInch}
                                            onChange={(e) => setEightInch(e.target.value)}
                                            placeholder='Enter eightInch'
                                        />
                                    </div>

                                    <div className='col-md-4 col-lg-4 mb-2'>
                                        <label className='form-label'>Nine/Six inch</label>
                                        <input
                                            className='form-control'
                                            type='text'
                                            name='nineSixInch'
                                            value={nineSixInch}
                                            onChange={(e) => setNineSixInch(e.target.value)}
                                            placeholder='Enter nineSixInch'

                                        />
                                    </div>


                                </div>
                                <div className='row'>
                                    <button type="submit" className="btn btn-primary d-flex m-auto col-1" disabled={loading}>
                                        {loading ? 'Adding...' : 'Add Slabrate'}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </Base>



    );
}
