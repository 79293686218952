import React, { useState, useEffect } from "react";
import { API_BASE_CRM_URL } from "../../Config/Config";
import { Select } from "antd";


export default function EditDrivers({ locationObj, onClose }) {
    const [formData, setFormData] = useState({
        Name: "",
        ContactNo: "",
        Transporter_id: null,
    });

    const [transporterData, setTransporterData] = useState([]);
    const [loading, setLoading] = useState(false);

    // Fetch transporter data
    useEffect(() => {
        const fetchTransporterData = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${API_BASE_CRM_URL}GetTransporter`);
                if (!response.ok) throw new Error("Failed to fetch transporter data");

                const data = await response.json();
                if (data.Status) {
                    setTransporterData(data.ResultData);
                } else {
                    console.error("Invalid transporter data:", data.Message);
                }
            } catch (error) {
                console.error("Error fetching transporter data:", error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchTransporterData();
    }, []);

    // Populate form fields on initial load
    useEffect(() => {
        if (locationObj) {
            setFormData({
                Name: locationObj.Name || "",
                ContactNo: locationObj.ContactNo || "",
                Transporter_id: locationObj.Transporter_id || null,
            });
        }
    }, [locationObj]);

    // Map transporter data to Select options
    const transporterOptions = transporterData.map((transporter) => ({
        label: transporter.name, // Replace with correct field name
        value: transporter.id,   // Replace with correct field name
    }));

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    // Handle transporter selection change
    const handleTransporterChange = (value) => {
        setFormData((prev) => ({ ...prev, Transporter_id: value }));
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`${API_BASE_CRM_URL}UpdateDriver`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    id: locationObj.id, // Ensure the driver ID is passed
                    Name: formData.Name,
                    ContactNo: formData.ContactNo,
                    Transporter_id: formData.Transporter_id,
                }),
            });

            const result = await response.json();
            if (result.Status) {
                alert("Driver updated successfully!");
                onClose();
                window.location.reload();
            } else {
                alert(`Failed to update driver: ${result.Message}`);

            }
        } catch (error) {
            console.error("Error updating driver:", error.message);

        }
    };

    return (
        <div>
            {/* Offcanvas Styles */}
            <style>
                {`
                    .offcanvas.custom-offcanvas {
                        width: 400px !important;
                    }
                    @media (max-width: 576px) {
                        .offcanvas.custom-offcanvas {
                            width: 70% !important;
                        }
                    }
                `}
            </style>

            <div
                className="offcanvas offcanvas-end custom-offcanvas"
                tabIndex="-1"
                id="offcanvasRightEdit"
                aria-labelledby="offcanvasRightLabel"
            >
                <div className="offcanvas-header">
                    <h5 id="offcanvasRightLabel">Edit Driver Details</h5>
                    <button
                        type="button"
                        className="btn-close text-reset"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                        onClick={onClose}
                    ></button>
                </div>

                <div className="offcanvas-body">
                    <form onSubmit={handleSubmit}>
                        {/* Name Field */}
                        <div className="mb-3">
                            <label className="form-label">Name</label>
                            <input
                                type="text"
                                name="Name"
                                className="form-control"
                                value={formData.Name}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        {/* Contact Number Field */}
                        <div className="mb-3">
                            <label className="form-label">Contact Number</label>
                            <input
                                type="text"
                                name="ContactNo"
                                className="form-control"
                                value={formData.ContactNo}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        {/* Transporter Field */}
                        <div className="mb-3">
                            <label className="form-label">
                                Transporter <span className="text-danger">*</span>
                            </label>
                            <Select
                                placeholder="Choose Transporter"
                                value={formData.Transporter_id}
                                onChange={handleTransporterChange}
                                options={transporterOptions}
                                loading={loading}
                                allowClear
                                showSearch
                                style={{ width: "100%" }}
                            />
                        </div>

                        {/* Submit Button */}
                        <div className="d-flex justify-content-end">
                            <button type="submit" className="btn btn-primary">
                                Update
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
