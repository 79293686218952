import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Base from "../../Config/Base";
import { API_BASE_CRM_URL } from "../../Config/Config";
import Swal from "sweetalert2";
import { useLabelContext } from "../../../Context";

const EditTransporters = () => {
    const [currentStaffId, setCurrentStaffId] = useState();
    const [loading, setLoading] = useState(false);
    const { ID } = useLabelContext();
    const TaskId = ID;

    const [transporterData, setTransporterData] = useState({
        id: TaskId,
        name: "",
        contact_no: "",
        email: "",
        pan_card: "",
        pan_photo: null,
        aadhaar_no: "",
        aadhaar_photo: null,
        gst_pdf: null,
        gst_no: "",
        vehicle_id: "",
        driver_id: "",
    });

    const [panPhotoPreview, setPanPhotoPreview] = useState(null);
    const [aadhaarPhotoPreview, setAadhaarPhotoPreview] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        const userDetails = sessionStorage.getItem("userDetails");
        if (userDetails) {
            const parsedUserDetails = JSON.parse(userDetails);
            setCurrentStaffId(parsedUserDetails.staffid);
        }
    }, []);

    useEffect(() => {
        if (TaskId) {
            const fetchData = async () => {
                setLoading(true);
                try {
                    const response = await fetch(`${API_BASE_CRM_URL}GetTransporter`);
                    if (!response.ok) throw new Error("Network response was not ok");

                    const data = await response.json();
                    if (data.Status) {
                        const transporter = data.ResultData.find((item) => item.id === TaskId);
                        if (transporter) {
                            setTransporterData({
                                ...transporter,
                            });
                            setPanPhotoPreview(transporter.pan_photo);
                            setAadhaarPhotoPreview(transporter.aadhaar_photo);
                        } else {
                            console.warn("Transporter not found for the given ID");
                        }
                    } else {
                        console.error("Error in API response:", data);
                    }
                } catch (error) {
                    console.error("Error fetching transporter data:", error);
                } finally {
                    setLoading(false);
                }
            };

            fetchData();
        }
    }, [TaskId]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setTransporterData({
            ...transporterData,
            [name]: value,
        });
    };

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        setTransporterData({
            ...transporterData,
            [name]: files[0],
        });

        // Generate previews
        if (name === "pan_photo") {
            setPanPhotoPreview(URL.createObjectURL(files[0]));
        } else if (name === "aadhaar_photo") {
            setAadhaarPhotoPreview(URL.createObjectURL(files[0]));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const formData = new FormData();
        formData.append("id", TaskId);
        Object.keys(transporterData).forEach((key) => {
            if (transporterData[key] instanceof File || transporterData[key] instanceof Blob) {
                formData.append(key, transporterData[key]);
            } else {
                formData.append(key, transporterData[key]);
            }
        });

        try {
            const response = await fetch(`${API_BASE_CRM_URL}EditTransporter`, {
                method: "POST",
                body: formData,
            });

            const result = await response.json();

            if (result.Status) {
                Swal.fire({
                    title: "Success!",
                    text: "Transporter updated successfully!",
                    icon: "success",
                    confirmButtonText: "Ok",
                }).then(() => {
                    navigate("/transporter");
                });
            } else {
                Swal.fire({
                    title: "Error",
                    text: result.Message || "Failed to update the transporter.",
                    icon: "error",
                    confirmButtonText: "Ok",
                });
            }
        } catch (error) {
            console.error("Error submitting form:", error);
            Swal.fire({
                title: "Error",
                text: "An error occurred while submitting the form.",
                icon: "error",
                confirmButtonText: "Ok",
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Base>
            <div className="page-header">
                <div className="add-item d-flex">
                    <div className="page-title">
                        <h4>Edit Transporter</h4>
                    </div>
                </div>
                <ul className="table-top-head">
                    <li>
                        <Link to="/transporter" className="btn btn-added">
                            <i className="fa-solid fa-arrow-left me-1"></i>Go to List
                        </Link>
                    </li>
                </ul>
            </div>

            <div className="card table-list-card">
                <div className="card-body">
                    <form onSubmit={handleSubmit}>
                        <div className="row m-2">
                            <div className="d-flex flex-column col-lg-4 col-md-6 mb-2">
                                <label className="form-label">Name</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="name"
                                    value={transporterData.name}
                                    onChange={handleInputChange}
                                    placeholder="Enter Name"
                                    required
                                />
                            </div>

                            <div className="d-flex flex-column col-lg-4 col-md-6 mb-2">
                                <label className="form-label">Contact Number</label>
                                <input
                                    className="form-control"
                                    type="number"
                                    name="contact_no"
                                    value={transporterData.contact_no}
                                    onChange={handleInputChange}
                                    placeholder="Enter Contact Number"
                                />
                            </div>

                            <div className="d-flex flex-column col-lg-4 col-md-6 mb-2">
                                <label className="form-label">Email</label>
                                <input
                                    className="form-control"
                                    type="email"
                                    name="email"
                                    value={transporterData.email}
                                    onChange={handleInputChange}
                                    placeholder="Enter Email"
                                />
                            </div>

                            <div className="d-flex flex-column col-lg-4 col-md-6 mb-2">
                                <label className="form-label">PAN Card</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="pan_card"
                                    value={transporterData.pan_card}
                                    onChange={handleInputChange}
                                    placeholder="Enter PAN Card"
                                />
                            </div>

                            <div className="d-flex flex-column col-lg-4 col-md-6 mb-2">
                                <label className="form-label">Aadhaar Number</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="aadhaar_no"
                                    value={transporterData.aadhaar_no}
                                    onChange={handleInputChange}
                                    placeholder="Enter Aadhaar Number"
                                />
                            </div>

                            <div className="d-flex flex-column col-lg-4 col-md-6 mb-2">
                                <label className="form-label">PAN Photo</label>
                                {panPhotoPreview && (
                                    <img
                                        src={panPhotoPreview}
                                        alt="PAN Preview"
                                        className="img-thumbnail mb-2"
                                        style={{ maxWidth: "100px" }}
                                    />
                                )}
                                <input
                                    className="form-control"
                                    type="file"
                                    name="pan_photo"
                                    onChange={handleFileChange}
                                />
                            </div>

                            <div className="d-flex flex-column col-lg-4 mb-2">
                                <label className="form-label">Aadhaar Photo</label>
                                {aadhaarPhotoPreview && (
                                    <img
                                        src={aadhaarPhotoPreview}
                                        alt="Aadhaar Preview"
                                        className="img-thumbnail mb-1"
                                        style={{ maxWidth: "100px" }}
                                    />
                                )}
                                <input
                                    className="form-control"
                                    type="file"
                                    name="aadhaar_photo"
                                    onChange={handleFileChange}
                                />
                            </div>

                            <div className="d-flex flex-column col-lg-4 col-md-6 mb-2">
                                <label className="form-label">GST Number</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="gst_no"
                                    value={transporterData.gst_no}
                                    onChange={handleInputChange}
                                    placeholder="Enter GST Number"
                                />
                            </div>

                            <div className="d-flex flex-column col-2 m-auto mt-2">
                                <button className="btn btn-primary" type="submit" disabled={loading}>
                                    {loading ? "Submitting..." : "Submit"}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Base>
    );
};

export default EditTransporters;
