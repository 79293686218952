import React, { useState, useEffect } from "react";
import { API_BASE_CRM_URL } from "../../Config/Config";
import Swal from "sweetalert2";
import "../../Config/swal.css";
import { Select } from "antd";
import { useNavigate } from "react-router-dom";

export default function AddRate() {
    const [productsData, setProductsData] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [bricksPerPallet, setBricksPerPallet] = useState("");
    const [machine, setMachine] = useState("");
    const [rate, setRate] = useState("");
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        // Fetch products data
        const fetchData = async () => {
            try {
                const response = await fetch(`${API_BASE_CRM_URL}GetProducts`);
                const data = await response.json();
                if (data.Status) {
                    setProductsData(data.ResultData);
                } else {
                    console.error("Failed to fetch products");
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    // Update product options to include id as value
    const productOptions = productsData.map((item) => ({
        value: item.id, // Use the product ID here
        label: item.description, // Display description
    }));

    const handleProductChange = (value) => {
        console.log("Selected Product ID:", value); // Log the product ID
        setSelectedProduct(value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!selectedProduct || !bricksPerPallet || !machine || !rate) {
            Swal.fire("Error", "All fields are required!", "error");
            return;
        }

        const requestBody = {
            Product: selectedProduct, // Send the product ID
            BricksPerPallet: bricksPerPallet,
            Machine: machine,
            Rate: rate,
        };

        try {
            setLoading(true);
            const response = await fetch(`${API_BASE_CRM_URL}AddRate`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(requestBody),
            });

            const result = await response.json();
            if (result.Status) {
                Swal.fire("Success", "Rate added successfully!", "success");
                // Reset form
                setSelectedProduct(null);
                setBricksPerPallet("");
                setMachine("");
                setRate("");
                window.location.reload();
            } else {
                Swal.fire("Error", result.Message || "Failed to add rate.", "error");
                window.location.reload();
            }
        } catch (error) {
            Swal.fire("Error", "Something went wrong!", "error");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <div
                className="offcanvas offcanvas-end custom-offcanvas"
                tabIndex="-1"
                id="offcanvasRight"
                aria-labelledby="offcanvasRightLabel"
            >
                <div className="offcanvas-header">
                    <h5 id="offcanvasRightLabel">Add Rate</h5>
                    <button
                        type="button"
                        className="btn-close text-reset"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                    ></button>
                </div>
                <div className="offcanvas-body">
                    <form onSubmit={handleSubmit}>
                        <div className="mt-2 d-flex flex-column mb-2">
                            <label className="form-label">Product</label>
                            <Select
                                showSearch
                                placeholder="Select Product"
                                value={selectedProduct}
                                onChange={handleProductChange} // Log and set product ID
                                filterOption={(input, option) =>
                                    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                                }
                                options={productOptions}
                            />
                        </div>

                        <div className="mt-2">
                            <label className="form-label">Bricks Per Pallet</label>
                            <input
                                className="form-control"
                                placeholder="Enter bricks per pallet"
                                value={bricksPerPallet}
                                onChange={(e) => setBricksPerPallet(e.target.value)}
                                required
                            />
                        </div>

                        <div className="mt-2">
                            <label className="form-label">Machine</label>
                            <input
                                className="form-control"
                                placeholder="Enter machine details"
                                value={machine}
                                onChange={(e) => setMachine(e.target.value)}
                                required
                            />
                        </div>

                        <div className="mt-2">
                            <label className="form-label">Rate</label>
                            <input
                                className="form-control"
                                placeholder="Enter rate"
                                value={rate}
                                onChange={(e) => setRate(e.target.value)}
                                required
                            />
                        </div>

                        <button
                            type="submit"
                            className="btn btn-primary d-flex m-auto mt-4"
                            disabled={loading}
                        >
                            {loading ? "Submitting..." : "Submit"}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}
