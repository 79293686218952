import React, { useState, useEffect } from "react";
import { API_BASE_CRM_URL } from "../../Config/Config";
import Select from "react-select";

export default function EditVehicle({ locationObj, onClose, onUpdate }) {
    const [transporterData, setTransporterData] = useState([]);
    const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState({
        VehicleNo: "",
        PassingWt: "",
        Transporter_id: null,
    });

    // Fetch transporter data
    useEffect(() => {
        const fetchTransporterData = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${API_BASE_CRM_URL}GetTransporter`);
                if (!response.ok) throw new Error("Failed to fetch transporter data");
                const data = await response.json();
                if (data.Status) {
                    setTransporterData(data.ResultData);
                } else {
                    console.error("Invalid transporter data:", data.Message);
                }
            } catch (error) {
                console.error("Error fetching transporter data:", error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchTransporterData();
    }, []);

    // Update form data with locationObj data
    useEffect(() => {
        if (locationObj) {
            setFormData({
                VehicleNo: locationObj.VehicleNo || "",
                PassingWt: locationObj.PassingWt || "",
                Transporter_id: transporterData.find(
                    (t) => t.name === locationObj.TransporterName
                ) || null,
            });
        }
    }, [locationObj, transporterData]);

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleTransporterChange = (option) => {
        setFormData((prev) => ({ ...prev, Transporter_id: option }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const payload = {
            id: locationObj.id,
            VehicleNo: formData.VehicleNo,
            PassingWt: formData.PassingWt,
            Transporter: formData.Transporter_id?.value,
        };

        try {
            const response = await fetch(`${API_BASE_CRM_URL}UpdateVehicleDetails`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(payload),
            });

            const result = await response.json();
            if (result.Status) {
                alert("Vehicle details updated successfully");
                if (onUpdate) onUpdate(result.ResultData); // Callback to refresh parent data
                onClose(); // Close the form
                window.location.reload();
            } else {
                alert(`Error: ${result.Message}`);
            }
        } catch (error) {
            console.error("Error updating vehicle details:", error.message);
            alert("An unexpected error occurred.");
        }
    };

    const transporterOptions = transporterData.map((transporter) => ({
        label: transporter.name,
        value: transporter.id,
    }));

    return (
        <div>
            <style>
                {`
          .offcanvas.custom-offcanvas {
            width: 400px !important;
          }
          @media (max-width: 576px) {
            .offcanvas.custom-offcanvas {
              width: 70% !important;
            }
          }
        `}
            </style>

            <div
                className="offcanvas offcanvas-end custom-offcanvas"
                tabIndex="-1"
                id="offcanvasRightEdit"
                aria-labelledby="offcanvasRightLabel"
            >
                <div className="offcanvas-header">
                    <h5 id="offcanvasRightLabel">Edit Vehicle</h5>
                    <button
                        type="button"
                        className="btn-close text-reset"
                        onClick={onClose}
                    ></button>
                </div>

                <div className="offcanvas-body">
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label className="form-label">Vehicle Number</label>
                            <input
                                type="text"
                                name="VehicleNo"
                                className="form-control"
                                value={formData.VehicleNo}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div className="mb-3">
                            <label className="form-label">Transporter</label>
                            <Select
                                placeholder="Choose Transporter"
                                value={formData.Transporter_id}
                                onChange={handleTransporterChange}
                                options={transporterOptions}
                                isLoading={loading}
                                isClearable
                            />
                        </div>

                        <div className="mb-3">
                            <label className="form-label">Passing Weight</label>
                            <input
                                type="text"
                                name="PassingWt"
                                className="form-control"
                                value={formData.PassingWt}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div className="text-end">
                            <button type="submit" className="btn btn-primary">
                                Update
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
