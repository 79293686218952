import React, { useState, useEffect, useMemo } from "react";
import Base from "../../Config/Base";
import { API_BASE_CRM_URL } from "../../Config/Config";
import Pagination from "../../Config/Pagination";
import "../../../Assets/popup.css";
import PDFIMG from "../../Config/pdf.svg";
import EXCELIMG from "../../Config/excel.svg";
import Swal from "sweetalert2";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import "../../Config/loading.css";
import { useNavigate } from "react-router-dom";

import EditVehicle from "./Editvehicle";
import AddVehicle from "./Addvehicle";

export default function Vehicle() {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [vehicleData, setVehicleata] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [locationViewObj, setLocationViewObj] = useState([]);
    const [locationEditObj, setLocationEditObj] = useState([]);

    useEffect(() => {
        const userDetails = sessionStorage.getItem("userDetails");
        if (!userDetails) {
            navigate("/");
        }
    }, [navigate]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${API_BASE_CRM_URL}GetVehicleDetails`);
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();
                console.log(data, "ddddddddddddd");

                if (data.Status) {
                    setVehicleata(data.ResultData);
                } else {
                    console.error("Fetched data is not an array:", data);
                    setVehicleata([]);
                }
            } catch (error) {
                console.error("Error fetching datasssss:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleReload = () => {
        window.location.reload();
    };

    const showLocationViewModal = (obj) => {
        setLocationViewObj(obj);
    };

    const showLocationEditModal = (obj) => {
        setLocationEditObj(obj);
    };

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const filteredItems = useMemo(() => {
        const results = vehicleData.filter(
            (item) =>
            (item.VehicleNo &&
                item.VehicleNo.toLowerCase().includes(searchTerm.toLowerCase()))

        );

        setCurrentPage(1);

        return results;
    }, [vehicleData, searchTerm]);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();

        return `${day}-${month}-${year}`;
    };


    const handleExcelDownload = () => {
        if (filteredItems.length === 0) {
            return;
        }

        // Create a new workbook
        const wb = XLSX.utils.book_new();

        // Convert your data to worksheet format
        const wsData = filteredItems.map((item) => ({
            Customer: item.cus_name,
            Project: item.pro_name,
            Latitude: item.latitude,
            Longitude: item.longitude,
        }));

        const ws = XLSX.utils.json_to_sheet(wsData);

        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(wb, ws, "followupsData");

        // Save the workbook to a file
        XLSX.writeFile(wb, "projectLocations.xlsx");
    };

    const handlePDFDownload = () => {
        const doc = new jsPDF();

        // Set properties for PDF
        doc.setProperties({
            title: "vehicle",
            author: "Your Name",
            creator: "Your Application",
        });

        // Add data to PDF
        const tableData = [];

        // Add a header row
        const headers = ["Vehicle Number", "Transporter", "passingweight",];
        tableData.push(headers);

        // Add rows
        filteredItems.forEach((item) => {
            const row = [];
            row.push(item.cus_name, item.pro_name, item.latitude, item.longitude);
            tableData.push(row);
        });

        // Generate table
        doc.autoTable({
            head: [headers],
            body: tableData.slice(1),
        });
        // Save the PDF
        doc.save("projectLocations.pdf");
    };

    return (
        <Base>
            <div className="page-header">
                <div className="add-item d-flex">
                    <div className="page-title">
                        <h4>Vehicle </h4>
                        <h6>Manage your Vehicles</h6>
                    </div>
                </div>
                <ul className="table-top-head">
                    <li>
                        <a data-bs-toggle="tooltip" data-bs-placement="top" title="Pdf">
                            <img src={PDFIMG} alt="img" onClick={handlePDFDownload} />
                        </a>
                    </li>
                    <li>
                        <a data-bs-toggle="tooltip" data-bs-placement="top" title="Excel">
                            <img src={EXCELIMG} alt="img" onClick={handleExcelDownload} />
                        </a>
                    </li>

                    <li>
                        <a title="Refresh" onClick={handleReload}>
                            <i className="fa-solid fa-arrow-rotate-left fs-5"></i>
                        </a>
                    </li>
                    <li>
                        <a title="Collapse" id="collapse-header">
                            <i className="fa-solid fa-chevron-up fs-6"></i>
                        </a>
                    </li>
                    {/* <li>
                        <a className="btn btn-added" data-bs-toggle="modal" data-bs-target="#add-logistic-rate"><i className="fa-solid fa-plus"></i></a>
                    </li> */}
                    <li>
                        <a
                            className="btn btn-added"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasRight"
                            aria-controls="offcanvasRight"
                        >
                            <i className="fa-solid fa-plus"></i>
                        </a>
                    </li>
                </ul>
            </div>

            <div className="card table-list-card">
                <div className="card-body">
                    <div className="table-top mb-3">
                        <div className="search-set">
                            <div className="search-input">
                                <input
                                    type="text"
                                    name="search"
                                    placeholder="Search..."
                                    className="form-control"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>

                    {/* Responsive Table */}
                    <div
                        style={{
                            overflowX: "auto",
                            WebkitOverflowScrolling: "touch",
                            display: "block",
                        }}
                    >
                        <table className="table dataew" style={{ minWidth: "100%", tableLayout: "auto" }}>
                            <thead>
                                <tr>
                                    <th className="no-sort">
                                        <label className="checkboxs">
                                            <input type="checkbox" id="select-all" />
                                            <span className="checkmarks"></span>
                                        </label>
                                    </th>
                                    <th>Vehicle Number</th>
                                    <th>Transporter</th>
                                    <th>PassingWt</th>
                                    <th className="no-sort">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr>
                                        <td colSpan={5} style={{ textAlign: "center" }}>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <div className="containers m-auto">
                                                    <div className="dot"></div>
                                                    <div className="dot"></div>
                                                    <div className="dot"></div>
                                                    <div className="dot"></div>
                                                    <div className="dot"></div>
                                                    <div className="dot"></div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ) : (
                                    currentItems &&
                                    currentItems.map((item, index) => (
                                        <tr key={index}>
                                            <td>
                                                <label className="checkboxs">
                                                    <input type="checkbox" />
                                                    <span className="checkmarks"></span>
                                                </label>
                                            </td>
                                            <td>{item.VehicleNo}</td>
                                            <td>{item.TransporterName}</td>
                                            <td>{item.PassingWt}</td>
                                            <td className="action-table-data">
                                                <div className="edit-delete-action">
                                                    <a
                                                        className="me-2 p-2"
                                                        data-bs-toggle="offcanvas"
                                                        data-bs-target="#offcanvasRightEdit"
                                                        aria-controls="offcanvasRight"
                                                        onClick={() => showLocationEditModal(item)}
                                                    >
                                                        <i className="fa-regular fa-pen-to-square text-info"></i>
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                    </div>

                    {/* Pagination */}
                    <Pagination
                        itemsPerPage={itemsPerPage}
                        totalItems={filteredItems.length}
                        paginate={paginate}
                        currentPage={currentPage}
                    />
                </div>
            </div>


            <AddVehicle />

            <EditVehicle locationObj={locationEditObj} />
        </Base>
    );
}
