import React, { useState, useEffect } from "react";
import { API_BASE_CRM_URL } from "../../Config/Config";
import Swal from "sweetalert2";
import "../../Config/swal.css";
import { Link, useNavigate } from "react-router-dom";
import Base from "../../Config/Base";
import { useLabelContext } from "../../../Context";

export default function EditSlabrate() {
    const [loading, setLoading] = useState(false);
    const [formdata, setFormdata] = useState({
        kmSlab: "",
        fourInch: "",
        sixInch: "",
        eightInch: "",
        nineSixInch: "",
    });
    const { ID } = useLabelContext();
    const SlabId = ID;
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            if (SlabId) {
                try {
                    setLoading(true);
                    const response = await fetch(`${API_BASE_CRM_URL}/kmslab/${SlabId}`);
                    const result = await response.json();

                    if (result.Status) {
                        const data = result.ResultData;
                        setFormdata({
                            kmSlab: data.kmSlab || "",
                            fourInch: data.fourInch || "",
                            sixInch: data.sixInch || "",
                            eightInch: data.eightInch || "",
                            nineSixInch: data.nineSixInch || "",
                        });
                    } else {
                        Swal.fire("Error", result.Message || "Failed to fetch data.", "error");
                    }
                } catch (error) {
                    Swal.fire("Error", "Something went wrong!", "error");
                } finally {
                    setLoading(false);
                }
            }
        };
        fetchData();
    }, [SlabId]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formdata.kmSlab) {
            Swal.fire("Error", "All fields are required!", "error");
            return;
        }

        const requestBody = { ...formdata };

        try {
            setLoading(true);
            const response = await fetch(`${API_BASE_CRM_URL}/updatekmslab`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(requestBody),
            });
            const result = await response.json();
            if (result.Status) {
                Swal.fire("Success", result.Message || "Operation successful!", "success");
                navigate("/slabrate");
            } else {
                Swal.fire("Error", result.Message || "Failed to update rate.", "error");
            }
        } catch (error) {
            Swal.fire("Error", "Something went wrong!", "error");
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormdata((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    return (
        <Base>
            <div className="page-heade mb-3">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="page-title">
                        <h4>Edit Slab Rate</h4>
                        <p className="fw-semibold">Update slab rate details below</p>
                    </div>
                    <div>
                        <Link to="/slabrate">
                            <button className="btn btn-primary" style={{ borderRadius: "10px" }}>
                                <i className="fa-solid fa-arrow-left me-2"></i> Go to List
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xxl-12 col-xl-12">
                    <div className="card">
                        <div className="card-body" style={{ maxHeight: "100vh", overflowY: "auto" }}>
                            <form onSubmit={handleSubmit}>
                                <div className="row m-3">
                                    <h5 className="mb-3">Slab Rate</h5>
                                    <div className="col-md-4 col-lg-4 mb-2">
                                        <label className="form-label">Slab Rate<span className="text-danger">*</span></label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="kmSlab"
                                            value={formdata.kmSlab}
                                            onChange={handleInputChange}
                                            placeholder="Enter Kmslab"
                                            required
                                        />
                                    </div>
                                    <div className="col-md-4 col-lg-4 mb-2">
                                        <label className="form-label">Four Inch</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="fourInch"
                                            value={formdata.fourInch}
                                            onChange={handleInputChange}
                                            placeholder="Enter fourInch"
                                        />
                                    </div>
                                    <div className="col-md-4 col-lg-4 mb-2">
                                        <label className="form-label">Six Inch</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="sixInch"
                                            value={formdata.sixInch}
                                            onChange={handleInputChange}
                                            placeholder="Enter sixInch"
                                        />
                                    </div>
                                    <div className="col-md-4 col-lg-4 mb-2">
                                        <label className="form-label">Eight Inch</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="eightInch"
                                            value={formdata.eightInch}
                                            onChange={handleInputChange}
                                            placeholder="Enter eightInch"
                                        />
                                    </div>
                                    <div className="col-md-4 col-lg-4 mb-2">
                                        <label className="form-label">Nine/Six Inch</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="nineSixInch"
                                            value={formdata.nineSixInch}
                                            onChange={handleInputChange}
                                            placeholder="Enter nineSixInch"
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <button type="submit" className="btn btn-primary d-flex m-auto col-1" disabled={loading}>
                                        {loading ? "Updating..." : "Update"}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Base>
    );
}
