import React, { useState, useEffect } from "react";
import { API_BASE_CRM_URL } from "../../Config/Config";
import Swal from "sweetalert2";
import '../../Config/swal.css';
import { Select } from 'antd';


export default function AddVehicle() {

    const [vehicleNo, setVehicleNo] = useState('');
    const [transporter, setTransporter] = useState('');
    const [passingWt, setPassingWt] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const [transporterData, setTransporterData] = useState('');



    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${API_BASE_CRM_URL}GetTransporter`);
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();

                if (data.Status) {
                    setTransporterData(data.ResultData);
                    console.log(data.ResultData);
                } else {
                    console.error("Fetched data is not an array:", data);
                    setTransporterData([]);
                }
            } catch (error) {
                console.error("Error fetching datasssss:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);


    const transporterOptions = Array.isArray(transporterData) ? transporterData.map((transporter) => ({
        label: transporter.name,
        value: transporter.id,
    })) : [];

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const requestData = {
            VehicleNo: vehicleNo,
            Transporter: transporter,
            PassingWt: passingWt,
        };
        console.log(requestData)
        try {
            const response = await fetch(`${API_BASE_CRM_URL}AddVehicleDetails`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData),
            });

            const data = await response.json();

            if (data.Status) {
                setSuccessMessage('Vehicle details added successfully');
                window.location.reload();
            } else {
                setError('Failed to add vehicle details');
            }
        } catch (error) {
            console.error('Error:', error);
            setError('An error occurred while adding vehicle details');
        } finally {
            setLoading(false);
        }
    };

    const handleTransporterChange = (value) => {
        setTransporter(value);
    };


    return (
        <div>
            <style>
                {`
                .offcanvas.custom-offcanvas {
                    width: 400px !important; /* Adjust this as needed */
                }

                @media (max-width: 576px) {
                    .offcanvas.custom-offcanvas {
                    width: 70% !important; /* Adjust the percentage as needed */
                    }
                }
                `}
            </style>

            <div
                className="offcanvas offcanvas-end custom-offcanvas"
                tabIndex="-1"
                id="offcanvasRight"
                aria-labelledby="offcanvasRightLabel"
            >
                <div className="offcanvas-header">
                    <h5 id="offcanvasRightLabel">Add Driver</h5>
                    <button
                        type="button"
                        className="btn-close text-reset"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                    ></button>
                </div>
                <div className="offcanvas-body">
                    <form onSubmit={handleSubmit}>

                        <div className="mt-2">
                            <label className="form-label">VehicleNo <span className='text-danger'>*</span></label>
                            <input
                                className="form-control"
                                placeholder="Enter Name"
                                value={vehicleNo}
                                onChange={(e) => setVehicleNo(e.target.value)}
                                required
                            />
                        </div>
                        <div className="mt-2 d-flex flex-column mb-2">
                            <label className="form-label">transporter</label>
                            <Select
                                showSearch
                                placeholder="Choose Customer"
                                value={transporter || null}
                                onChange={handleTransporterChange}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={transporterOptions}
                            />
                        </div>

                        <div className="mt-2">
                            <label className="form-label">Passing Weight </label>
                            <textarea
                                className="form-control"
                                placeholder="Enter address.."
                                value={passingWt}
                                onChange={(e) => setPassingWt(e.target.value)}
                            />
                        </div>

                        <button type="submit" className="btn btn-primary d-flex m-auto mt-4">
                            {loading ? 'Submitting...' : 'Submit'}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}
