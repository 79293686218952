import React, { useState, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { API_BASE_CRM_URL } from "../../Config/Config";
import Pagination from "../../Config/Pagination";
import Base from "../../Config/Base";
import PDFIMG from "../../Config/pdf.svg";
import EXCELIMG from "../../Config/excel.svg";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Swal from "sweetalert2";
const Payments = () => {
  const [loading, setLoading] = useState(false);
  const [paymentsData, setPaymentsData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${API_BASE_CRM_URL}GetPayments`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();

        if (data.Status) {
          setPaymentsData(data.ResultData);
        } else {
          console.error("Fetched data is not an array:", data);
          setPaymentsData([]);
        }
      } catch (error) {
        console.error("Error fetching datasssss:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // const filteredItems = paymentsData.filter(
  //   (item) =>
  //     (item.paymentsModeName &&
  //       item.paymentsModeName.toString().includes(searchTerm.toString())) ||
  //     (item.transactionid &&
  //       item.transactionid.toLowerCase().includes(searchTerm.toLowerCase())) ||
  //     (item.company_name &&
  //       item.company_name.toLowerCase().includes(searchTerm.toLowerCase()))
  // );

  const filteredItems = useMemo(() => {
    const results = paymentsData.filter(
      (item) =>
        (item.paymentsModeName &&
          item.paymentsModeName.toString().includes(searchTerm.toString())) ||
        (item.transactionid &&
          item.transactionid.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (item.company_name &&
          item.company_name.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    
    setCurrentPage(1);

    return results;
  }, [paymentsData, searchTerm]);

  // Calculate indices for slicing data
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };

  const handleReload = () => {
    window.location.reload();
  };

  const handleExcelDownload = () => {
    if (paymentsData.length === 0) {
      return; // Don't download if there is no data
    }

    // Create a new workbook
    const wb = XLSX.utils.book_new();

    // Convert your data to worksheet format
    const wsData = paymentsData.map((item) => ({
      Payment: item.paymentsId,
      Invoice: `INV-2${item.invoiceId}`,
      "Payment Mode": item.paymentsModeName,
      "Transaction Id": item.transactionid,
      Customer: item.company_name,
      Amount: item.amount,
      Date: formatDate(item.date),
    }));

    const ws = XLSX.utils.json_to_sheet(wsData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "paymentsData");

    // Save the workbook to a file
    XLSX.writeFile(wb, "paymentsData.xlsx");
  };

  const handlePDFDownload = () => {
    const doc = new jsPDF();

    // Set properties for PDF
    doc.setProperties({
      title: "Leads Data",
      author: "Your Name",
      creator: "Your Application",
    });

    // Add data to PDF
    const tableData = []; // Your data array here

    // Add a header row
    const headers = [
      "Payment",
      "Invoice",
      "Payment Mode",
      "Transaction Id",
      "Customer",
      "Amount",
      "Date",
    ];
    tableData.push(headers);

    // Add rows
    paymentsData.forEach((item) => {
      const row = [];
      row.push(
        item.paymentsId,
        `INV-2${item.invoiceId}`,
        item.paymentsModeName,
        item.transactionid,
        item.company_name,
        item.amount,
        formatDate(item.date)
      );
      tableData.push(row);
    });

    // Generate table
    doc.autoTable({
      head: [headers],
      body: tableData.slice(1),
    });

    // Save the PDF
    doc.save("paymentsData.pdf");
  };

  return (
    <Base>
      <div className="page-header">
        <div className="d-flex justify-content-between flex-wrap">
          <div className="page-title">
            <h4>Invoice Payment</h4>
            <h6>Manage your Invoice PAyments</h6>
          </div>
        </div>
        <div className="d-flex flex-wrap align-items-center mt-3">
          <div className="flex-fill d-md-flex justify-content-between align-items-center">
            <div className="input-group mb-2 mb-md-0">
              <input
                type="text"
                name="search"
                placeholder="Search..."
                className="form-control"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <button className="btn btn-primary" type="button">
                <i className="fas fa-search"></i>
              </button>
            </div>
          </div>
          <ul className="table-top-head d-flex list-unstyled mb-0 flex-wrap">
            <li className="ms-2 ms-md-3">
              <a
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Pdf"
                onClick={handlePDFDownload}
              >
                <img src="assets/img/icons/pdf.svg" alt="PDF" />
              </a>
            </li>
            <li className="ms-2 ms-md-3">
              <a
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Excel"
                onClick={handleExcelDownload}
              >
                <img src="assets/img/icons/excel.svg" alt="Excel" />
              </a>
            </li>
            {/* <li className="ms-2 ms-md-3">
                <a data-bs-toggle="tooltip" data-bs-placement="top" title="Print">
                    <i className="fa-solid fa-print fs-5"></i>
                </a>
            </li> */}
            <li className="ms-2 ms-md-3">
              <a
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Refresh"
                onClick={handleReload}
              >
                <i className="fa-solid fa-arrow-rotate-left fs-5"></i>
              </a>
            </li>
            <li className="ms-2 ms-md-3">
              <a
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Collapse"
                id="collapse-header"
              >
                <i className="fa-solid fa-chevron-up fs-6"></i>
              </a>
            </li>
            {/* <li className="ms-2 ms-md-3">
            <Link className="btn btn-added" to="/add-credit-notes">
                <i className="fa-solid fa-plus"></i>
            </Link>
            </li> */}
          </ul>
        </div>
      </div>

      <div className="card table-list-card">
        <div className="card-body" style={{ minHeight: "100px" }}>
          <div className="table-responsive ">
            <table className="table dataew mb-2">
              <thead>
                Search...
                <tr>
                  <th className="no-sort">#</th>
                  <th className="no-sort text-start">Action</th>
                  <th className="">Payment #</th>
                  <th className="">Invoice #</th>
                  <th className="">Payment Mode</th>
                  <th>Transaction ID</th>
                  <th>Customer</th>
                  <th>Amount</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <td colSpan={12} className="etxt-center">
                    <p className="text-center">
                      <div className="containers m-auto">
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                      </div>
                    </p>
                  </td>
                ) : (
                  currentItems &&
                  currentItems.map((item, index) => (
                    <tr key={index}>
                      <td>{indexOfFirstItem + index + 1}</td>
                      <td className="text-center">
                        <div className="dropdown">
                          <a
                            className="action-set"
                            href="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i
                              className="fa fa-ellipsis-v"
                              aria-hidden="true"
                            ></i>
                          </a>
                          <div className="dropdown-menu">
                            <Link
                              to={`/view-payment/${item.paymentsId}`}
                              className="dropdown-item"
                            >
                              <i className="fa-regular fa-eye me-2"></i>View
                            </Link>
                            <Link
                              className={`dropdown-item `}
                              to={`/edit-payment/${item.paymentsId}`}
                            >
                              <i className="fa-regular fa-pen-to-square text-info me-2"></i>
                              Edit
                            </Link>
                            {/* <a className={`dropdown-item ${item.status === 1 ? '' : 'disabled'}`} href="javascript:void(0);">
                                                            <i className="fa-regular fa-trash-can text-danger me-2"></i>Delete
                                                        </a> */}
                          </div>
                        </div>
                      </td>
                      <td>{item.paymentsId}</td>
                      <td>INV-{item.invoiceId}</td>
                      <td>{item.paymentsModeName}</td>
                      <td>{item.transactionid}</td>
                      <td>{item.company_name}</td>
                      <td>{item.amount}</td>
                      <td>{formatDate(item.date)}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={filteredItems.length}
              paginate={paginate}
              currentPage={currentPage}
            />
          </div>
          {/* Mobile View */}
          {/* <div className="row d-block d-md-none d-lg-none" style={{ maxHeight: '100vh', overflowY: 'auto' }}>
                        {loading ?
                            <td colSpan={12} className="text-center">
                                <p className="text-center">
                                    <div className="containers m-auto">
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                    </div>
                                </p>
                            </td> :
                            currentItems && currentItems.map((item, index) => (
                                <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6" key={index}>
                                    <div className="employee-grid-profile">
                                        <div className="profile-head">
                                            <p>{formatDate(item.createdon)}</p>
                                            <div className="profile-head-action">
                                                {item.IsActive === 1 ? (
                                                    <span className="badge bg-outline-success text-center w-auto me-1">Active</span>
                                                ) : (
                                                    <span className="badge bg-outline-danger text-center w-auto me-1">Inactive</span>
                                                )}
                                                <div className="dropdown profile-action">
                                                    <a href="#" className="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><svg
                                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                        stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                                        className="feather feather-more-vertical feather-user">
                                                        <circle cx="12" cy="12" r="1"></circle>
                                                        <circle cx="12" cy="5" r="1"></circle>
                                                        <circle cx="12" cy="19" r="1"></circle>
                                                    </svg></a>
                                                    <ul className="dropdown-menu">
                                                        <li className=" mt-2">
                                                            <a
                                                                className="dropdown-item"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#view-logistic-rate"
                                                                onClick={() => showLogisticViewModal(item)}
                                                            >
                                                                <i className="fa-regular fa-eye me-2"></i> View
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                className="dropdown-item"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#edit-logistic-rate"
                                                                onClick={() => showLogisticEditModal(item)}
                                                            >
                                                                <i className="fa-regular fa-pen-to-square me-2 text-info"></i>Edit
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a className="dropdown-item confirm-text mb-0">
                                                                <i className="fa-regular fa-trash-can me-2 text-danger"></i>Delete
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <table className="table">
                                            <tbody>
                                                <tr className="text-start">
                                                    <th>{item.name}</th>
                                                    <th>{item.weight} {item.units}</th>
                                                    <th>Rs.{item.price}/-</th>
                                                </tr>
                                                <tr className="text-start">
                                                    <th>{item.min_distance}</th>
                                                    <th>-</th>
                                                    <th>{item.max_distance}</th>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            ))}
                    </div> */}
        </div>
      </div>
    </Base>
  );
};

export default Payments;
