import React, { useState, useEffect } from "react";
import { API_BASE_CRM_URL } from "../../Config/Config";

export default function AddLogisticRate() {
    const [userDetails, setUserDetails] = useState([]);
    const [formData, setFormData] = useState({
        name: '',
        weight: '',
        units: '',
        min_distance: '',
        max_distance: '',
        price: '',
        notes: ''
    });

    useEffect(() => {
        const userDetailsSession = JSON.parse(sessionStorage.getItem('userDetails'));
        setUserDetails(userDetailsSession);
    }, []);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const payload = {
            Name: formData.name,
            Weight: formData.weight,
            Units: formData.units,
            MinDistance: formData.min_distance,
            MaxDistance: formData.max_distance,
            Addedfrom: userDetails.userId,
            Price: formData.price,
            Notes: formData.notes,
        };
        console.log(payload, 'data sending to api');
        fetch(`${API_BASE_CRM_URL}AddLogisticRate`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(payload)
        })
            .then(response => response.json())
            .then(data => {
                if (data.Status) {
                    alert('Logistic Rate added successfully!');
                } else {
                    alert('Failed to add Logistic Rate');
                }
            })
            .catch(error => {
                console.error("Error:", error);
                alert('Error adding Logistic Rate');
            });
    };

    return (
        <div
            className="offcanvas offcanvas-end custom-offcanvas"
            tabIndex="-1"
            id="offcanvasAdd"
            aria-labelledby="offcanvasRightLabel"
        >

            <style>
                {`
                .offcanvas.custom-offcanvas {
                    width: 450px !important; /* Adjust this as needed */
                }

                @media (max-width: 576px) {
                    .offcanvas.custom-offcanvas {
                    width: 70% !important; /* Adjust the percentage as needed */
                    }
                }
                `}
            </style>
            <div className="offcanvas-header">
                <div className="page-title d-flex justify-content-between align-items-center">
                    <h4>Add Logistic Rate</h4>
                </div>
                <button
                    type="button"
                    className="btn-close text-reset"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                ></button>
            </div>
            <div className="offcanvas-body ms-2">
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="mb-3 col-6">
                            <label className="form-label">Name</label>
                            <input
                                type="text"
                                name="name"
                                className="form-control"
                                placeholder="Name of Product"
                                value={formData.name}
                                onChange={handleChange}
                            />
                        </div>

                        {/* <div className="mb-3 col-6">
                                            <label className="form-label">Units</label>
                                            <input
                                                type="text"
                                                name="units"
                                                className="form-control"
                                                placeholder="Units"
                                                value={formData.units}
                                                onChange={handleChange}
                                            />
                                        </div> */}

                        <div className="mb-3 col-6">
                            <label className="form-label">Min Distance</label>
                            <input
                                type="text"
                                name="min_distance"
                                className="form-control"
                                placeholder="Min Distance"
                                value={formData.min_distance}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mb-3 col-6">
                            <label className="form-label">Max Distance</label>
                            <input
                                type="text"
                                name="max_distance"
                                className="form-control"
                                placeholder="Max Distance"
                                value={formData.max_distance}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mb-3 col-6">
                            <label className="form-label">Weight</label>
                            <input
                                type="text"
                                name="weight"
                                className="form-control"
                                placeholder="Quantity"
                                value={formData.weight}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mb-3 col-6">
                            <label className="form-label">Price</label>
                            <input
                                type="text"
                                name="price"
                                className="form-control"
                                placeholder="Price"
                                value={formData.price}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mb-3 col-12">
                            <label className="form-label">Notes</label>
                            <textarea
                                className="form-control"
                                name="notes"
                                placeholder="Notes..."
                                value={formData.notes}
                                onChange={handleChange}
                            ></textarea>
                        </div>
                    </div>
                    <div className="modal-footer-btn">
                        <button type="button" className="btn btn-cancel me-2" data-bs-dismiss="modal">
                            Cancel
                        </button>
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </div>
                </form>
            </div>
        </div>

    );
}
