import React, { useState, useEffect } from "react";
import { API_BASE_CRM_URL } from "../../Config/Config";
import Swal from "sweetalert2";
import '../../Config/swal.css';
import { Select } from 'antd';


export default function AddLocation() {

    const [userDetails, SetUserDetails] = useState([]);
    const [loading, setLoading] = useState(false);

    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [address, setAddress] = useState("");
    const [notes, setNotes] = useState("");
    const [customersData, setCustomersData] = useState([]);
    const [projectsData, setProjectsData] = useState([]);
    const [selectedCustomerid, setSelectedCustomerid] = useState("");
    const [selectedprojectid, setSelectedProjectid] = useState("");


    useEffect(() => {
        const userDetailsSession = JSON.parse(sessionStorage.getItem('userDetails'));
        SetUserDetails(userDetailsSession);
    }, []);

    const customerOptions = customersData && customersData.map((item) => ({
        value: item.userid,
        label: item.company,
    }));

    const projectOptions = projectsData && projectsData.map((item) => ({
        value: item.id,
        label: item.name,
    }));

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${API_BASE_CRM_URL}GetCustomers`);
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();
                if (data.Status) {
                    setCustomersData(data.ResultData);
                } else {
                    console.error("Fetched data is not an array:", data);
                    setCustomersData([]);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (selectedCustomerid) {
            const fetchData = async () => {
                try {
                    const response = await fetch(`${API_BASE_CRM_URL}GetProjectsByCusId?CusID=${selectedCustomerid}`);
                    if (!response.ok) {
                        throw new Error("Network response was not ok");
                    }
                    const data = await response.json();
                    if (data.Status) {
                        setProjectsData(data.ResultData);
                    } else {
                        console.error("Fetched data is not an array:", data);
                        setProjectsData([]);
                    }
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            };
            fetchData();
        }
    }, [selectedCustomerid]);

    const handleCustomerChange = (selectedone) => {
        setSelectedCustomerid(selectedone);
    };

    const handleProjectChange = (e) => {
        setSelectedProjectid(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setLoading(true);

        const locationData = {
            latitude: latitude,
            longitude: longitude,
            address: address,
            notes: notes,
            addedfrom: userDetails.staffid,
            projectid: selectedprojectid,
            relid: selectedCustomerid,
        };

        try {
            const response = await fetch(`${API_BASE_CRM_URL}AddLocations`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(locationData)
            });

            const data = await response.json();

            if (data.Status) {
                setLoading(false);
                Swal.fire({
                    title: `Location added Successfully.`,
                    icon: "success",
                    customClass: {
                        title: 'swal-title',
                        content: 'swal-text'
                    }
                }).then((result) => {
                    if (result.isConfirmed || result.isDismissed) {
                        window.location.reload();
                    }
                });
            } else {
                setLoading(false);
                Swal.fire({
                    title: "Error",
                    text: "Failed to submit request.",
                    icon: "error",
                    customClass: {
                        title: 'swal-title',
                        content: 'swal-text'
                    }
                });
            }
        } catch (error) {
            console.error("Error adding location:", error);
        }
    };

    return (
        <div>
            <style>
                {`
                .offcanvas.custom-offcanvas {
                    width: 400px !important; /* Adjust this as needed */
                }

                @media (max-width: 576px) {
                    .offcanvas.custom-offcanvas {
                    width: 70% !important; /* Adjust the percentage as needed */
                    }
                }
                `}
            </style>

            <div
                className="offcanvas offcanvas-end custom-offcanvas"
                tabIndex="-1"
                id="offcanvasRight"
                aria-labelledby="offcanvasRightLabel"
            >
                <div className="offcanvas-header">
                    <h5 id="offcanvasRightLabel">Add Location</h5>
                    <button
                        type="button"
                        className="btn-close text-reset"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                    ></button>
                </div>
                <div className="offcanvas-body">
                    <form onSubmit={handleSubmit}>
                        <div className="mt-2 d-flex flex-column mb-2">
                            <label className="form-label">Customer</label>

                            <Select
                                showSearch
                                placeholder="Choose Customer"
                                value={selectedCustomerid || null}
                                onChange={handleCustomerChange}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={customerOptions}
                            />
                        </div>
                        <div className="">
                            <label className="form-label">Project:{" "} <span className='text-danger'>*</span></label>
                            <div className='d-flex'>
                                <Select
                                    showSearch
                                    placeholder="Choose Project"
                                    value={selectedprojectid || null}
                                    onChange={handleProjectChange}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={projectOptions}
                                    style={{ flexGrow: 1, height: '2.4rem' }}
                                    required
                                />
                            </div>
                        </div>
                        <div className="mt-2">
                            <label className="form-label">Latitude <span className='text-danger'>*</span></label>
                            <input
                                className="form-control"
                                placeholder="Enter latitude"
                                value={latitude}
                                onChange={(e) => setLatitude(e.target.value)}
                                required
                            />
                        </div>
                        <div className="mt-2">
                            <label className="form-label">Longitude <span className='text-danger'>*</span></label>
                            <input
                                className="form-control"
                                placeholder="Enter longitude"
                                value={longitude}
                                onChange={(e) => setLongitude(e.target.value)}
                                required
                            />
                        </div>
                        <div className="mt-2">
                            <label className="form-label">Address</label>
                            <textarea
                                className="form-control"
                                placeholder="Enter address.."
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                            />
                        </div>
                        <div className="mt-2">
                            <label className="form-label">Notes</label>
                            <textarea
                                className="form-control"
                                placeholder="Enter notes.."
                                value={notes}
                                onChange={(e) => setNotes(e.target.value)}
                            />
                        </div>
                        <button type="submit" className="btn btn-primary d-flex m-auto mt-4">
                            {loading ? 'Submitting...' : 'Submit'}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}
