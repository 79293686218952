import React, { useState, useEffect } from 'react';
import Base from '../../Config/Base';
import { Link, useLocation } from 'react-router-dom';
import { API_BASE_CRM_URL } from "../../Config/Config";
import Swal from "sweetalert2";
import { useNavigate } from 'react-router-dom';
import '../../Config/swal.css';
import { Select } from 'antd';

export default function AddCustomer() {

    const [userDetails, SetUserDetails] = useState([]);
    const location = useLocation();
    const leadData = location.state?.lead || {};

    useEffect(() => {
        const userDetailsSession = JSON.parse(sessionStorage.getItem('userDetails'));
        SetUserDetails(userDetailsSession);
    }, []);

    const navigate = useNavigate();
    const [selectedproductid, setselectedproductid] = useState("")
    const [formData, setFormData] = useState({
        company: '',
        vat: '',
        phonenumber: '',
        website: '',
        email: '',
        googlelocation: '',
        head_office_flag: '',
        datecreated: '',
        default_language: '',
        city: '',
        Address: '',
        state: '',
        zip: '',
        leadid: '',
        billing_street: '',
        billing_city: '',
        billing_state: '',
        billing_zip: '',
        shipping_street: '',
        shipping_city: '',
        shipping_state: '',
        shipping_zip: '',
        Longitude: '',
        Latitude: '',
        product_id: '',
        site_name: '',
        km_slab: '',
        rate: '',
        bill: '',
        gst: '',
    });
    const [loading, setLoading] = useState(false);
    const [productsData, setProductsData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${API_BASE_CRM_URL}GetProducts`);
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();
                if (data.Status) {
                    setProductsData(data.ResultData);
                } else {
                    console.error("Fetched data is not an array:", data);
                    setProductsData([]);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);
    const productOptions = productsData && productsData.map((item) => ({
        value: item.id,
        label: item.description,
    }));

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
        console.log(formData, 'Updated FormData');
    };

    const handleProductChange = (selectedOption) => {
        console.log("data id:", selectedOption);
        setselectedproductid(selectedOption);
    };

    useEffect(() => {
        console.log(formData, 'Updated FormData');
    }, [formData]);

    // console.log("Lead Data in handleSubmit:", leadData);
    const handleSubmit = async (e) => {
        if (e) e.preventDefault();
        setLoading(true);

        console.log("Lead Data in handleSubmit:", leadData);
        const formDataToSend = {
            Company: leadData?.company || formData.company,
            Vat: '',
            PhoneNo: leadData?.phoneNumber || formData.phonenumber,
            Website: leadData?.Website || formData.website,
            email: leadData?.email || formData.email,
            googlelocation: '',
            head_office_flag: false,
            DefaultLanguage: 1,
            City: leadData?.city || formData.city,
            Address: leadData?.Address || formData.Address,
            State: leadData?.state || formData.state,
            Zip: leadData?.Zip || formData.zip,
            Country: leadData?.Country || 1,
            LeadId: leadData?.id || formData.leadid || "",
            BillingStreet: leadData?.Address || formData.billing_street,
            BillingCity: leadData?.city || formData.billing_city,
            BillingState: leadData?.State || formData.billing_state,
            BillingZip: leadData?.Zip || formData.billing_zip,
            ShippingStreet: leadData?.Address || formData.shipping_street,
            ShippingCity: leadData?.City || formData.shipping_city,
            ShippingState: leadData?.State || formData.shipping_state,
            ShippingZip: leadData?.Zip || formData.shipping_zip,
            AddedFrom: userDetails.staffid,
            Lead: leadData?.Name || null,
            BillingCountry: leadData?.Country || null,
            ShippingCountry: leadData?.Country || null,
            Latitude: '',
            Longitude: '',
            DefaultCurrency: 1,
            product_id: selectedproductid,
            site_name: formData.site_name,
            km_slab: formData.km_slab,
            rate: formData.rate || 0,
            bill: formData.bill || 0,
            gst: formData.gst
        };

        console.log(formDataToSend, 'data to API');

        try {
            const response = await fetch(`${API_BASE_CRM_URL}AddCustomer`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formDataToSend),
            });

            if (!response.ok) {
                const errorResponse = await response.text();
                console.error('Server Error:', errorResponse);
                throw new Error(`Network response was not ok: ${response.status}`);
            }

            const result = await response.json();
            if (result.Status) {
                setLoading(false);
                Swal.fire({
                    title: `Customer Added Successfully.`,
                    icon: 'success',
                    customClass: {
                        title: 'swal-title',
                        content: 'swal-text',
                    },
                }).then((result) => {
                    if (result.isConfirmed || result.isDismissed) {
                        navigate('/customers');
                    }
                });
            } else {
                setLoading(false);
                Swal.fire({
                    title: 'Error',
                    text: 'Failed to submit request.',
                    icon: 'error',
                    customClass: {
                        title: 'swal-title',
                        content: 'swal-text',
                    },
                });
            }
        } catch (error) {
            console.error('Error:', error);
            setLoading(false);
            Swal.fire({
                title: 'Error',
                text: 'An error occurred while submitting the request.',
                icon: 'error',
            });
        }
    };



    return (
        <Base>
            <div className="page-heade mb-3">

                <div className="row align-items-center">
                    <div className="col-md-6">
                        <div className="page-title">
                            <h4>Add company</h4>
                            <p className='fw-semibold'>Fill out the form to add a new company</p>
                        </div>
                    </div>
                    <div className="col-md-6 d-flex justify-content-md-end justify-content-start">
                        <Link to='/customers'>
                            <button className="btn btn-primary" style={{ borderRadius: '10px' }}>
                                <i className="fa-solid fa-arrow-left me-2"></i> Go to List
                            </button>
                        </Link>
                    </div>

                </div>
            </div>

            <div className="col-xxl-12 col-xl-12" >
                <div className="card">
                    <div className="card-body" style={{ maxHeight: '100vh', overflowY: 'auto' }}>
                        <form onSubmit={handleSubmit}>
                            <ul className="nav nav-tabs tab-style-2 nav-justified mb-3 d-sm-flex d-block" id="myTab1"
                                role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="order-tab" data-bs-toggle="tab"
                                        data-bs-target="#order-tab-pane" type="button" role="tab" aria-controls="order-tab-pane"
                                        aria-selected="true">
                                        <i className="fa-regular fa-address-book"></i> Info
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="confirmed-tab" data-bs-toggle="tab"
                                        data-bs-target="#confirm-tab-pane" type="button" role="tab" aria-controls="confirm-tab-pane"
                                        aria-selected="false">
                                        <i className="fa-solid fa-map-location-dot"></i>Billing  & Shipping Address
                                    </button>
                                </li>
                                {/* <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="shipped-tab" data-bs-toggle="tab"
                                        data-bs-target="#shipped-tab-pane" type="button" role="tab" aria-controls="shipped-tab-pane"
                                        aria-selected="false">
                                        <i className="fa-solid fa-truck-fast"></i>Shipping
                                    </button>
                                </li> */}
                            </ul>
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active text-muted" id="order-tab-pane" role="tabpanel"
                                    aria-labelledby="order-tab" tabIndex="0">
                                    <div className='row m-3'>
                                        <div className='col-md-4 col-lg-4 mb-2'>
                                            <label className='form-label'>Organization Name <span className='text-danger'>*</span></label>
                                            <input className='form-control' name='company' value={leadData.company || formData.company || ""}
                                                placeholder='Enter organization name'
                                                required
                                                onChange={handleChange} />
                                        </div>
                                        <div className='col-md-4 col-lg-4 mb-2'>
                                            <label className='form-label'>Nature Of Bussiness <span className='text-danger'>*</span></label>
                                            <input className='form-control' name='vat' value={formData.vat}
                                                placeholder='Enter Nature Of Bussiness '

                                                onChange={handleChange} />
                                        </div>
                                        <div className='col-md-4 col-lg-4 mb-2'>
                                            <label className='form-label'>Email <span className='text-danger'>*</span></label>
                                            <input className='form-control' name='email' value={leadData.email || formData.email || ""}
                                                placeholder='Enter email'
                                                required
                                                onChange={handleChange} />
                                        </div>
                                        <div className='col-md-4 col-lg-4'>
                                            <label className='form-label'>Phone Number<span className='text-danger'>*</span></label>
                                            <input className='form-control' name='phonenumber' value={leadData.phonenumber || formData.phonenumber || ""}
                                                required
                                                placeholder='Enter phone number'
                                                onChange={handleChange} />
                                        </div>
                                        {/* <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                <label className="form-label"> Latitude</label>
                                <input
                                    type="number"
                                    name='Latitude'
                                    className="form-control"
                                    value={formData.Latitude}
                                    onChange={handleChange}
                                    placeholder="Enter site latitude"
                                />
                            </div>
                            <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                <label className="form-label"> Longitude</label>
                                <input
                                    type="number"
                                    name='Longitude'
                                    className="form-control"
                                    value={formData.Longitude}
                                    onChange={handleChange}
                                    placeholder="Enter site longitude"
                                />
                            </div> */}
                                        <div className='col-md-4 col-lg-4'>
                                            <label className='form-label'>Google Location</label>
                                            <input className='form-control' name='googlelocation' value={formData.googlelocation}
                                                placeholder='Enter Google Location'
                                                onChange={handleChange} />
                                        </div>
                                        <div className='col-md-4 col-lg-4'>
                                            <label className='form-label'>Website</label>
                                            <input className='form-control' name='website' value={formData.website}
                                                placeholder='Enter Google Location'
                                                onChange={handleChange} />
                                        </div>

                                        <div className='col-md-4 col-lg-4'>
                                            <label className='form-label'>Source of  Lead</label>
                                            <input className='form-control' name='default_language'
                                                placeholder='Enter Source of Lead'
                                                value={formData.default_language} onChange={handleChange} />
                                        </div>
                                        <div className='col-md-4 col-lg-4'>
                                            <label className='form-label'>City<span className='text-danger'>*</span></label>
                                            <input className='form-control' name='city' value={leadData.city || formData.city}
                                                placeholder='Enter city'
                                                onChange={handleChange} />
                                        </div>
                                        <div className='col-md-4 col-lg-4 mb-2'>
                                            <label className='form-label'>State</label>
                                            <input className='form-control' name='state' value={formData.state}
                                                placeholder='Enter state'
                                                onChange={handleChange} />
                                        </div>
                                        <div className='col-md-4 col-lg-4'>
                                            <label className='form-label'>Zipcode</label>
                                            <input className='form-control' name='zip' value={formData.zip}
                                                placeholder='Enter zipcode'
                                                onChange={handleChange} />
                                        </div>

                                        <div className='col-md-4 col-lg-4'>
                                            <label className='form-label'>GSTIN</label>
                                            <input
                                                className='form-control'
                                                name='head_office_flag'
                                                value={formData.head_office_flag}
                                                onChange={handleChange} />
                                        </div>
                                        <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                            <label className="form-label">Product</label>
                                            <Select
                                                placeholder="Choose product"
                                                value={selectedproductid}
                                                onChange={handleProductChange}
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                options={productOptions}
                                                style={{ height: '2.4rem' }}
                                            />
                                        </div>
                                        <div className='col-md-4 col-lg-4'>
                                            <label className='form-label'>Site Name</label>
                                            <input
                                                className='form-control'
                                                name='site_name'
                                                placeholder='enter site name'
                                                value={formData.site_name}
                                                onChange={handleChange} />
                                        </div> <div className='col-md-4 col-lg-4'>
                                            <label className='form-label'>Km Slab</label>
                                            <input
                                                className='form-control'
                                                name='km_slab'
                                                placeholder='km slab'
                                                value={formData.km_slab}
                                                onChange={handleChange} />
                                        </div>
                                        <div className='col-md-4 col-lg-4'>
                                            <label className='form-label'>Rate</label>
                                            <input
                                                className='form-control'
                                                name='rate'
                                                placeholder='enter rate'
                                                value={formData.rate}
                                                onChange={handleChange} />
                                        </div> <div className='col-md-4 col-lg-4'>
                                            <label className='form-label'>Bill</label>
                                            <input
                                                className='form-control'
                                                name='bill'
                                                placeholder='enter bill'
                                                value={formData.bill}
                                                onChange={handleChange} />
                                        </div> <div className='col-md-4 col-lg-4'>
                                            <label className='form-label'>Gst %</label>
                                            <input
                                                className='form-control'
                                                name='gst'
                                                placeholder='enter gst'
                                                value={formData.gst}
                                                onChange={handleChange} />
                                        </div>
                                        <div className='col-md-4 col-lg-4'>
                                            <label className='form-label'>Address</label>
                                            <textarea
                                                className='form-control'
                                                name='Address'
                                                placeholder='enter Address'
                                                value={formData.Address}
                                                onChange={handleChange} />
                                        </div>

                                    </div>
                                </div>
                                <div className="tab-pane fade text-muted" id="confirm-tab-pane" role="tabpanel"
                                    aria-labelledby="confirmed-tab" tabIndex="0">
                                    <div className='row m-3'>
                                        <div className='col-md-4 col-lg-4'>
                                            <label className='form-label'>Billing Street</label>
                                            <input className='form-control' name='billing_street' value={formData.billing_street}
                                                placeholder='Enter street'
                                                onChange={handleChange} />
                                        </div>
                                        <div className='col-md-4 col-lg-4'>
                                            <label className='form-label'>Billing City</label>
                                            <input className='form-control' name='billing_city' value={formData.billing_city}
                                                placeholder='Enter city'
                                                onChange={handleChange} />
                                        </div>
                                        <div className='col-md-4 col-lg-4'>
                                            <label className='form-label'>Billing State</label>
                                            <input className='form-control' name='billing_state' value={formData.billing_state}
                                                placeholder='Enter city'
                                                onChange={handleChange} />
                                        </div>
                                        <div className='col-md-4 col-lg-4'>
                                            <label className='form-label'>Billing Zipcode</label>
                                            <input className='form-control' name='billing_zip' value={formData.billing_zip}
                                                placeholder='Enter zipcode'
                                                onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className='row m-3'>
                                        <div className='col-md-4 col-lg-4'>
                                            <label className='form-label'>Shipping Street</label>
                                            <input className='form-control' name='shipping_street' value={formData.shipping_street}
                                                placeholder='Enter street'
                                                onChange={handleChange} />
                                        </div>
                                        <div className='col-md-4 col-lg-4'>
                                            <label className='form-label'>Shipping City</label>
                                            <input className='form-control' name='shipping_city' value={formData.shipping_city}
                                                placeholder='Enter city'
                                                onChange={handleChange} />
                                        </div>
                                        <div className='col-md-4 col-lg-4'>
                                            <label className='form-label'>Shipping State</label>
                                            <input className='form-control' name='shipping_state' value={formData.shipping_state}
                                                placeholder='Enter state'
                                                onChange={handleChange} />
                                        </div>
                                        <div className='col-md-4 col-lg-4'>
                                            <label className='form-label'>Shipping Zipcode</label>
                                            <input className='form-control' name='shipping_zip' value={formData.shipping_zip}
                                                placeholder='Enter zipcode'
                                                onChange={handleChange} />
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="tab-pane fade text-muted" id="shipped-tab-pane" role="tabpanel"
                                    aria-labelledby="shipped-tab" tabIndex="0">
                                    <div className='row m-3'>
                                        <div className='col-md-4 col-lg-4'>
                                            <label className='form-label'>Shipping Street</label>
                                            <input className='form-control' name='shipping_street' value={formData.shipping_street}
                                                placeholder='Enter street'
                                                onChange={handleChange} />
                                        </div>
                                        <div className='col-md-4 col-lg-4'>
                                            <label className='form-label'>Shipping City</label>
                                            <input className='form-control' name='shipping_city' value={formData.shipping_city}
                                                placeholder='Enter city'
                                                onChange={handleChange} />
                                        </div>
                                        <div className='col-md-4 col-lg-4'>
                                            <label className='form-label'>Shipping State</label>
                                            <input className='form-control' name='shipping_state' value={formData.shipping_state}
                                                placeholder='Enter state'
                                                onChange={handleChange} />
                                        </div>
                                        <div className='col-md-4 col-lg-4'>
                                            <label className='form-label'>Shipping Zipcode</label>
                                            <input className='form-control' name='shipping_zip' value={formData.shipping_zip}
                                                placeholder='Enter zipcode'
                                                onChange={handleChange} />
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            <button type="submit" className="btn btn-primary mt-3">{loading ? 'Submitting...' : 'Submit'}</button>
                        </form>
                    </div>
                </div>
            </div>
        </Base>
    );
}
